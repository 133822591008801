import { useState } from "react";
import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { Form } from "../../../components/common/Form";
import { Input } from "../../../components/common/Form/Input";
import { SubmitButton } from "../../../components/common/Form/SubmitButton";
import { Select } from "../../../components/common/Form/Select";
import { Formik } from "formik";
import * as Yup from "yup";
import { useIsMobile, useIsTablet } from "../../../hooks/useIsMobile";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const baseClass = getBEMClasses("consign-with-us-third-step");

export const PAYOUT_METHODS = {
  PAYPAL: { label: "Paypal", value: "Paypal" },
  WIRE_TRANSFER: { label: "Wire Transfer", value: "Wire Transfer" },
  INTERACT_E_TRANSFER: {
    label: "Interac E-Transfer",
    value: "Interac E-Transfer",
  },
};

export const ConsignThirdStep = (props) => {
  const { onNextClick, saveStepValues, initialValues } = props;
  const [currentAuctionPayOutMethod, setCurrentAuctionPayOutMethod] = useState(
    initialValues.currentAuctionPayOutMethod
      ? initialValues.currentAuctionPayOutMethod
      : PAYOUT_METHODS.PAYPAL.value
  );
  const defaultInitialValues = !isEmpty(initialValues)
    ? initialValues
    : {
        auctionPayoutMethod: PAYOUT_METHODS.PAYPAL.value,
        bankAccountNumber: "",
        swiftCode: "",
        bankAddress: "",
        paypalEmail: "",
        bankFullName: "",
        interactEmail: "",
      };
  const auctionPayoutMethodOptions = [
    PAYOUT_METHODS.PAYPAL,
    PAYOUT_METHODS.WIRE_TRANSFER,
    PAYOUT_METHODS.INTERACT_E_TRANSFER,
  ];

  const paypalValidationSchema = Yup.object().shape({
    auctionPayoutMethod: Yup.string().required(
      "Auction payout method is required"
    ),
    paypalEmail: Yup.string()
      .email("Invalid paypal email. Please try again.")
      .required("Paypal email is required"),
  });

  const wireValidationSchema = Yup.object().shape({
    auctionPayoutMethod: Yup.string().required(
      "Auction payout method is required"
    ),
    bankAccountNumber: Yup.string().required(
      "Bank account number is invalid. Please try again."
    ),
    swiftCode: Yup.string().required(
      "Swift code is invalid. Please try again."
    ),
    bankFullName: Yup.string().required("Bank full name is required"),
    bankAddress: Yup.string().required("Bank address is required"),
  });

  const interactValidationSchema = Yup.object().shape({
    auctionPayoutMethod: Yup.string().required(
      "Auction payout method is required"
    ),
    bankFullName: Yup.string().required("Bank full name is required"),
    interactEmail: Yup.string()
      .email("Invalid email.Please try again.")
      .required("Email is required"),
  });
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const getCorrectValues = (values) => {
    if (values.auctionPayoutMethod === PAYOUT_METHODS.PAYPAL.value) {
      return {
        auctionPayoutMethod: values.auctionPayoutMethod,
        paypalEmail: values.paypalEmail,
      };
    } else if (
      values.auctionPayoutMethod === PAYOUT_METHODS.WIRE_TRANSFER.value
    ) {
      return {
        auctionPayoutMethod: values.auctionPayoutMethod,
        bankAccountNumber: values.bankAccountNumber,
        swiftCode: values.swiftCode,
        bankFullName: values.bankFullName,
        bankAddress: values.bankAddress,
      };
    } else {
      return {
        auctionPayoutMethod: values.auctionPayoutMethod,
        bankFullName: values.bankFullName,
        interactEmail: values.interactEmail,
      };
    }
  };
  return (
    <Formik
      onSubmit={(values) => {
        const correctValues = getCorrectValues(values);
        saveStepValues({ ...correctValues, currentAuctionPayOutMethod }, 3);
        onNextClick();
      }}
      initialValues={defaultInitialValues}
      validationSchema={
        currentAuctionPayOutMethod === PAYOUT_METHODS.PAYPAL.value
          ? paypalValidationSchema
          : currentAuctionPayOutMethod === PAYOUT_METHODS.WIRE_TRANSFER.value
          ? wireValidationSchema
          : interactValidationSchema
      }
    >
      {({ errors, touched }) => {
        return (
          <Form style={{ gap: isTablet ? 30 : 40, alignItems: "center" }}>
            <Select
              name="auctionPayoutMethod"
              label="Auction Payout Method"
              options={auctionPayoutMethodOptions}
              customChange={(option) => {
                setCurrentAuctionPayOutMethod(option.label);
              }}
              width={isMobile ? "100%" : 280}
              error={
                errors.auctionPayoutMethod && touched.auctionPayoutMethod
                  ? errors.auctionPayoutMethod
                  : ""
              }
              defaultTitle={"Select auction payout method"}
            />
            {currentAuctionPayOutMethod === PAYOUT_METHODS.PAYPAL.value && (
              <>
                <Input
                  name="paypalEmail"
                  placeholder="Type your paypal email here"
                  label="Paypal email"
                  width={isMobile ? "100%" : 280}
                  error={
                    errors.paypalEmail && touched.paypalEmail
                      ? errors.paypalEmail
                      : ""
                  }
                />
              </>
            )}
            {currentAuctionPayOutMethod ===
              PAYOUT_METHODS.WIRE_TRANSFER.value && (
              <>
                <Input
                  name="bankAccountNumber"
                  placeholder="Type your bank account number here"
                  label="Bank Account Number"
                  width={isMobile ? "100%" : 280}
                  error={
                    errors.bankAccountNumber && touched.bankAccountNumber
                      ? errors.bankAccountNumber
                      : ""
                  }
                />
                <Input
                  name="swiftCode"
                  placeholder="Type your SWIFT code here"
                  label="Swift Code"
                  width={isMobile ? "100%" : 280}
                  error={
                    errors.swiftCode && touched.swiftCode
                      ? errors.swiftCode
                      : ""
                  }
                  infoText={
                    "If you are located outside of Canada, you need to provide a swift code or IBAN"
                  }
                />
                <Input
                  name="bankFullName"
                  placeholder="Type your full name here"
                  label="FULL NAME"
                  width={isMobile ? "100%" : 280}
                  error={
                    errors.bankFullName && touched.bankFullName
                      ? errors.bankFullName
                      : ""
                  }
                  infoText={"As it appears on your bank account"}
                />
                <Input
                  name="bankAddress"
                  label="ADDRESS"
                  width={isMobile ? "100%" : 280}
                  error={
                    errors.bankAddress && touched.bankAddress
                      ? errors.bankAddress
                      : ""
                  }
                  infoText={"As it appears on your bank account"}
                  placeholder={"Type your address here"}
                />
              </>
            )}
            {currentAuctionPayOutMethod ===
              PAYOUT_METHODS.INTERACT_E_TRANSFER.value && (
              <>
                <div className={baseClass("info-text")}>
                  Only available for candian residents*
                </div>
                <Input
                  name="bankFullName"
                  placeholder="Type your full name here"
                  label="Full Name"
                  width={isMobile ? "100%" : 280}
                  error={
                    errors.bankFullName && touched.bankFullName
                      ? errors.bankFullName
                      : ""
                  }
                />
                <Input
                  name="interactEmail"
                  placeholder="Type your email here"
                  label="Email"
                  width={isMobile ? "100%" : 280}
                  error={
                    errors.interactEmail && touched.interactEmail
                      ? errors.interactEmail
                      : ""
                  }
                />
              </>
            )}
            <SubmitButton
              label="next"
              style={{ marginLeft: "auto", marginRight: "auto", marginTop: 50 }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

ConsignThirdStep.propTypes = {
  onNextClick: PropTypes.func,
  saveStepValues: PropTypes.func,
  initialValues: PropTypes.object,
};
