import { getBEMClasses } from "../../helpers/bemHelper";
import ArrowBack from "../../assets/icons/arrow-back.svg";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import { SteveAoki } from "./pages/SteveAoki";
import { LoganPaul } from "./pages/LoganPaul";
import { DarrenJack } from "./pages/DarrenJack";
import { ExpensiveCollectibles } from "./pages/ExpensiveCollectibles";
import { MonkeyBusiness } from "./pages/MonkeyBusiness";
import { TrustAndTransparency } from "./pages/TrustAndTransparency";
import { Vote } from "./pages/Vote";
import DoubleArrowLeftIcon from "../../assets/icons/double-arrow-left.svg";
import DoubleArrowRightIcon from "../../assets/icons/double-arrow-right.svg";
import "./styles.css";

const baseClass = getBEMClasses("blog");

export const BLOGS = [
  "steve-aoki",
  "logan-paul",
  "darren-jack",
  "expensive-collectibles",
  "monkey-business",
  "trust-and-transparency",
  "vote",
];

export const BlogPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPageId = BLOGS.findIndex((item) => pathname.includes(item));

  return (
    <div className={baseClass("")}>
      <div className={baseClass("header-line")}></div>
      <div
        className={baseClass("go-back-wrapper")}
        onClick={() => navigate(-1)}
      >
        <img src={ArrowBack} className={baseClass("go-back-img")} />
        Go back
      </div>
      <div className={baseClass("content")}>
        <Routes>
          <Route path="steve-aoki" element={<SteveAoki />} />
          <Route path="logan-paul" element={<LoganPaul />} />
          <Route path="darren-jack" element={<DarrenJack />} />
          <Route
            path="expensive-collectibles"
            element={<ExpensiveCollectibles />}
          />
          <Route path="monkey-business" element={<MonkeyBusiness />} />
          <Route
            path="trust-and-transparency"
            element={<TrustAndTransparency />}
          />
          <Route path="vote" element={<Vote />} />
        </Routes>
      </div>
      <div className={baseClass("pagination-wrapper")}>
        <div
          onClick={() => {
            if (currentPageId > 0) {
              navigate(`/blogs/${BLOGS[currentPageId - 1]}`);
            }
          }}
          className={baseClass(
            "pagination-button",
            currentPageId <= 0 && "disabled"
          )}
        >
          <img src={DoubleArrowLeftIcon} />
          PREV
        </div>
        <div
          onClick={() => {
            if (currentPageId < BLOGS.length - 1) {
              navigate(`/blogs/${BLOGS[currentPageId + 1]}`);
            }
          }}
          className={baseClass(
            "pagination-button",
            currentPageId >= BLOGS.length - 1 && "disabled"
          )}
        >
          NEXT
          <img className={baseClass('double-arrow-icon')} src={DoubleArrowRightIcon} />
        </div>
      </div>
    </div>
  );
};
