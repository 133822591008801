import PropTypes from "prop-types";
import { DarkButton } from "../../../components/common/Buttons";
import { getBEMClasses } from "../../../helpers/bemHelper";
import CommunityVideo from "../../../assets/videos/community.mp4";
import EvaluationVideo from "../../../assets/videos/evaluation.mp4";
import MarketPlaceVideo from "../../../assets/videos/marketplace.mp4";
import TokenizationVideo from "../../../assets/videos/tokenization.mp4";
import SliderIconLeft from "../../../assets/icons/slider-icon-left.svg";
import SliderIconRight from "../../../assets/icons/slider-icon-right.svg";
import Slider from "react-slick";
import { useIsPreTablet } from "../../../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { Video } from "../../../components/common/Video";

const baseClass = getBEMClasses("collect");

export const CollectSection = () => {
  const navigate = useNavigate();
  const isTablet = useIsPreTablet();
  const sliderSettings = {
    dots: isTablet ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className={baseClass("wrapper")}>
      <div className={baseClass("title")}>LET’S COLLECT TOGETHER</div>
      <div className={baseClass("text")}>
        Join Liquid MarketPlace and co-own collectibles with your friends in our
        global community.
      </div>
      {isTablet ? (
        <div className={baseClass("slider-wrapper")}>
          <Slider {...sliderSettings}>
            <div className={baseClass("card")}>
              <div className={baseClass("card-content")}>
                <Video width={160} height={160} video={EvaluationVideo} />
                <div className={baseClass("card-title")}>EVALUATION</div>
              </div>
            </div>
            <div className={baseClass("card")}>
              <div className={baseClass("card-content")}>
                <Video width={160} height={160} video={TokenizationVideo} />
                <div className={baseClass("card-title")}>CO-OWNERSHIP</div>
              </div>
            </div>
            <div className={baseClass("card")}>
              <div className={baseClass("card-content")}>
                <Video width={160} height={160} video={MarketPlaceVideo} />
                <div className={baseClass("card-title")}>MARKETPLACE</div>
              </div>
            </div>
            <div className={baseClass("card")}>
              <div className={baseClass("card-content")}>
                <Video width={160} height={160} video={CommunityVideo} />
                <div className={baseClass("card-title")}>COMMUNITY</div>
              </div>
            </div>
          </Slider>
        </div>
      ) : (
        <div className={baseClass("cards")}>
          <div className={baseClass("card")}>
            <Video width={200} height={200} video={EvaluationVideo} />
            <div className={baseClass("card-title")}>EVALUATION</div>
          </div>
          <div className={baseClass("card")}>
            <Video width={200} height={200} video={TokenizationVideo} />
            <div className={baseClass("card-title")}>CO-OWNERSHIP</div>
          </div>
          <div className={baseClass("card")}>
            <Video width={200} height={200} video={MarketPlaceVideo} />
            <div className={baseClass("card-title")}>MARKETPLACE</div>
          </div>
          <div className={baseClass("card")}>
            <Video width={200} height={200} video={CommunityVideo} />
            <div className={baseClass("card-title")}>COMMUNITY</div>
          </div>
        </div>
      )}
      <DarkButton
        title={"LEARN MORE"}
        width={220}
        onClick={() => navigate("about-us")}
      />
    </div>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconRight}
    />
  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconLeft}
    />
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
