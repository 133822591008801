import { getBEMClasses } from "../../../helpers/bemHelper";
import { GradientButton } from "../../../components/common/Buttons";
import { openLinkInNewTab } from "../../../helpers/linksHelper";
import { Video } from "../../../components/common/Video";
import Banner from "./videos/banner.mp4";
import "./styles.css";

const baseClass = getBEMClasses("own-anything");

export const OwnAnything = () => {
  return (
    <div className={baseClass("wrapper")}>
      <div className={baseClass("text-wrapper")}>
        <div className={baseClass("title")}>OWN ANYTHING</div>
        <div className={baseClass("text")}>
          We offer collectors the opportunity to co-own exclusive collectibles.
          Start owning real assets with as little as $0.10.
        </div>
        <GradientButton
          title="START TODAY"
          width={220}
          onClick={() =>
            openLinkInNewTab("https://trade.liquidmarketplace.io/signup")
          }
        />
      </div>
      <div className={baseClass("video")}>
        <Video video={Banner} width="100%" height="100%" />
      </div>
    </div>
  );
};
