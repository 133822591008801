import PropTypes from "prop-types";
import { useIsTablet } from "../../hooks/useIsMobile";
import { getBEMClasses } from "../../helpers/bemHelper";
import { openLinkInNewTab } from "../../helpers/linksHelper";
import "./styles.css";

const baseClass = getBEMClasses("press-card");

export const PressCard = (props) => {
  const {
    img,
    text,
    link,
    headerImg,
    headerBackground = "transparent",
    headerImgWidth = 200,
  } = props;
  const isTablet = useIsTablet();
  return isTablet ? (
    <div className={baseClass("card")}>
      <div
        className={baseClass("card-wrapper")}
        onClick={() => {
          openLinkInNewTab(link);
        }}
      >
        <img
          className={baseClass("card-header-img")}
          src={headerImg}
          style={{
            background: headerBackground,
            width: headerImgWidth,
          }}
        />
        <img className={baseClass("card-img")} src={img} />
        <div className={baseClass("card-content")}>
          <div className={baseClass("card-text")}>{text}</div>
          <div
            className={baseClass("card-email")}
            onClick={() => {
              openLinkInNewTab(link);
            }}
          >
            Read more
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={baseClass("card")}
      onClick={() => {
        openLinkInNewTab(link);
      }}
    >
      <img
        className={baseClass("card-header-img")}
        src={headerImg}
        style={{
          background: headerBackground,
          width: headerImgWidth,
        }}
      />
      <img className={baseClass("card-img")} src={img} />
      <div className={baseClass("card-content")}>
        <div className={baseClass("card-text")}>{text}</div>
        <div
          className={baseClass("card-email")}
          onClick={() => {
            openLinkInNewTab(link);
          }}
        >
          Read more
        </div>
      </div>
    </div>
  );
};

PressCard.propTypes = {
  img: PropTypes.string,
  headerImg: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  headerBackground: PropTypes.string,
  headerImgWidth: PropTypes.number,
};
