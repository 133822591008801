import { useState } from "react";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { BlogCard } from "../../../components/BlogCard";
import { BLOG_CARDS } from "./blogCards";
import { PaginationComponent } from "../../../components/common/Pagination";
import "./styles.css";

const baseClass = getBEMClasses("blogs-cards");

export const BlogsCards = () => {
  const [activePage, setActivePage] = useState(0);
  const pageSize = 4;
  // eslint-disable-next-line no-unused-vars
  const totalPages = Math.ceil(
    BLOG_CARDS.length / pageSize || BLOG_CARDS.length
  );
  return (
    <div className={baseClass("")}>
      <PaginationComponent
        customClass={baseClass()}
        totalPages={totalPages}
        maxPages={3}
        showOnlyActivePage={false}
        activePage={activePage}
        setActivePage={setActivePage}
        containerClassName={baseClass("pagination-wrapper")}
        content={(page) => {
          const pageItems = BLOG_CARDS.slice(
            pageSize * page,
            pageSize * (page + 1)
          );
          return (
            <div className={baseClass("item-wrapper")}>
              {pageItems.map((item, index) => (
                <BlogCard
                  key={item.title + index}
                  img={item.img}
                  description={item.description}
                  date={item.date}
                  link={item.link}
                  title={item.title}
                  isUseMaxWidth={
                    activePage === 0 &&
                    (index === 0 || index === 1 || index === 2)
                  }
                />
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};
