import PropTypes from "prop-types";
import { Field } from "formik";
import { getBEMClasses } from "../../../../helpers/bemHelper";
import "./styles.css";
import { InfoButton } from "../InfoButton";

const baseClass = getBEMClasses("form-textarea");

export const Textarea = (props) => {
  const {
    name,
    label,
    placeholder = "",
    width,
    isRequired = true,
    error = false,
    infoText = "",
    ...rest
  } = props;
  return (
    <div className={baseClass("")} style={{ width: width ? width : "100%" }}>
      {!!label && (
        <label htmlFor={name} className={baseClass("label")}>
          {label}
          {isRequired && <span className={baseClass("required")}>*</span>}
          {!!infoText && <InfoButton infoText={infoText} />}
        </label>
      )}
      <div
        className={baseClass("input-wrapper", !!error && "error")}
        style={rest.style}
      >
        <Field
          as="textarea"
          id={name}
          name={name}
          placeholder={placeholder}
          className={baseClass("input")}
          rows="2"
          {...rest}
        />
      </div>

      {!!error && <div className={baseClass("error-text")}>{error}</div>}
    </div>
  );
};

Textarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  infoText: PropTypes.string,
};
