const URL = "https://api.liquidmarketplace.org/api/v1/public";

export const makePostRequest = (path, body) => {
  try {
    return fetch(`${URL}${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(body),
    }).then((response) =>
      response.status > 400 ? Promise.reject() : Promise.resolve(response.body)
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendMediaInqiries = (body) => {
  return makePostRequest("/send-email", body);
};

export const subscribeEmail = (email) => {
  return makePostRequest("/subscribe", { email });
};

export const sendConsignEmail = (values) => {
  return makePostRequest("/consignment", { ...values });
};

export const getDrops = async () => {
  try {
    const response = await fetch(`${URL}/landing-page`);
    return await response.json();
  } catch (err) {
    console.log(err);
  }
};
