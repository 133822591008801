import PropTypes from "prop-types";
import { getBEMClasses } from "../../helpers/bemHelper";
import { useIsTablet } from "../../hooks/useIsMobile";
import { DarkButton } from "../common/Buttons";
import { BASE_TRADING_URL } from "../../constants/trading";
import "./styles.css";
import { openLinkInNewTab } from "../../helpers/linksHelper";
import { Video } from "../common/Video";

const baseClass = getBEMClasses("collection-card");

export const CollectionCard = (props) => {
  const { id, tradeState, name, video } = props;
  const isTablet = useIsTablet();
  const link = `${BASE_TRADING_URL}/asset-details/${id}`;
  return isTablet ? (
    <div key={id} className={baseClass("card-wrapper")}>
      <div className={baseClass("card-state", { offering: !tradeState })}>
        {tradeState ? "Now Trading" : "Offering"}
      </div>
      <div
        className={baseClass("card")}
        onClick={() => {
          openLinkInNewTab(link);
        }}
      >
        <Video video={video} width={200} height={319} />
      </div>
      <div className={baseClass("card-name")}>{name}</div>
    </div>
  ) : (
    <div key={id} className={baseClass("card-wrapper")}>
      <div className={baseClass("card")}>
        <Video video={video} width={200} height={319} />
      </div>

      <div className={baseClass("card-hover-wrapper")}>
        <div className={baseClass("card-hover-info")}>
          <div
            className={baseClass("card-hover-state", { offering: !tradeState })}
          >
            {tradeState ? "Now Trading" : "Offering"}
          </div>
          <div className={baseClass("card-hover-name")}>{name}</div>
          <DarkButton
            width={180}
            title="BUY NOW"
            onClick={() => {
              openLinkInNewTab(link);
            }}
          />
        </div>
      </div>
    </div>
  );
};

CollectionCard.propTypes = {
  id: PropTypes.number,
  tradeState: PropTypes.shape({
    price: PropTypes.number.isRequired,
    isGrowing: PropTypes.bool.isRequired,
  }),
  name: PropTypes.string,
  video: PropTypes.string,
};
