import { getBEMClasses } from "../../helpers/bemHelper";
import { OwnAnything } from "./OwnAnything";
import { CoOwnSection } from "./CoOwnSection";
import { CollectionsSection } from "./CollectionsSection";
import { CollectSection } from "./CollectSection";
import { BlogsSection } from "./BlogsSection/Index";
import { PressSection } from "./PressSection";
import { DiscordSection } from "./DiscordSection";
import { CommunitySection } from "./CommunitySection";
import ConsignIcon from "../../assets/icons/consign.svg";
import { useIsTablet } from "../../hooks/useIsMobile";
import { Link } from "react-router-dom";
import "./styles.css";

const baseClass = getBEMClasses("home");

const HomePage = () => {
  const isTablet = useIsTablet();
  return (
    <div className={baseClass("")}>
      {isTablet && (
        <Link to="consign-with-us" className={baseClass("new-feature")}>
          <div className={baseClass("new-feature-text")}>
            New Feature Available
          </div>
          <div className={baseClass("consign-button")}>
            <img src={ConsignIcon} className={baseClass("consign-icon")} />
            Consign With Us
          </div>
        </Link>
      )}
      <OwnAnything />
      <CoOwnSection />
      <CollectionsSection />
      <CollectSection />
      <BlogsSection />
      <PressSection />
      <DiscordSection />
      <CommunitySection />
    </div>
  );
};

export { HomePage };
