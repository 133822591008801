import PropTypes from "prop-types";

export const Video = (props) => {
  const { width, height, video } = props;
  return (
    <video
      width={width}
      height={height}
      autoPlay
      loop
      playsInline
      controls=""
      muted
    >
      <source src={video} type="video/mp4" />
    </video>
  );
};

Video.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  video: PropTypes.string,
};
