import { GradientButton } from "../../../components/common/Buttons";
import CommunityImage from "./Community.png";
import { getBEMClasses } from "../../../helpers/bemHelper";
import "./styles.css";
import { openLinkInNewTab } from "../../../helpers/linksHelper";

const baseClass = getBEMClasses("community");

export const CommunitySection = () => {
  return (
    <div className={baseClass("")}>
      <img src={CommunityImage} className={baseClass("image")} />
      <div className={baseClass("title")}>
        Let’s collect together. create an account today!
      </div>
      <GradientButton
        title={"START NOW"}
        width={220}
        style={{ zIndex: 2 }}
        onClick={() =>
          openLinkInNewTab("https://trade.liquidmarketplace.io/signup")
        }
      />
    </div>
  );
};
