import Blog1 from "./images/Blog1.png";
import Blog2 from "./images/Blog2.png";
import Blog3 from "./images/Blog3.png";
import Blog4 from "./images/Blog4.png";
import Blog5 from "./images/Blog5.png";
import Blog6 from "./images/Blog6.png";
import Blog7 from "./images/Blog7.png";

export const BLOG_CARDS = [
  {
    img: Blog2,
    description:
      "Logan Paul has made quite the name for himself as a social media personality, entrepreneur, athlete, and ...",
    date: "Posted on July 6 2022, by Liquid MarketPlace",
    title: "Let’s Talk: Logan Paul",
    link: "logan-paul",
  },
  {
    img: Blog1,
    description:
      "Learn about Steve Aoki: what he’s done, what he’s doing, and what he’s planning to do. Listing two sports cards ...",
    date: "Posted on July 6 2022, by Liquid MarketPlace",
    title: "Let’s Talk: Steve Aoki",
    link: "steve-aoki",
  },
  {
    img: Blog3,
    description:
      "Darran Jack is one of the world of motorsports' most senior collectors, who has commanded the respect of ...",
    date: "Posted on July 6 2022, by Liquid MarketPlace",
    title: "Let’s Talk: Darren jack",
    link: "darren-jack",
  },
  {
    img: Blog4,
    description:
      "You buy tokens of an asset. We ensure that the asset is safe and sound ...",
    date: "Posted on July 6 2022, by Liquid MarketPlace",
    link: "trust-and-transparency",
    title: "Trust and Transparency",
  },
  {
    img: Blog5,
    description:
      "Don’t worry, there’s nothing mischievous going on at Liquid MarketPlace! When we say we are getting into the ...",
    date: "Posted on July 6 2022, by Liquid MarketPlace",
    link: "monkey-business",
    title: "Getting Into Monkey Business",
  },
  {
    img: Blog6,
    description:
      "The desire to collect goes back to the beginning of the human race: what used to be a survival method has ...",
    date: "Posted on July 6 2022, by Liquid MarketPlace",
    link: "expensive-collectibles",
    title: "The Expensive Side of Collectibles",
  },
  {
    img: Blog7,
    description:
      "Voting is an important human right in democracies across the world. To progress our goal of democratization of ...",
    date: "Posted on July 6 2022, by Liquid MarketPlace",
    link: "vote",
    title: "Using your right to vote",
  },
];
