import { Title } from "../../components/common/Title";
import { getBEMClasses } from "../../helpers/bemHelper";
import { openLinkInNewTab } from "../../helpers/linksHelper";
import "./styles.css";

const baseClass = getBEMClasses("privacy-policy");

const PrivacyPolicyPage = () => {
  return (
    <div className={baseClass("")}>
      <Title title={"Privacy Policy"} />
      <div className={baseClass("text-section-wrapper")}>
        <div className={baseClass("header-text")}>
          PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THIS PLATFORM
        </div>
        <div className={baseClass("date-text")}>
          Last Updated: March 29, 2022
        </div>
        <div className={baseClass("section-header")}>
          Our Commitment to Privacy
        </div>
        <div className={baseClass("section-text")}>
          Liquid Marketplace Inc. and its wholly-owned subsidiaries
          (collectively, “Liquid Marketplace”, “we”, “us” and “our”) is
          committed to protecting your Personal Information (as defined below)
          by ensuring that your Personal Information is treated with care and is
          not used or disclosed in ways to which you have not consented or as
          described in this privacy policy (“Policy”).
          <br />
          This Policy explains the ways in which we collect, use and disclose
          your Personal Information. It applies to any Personal Information that
          is collected by us, whether it is collected over the telephone, in
          writing, or electronically, including through any of our websites,
          mobile applications, or other digital platforms, online products or
          services that link to this Privacy Policy (each a “Platform” and
          collectively, the “Services”).
        </div>
        <div className={baseClass("section-text")}>
          We will only use your Personal Information in accordance with this
          Policy, unless otherwise permitted or required by applicable law.
          Please read this Policy carefully to understand our policies and
          practices for collecting, using, disclosing, processing, storing and
          deleting your Personal Information. By providing us with your Personal
          Information and using our Services, you indicate your acceptance of
          this Policy, as well as any other terms that are described to you when
          your Personal Information is collected. If you do not agree with or
          are not comfortable with any aspect of this Policy, please do not use
          the Services or otherwise provide your Personal Information to us.
        </div>
        <div className={baseClass("section-header")}>
          Changes to this Privacy Policy
        </div>
        <div className={baseClass("section-text")}>
          We may modify, revise or update this Policy at any time by updating
          this posting. We include the date the Policy was last revised at the
          top of the page. Each time you use the Services, the version of this
          Policy then posted will apply to that use, so you should check this
          page each time you use the Services. If we make material changes to
          how we treat Personal Information, we will post a notice on the
          homepage of our Platform and email notice of the change to registered
          users of our Platforms. You are responsible for ensuring we have
          up-to-date, active, and deliverable contact information for you, and
          for periodically visiting this page to check for any changes. Any
          modifications to this Privacy Policy will be effective upon our
          posting of the new terms. In all cases, your continued use of our
          Services after the posting of any modified Privacy Policy indicates
          your acceptance of the terms of the modified Privacy Policy. If you
          disagree with any change to this Policy, please refrain from using our
          Service or otherwise providing us with your Personal Information.
        </div>
        <div className={baseClass("section-header")}>
          What is Personal Information?
        </div>
        <div className={baseClass("section-text")}>
          “Personal Information” is information about an identifiable
          individual. This includes information that we can reasonably use on
          its own, or with other information we have, to directly or indirectly
          identify an individual. Personal Information generally does not
          include business contact information, including your name, title, or
          business contact information. The definition of Personal Information
          depends on the applicable law based on your physical location. Only
          the definition that applies to your physical location will apply to
          you under this Policy.
        </div>
        <div className={baseClass("section-header")}>
          What Information Do We Collect and How?
        </div>
        <div className={baseClass("section-text")}>
          The information we collect, and how that information is used, depends
          on how you use our Services.
        </div>
        <div className={baseClass("section-header")}>Personal Information</div>
        <div className={baseClass("section-text")}>
          Personal Information Collected Directly From You. Personal Information
          is collected by Liquid Marketplace when you provide it to us, such as
          when you provide us with your contact information or send us a
          question or comment through the Services, when you meet with us in
          person, or when you phone us with an inquiry.
        </div>
        <div className={baseClass("section-text")}>
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              Liquid Marketplace may collect the following types of Personal
              Information directly from you:
            </li>
            <li className={baseClass("section-list-text")}>
              your contact information such as your name, email address and
              phone number(s);
            </li>
            <li className={baseClass("section-list-text")}>
              information to establish an account with our Services, including a
              username and password chosen by you;
            </li>
            <li className={baseClass("section-list-text")}>
              digital asset addresses and electronic wallet addresses used to
              send and receive payments;
            </li>
            <li className={baseClass("section-list-text")}>
              your transaction history and account activity with us; and
            </li>
            <li className={baseClass("section-list-text")}>
              your comments and questions and other Personal Information you
              choose to share with us.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-text")}>
          We may not be able to serve you as effectively or offer you our
          services if you choose not to share certain Personal Information with
          us. Any Personal Information you provide to us that is not required is
          voluntary.
        </div>
        <div className={baseClass("section-text")}>
          We engage third party service providers to collect and assess any
          information required to fulfill know your client/anti-money laundering
          (“KYC/AML”) and know your token (“KYT”) requirements. Such information
          may include your full legal name, date of birth, photograph and
          residential address; name and confirmation of a financial account or
          credit file; and details associated with government-issued photo
          identification cards/documents and utility bills. This information is
          not shared with Liquid Marketplace and we are only provided a summary
          report of the results, which contains personal information that we
          already have on record for you. Please familiarize yourself with the
          privacy policies of these third party service providers.
        </div>
        <div className={baseClass("section-text")}>
          All transactions take place between users of the Platform. Liquid
          Marketplace is acting in the capacity of a service provider
          facilitating such transactions and is not a party to any transaction.
          All payments for transactions are processed by third party service
          providers. Payment and banking information is collected and stored by
          those providers and is subject to their privacy policies. Please
          familiarize yourself with the privacy policies of those payment
          providers before using their services. Liquid Marketplace only
          collects limited payment information (i.e., bank account number and
          SWIFT details) for outbound payments made by us, however such
          information is only used for the particular transaction and is not
          stored by us.
        </div>
        <div className={baseClass("section-text")}>
          If you sign-up for marketing and promotion communications, we may ask
          for your contact information such as your name and email address. You
          can choose to unsubscribe from such marketing and promotion
          communications at any time as described below under the heading
          entitled “Your Privacy Preferences”.
        </div>
        <div className={baseClass("section-text")}>
          Personal Information Collected from Third Parties. Liquid Marketplace
          or our agents or service providers may receive information about you
          from third parties and through publicly available sources, including
          the following:
        </div>
        <div className={baseClass("section-text")}>
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              Public Databases, Credit Bureaus & ID Verification Partners. Our
              KYC/AML and KYT service providers may obtain information about you
              from public databases and ID verification partners for purposes of
              verifying your identity in accordance with applicable law, in
              order to comply with legal obligations such as anti-money
              laundering laws. In some cases, we may process additional data
              about you to assess risk and ensure our Services are not used
              fraudulently or for other illicit activities. In such instances,
              processing is necessary for us to continue to perform our
              contractual obligations with you and others.
            </li>
            <li className={baseClass("section-list-text")}>
              Blockchain Data. We may analyze public blockchain data to ensure
              parties utilizing our Services are not engaged in illegal or
              prohibited activity under our Terms of Use, and to analyze
              transaction trends for research and development purposes.
            </li>
            <li className={baseClass("section-list-text")}>
              Joint Marketing Partners. Unless prohibited by applicable law,
              joint marketing partners may share information about you with us
              so that we can better understand which of our Services may be of
              interest to you.
            </li>
            <li className={baseClass("section-list-text")}>
              Advertising Networks & Analytics Providers. We work with these
              providers to provide us with de-identified information about how
              you found our Platform and how you interact with the Platform and
              Services. For more information on how you can manage collection of
              this information, please refer to the section entitled “Your
              Privacy Preferences” below.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-header")}>Technical Information</div>
        <div className={baseClass("section-text")}>
          When you visit our Platform, we may automatically collect the
          following information, which we refer to as “Technical Information” in
          this Policy. Technical Information is generally used on an aggregate
          and anonymized basis and in a non-personally identifiable form, but if
          we can or do use your Technical Information to identify you, including
          by sending you targeted advertising (as described below), we treat
          such Technical Information as Personal Information in accordance with
          this Policy.
        </div>
        <div className={baseClass("section-text")}>
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              Usage details. We may automatically collect certain details of
              your access to and use of our Platform, including traffic data,
              location data, logs and other communication data and resources
              that you interact with on or through the Platform, such as
              individual pages or products that you view and what websites or
              search terms referred you to the Platform.
            </li>
            <li className={baseClass("section-list-text")}>
              Device information. We may collect information about your device
              and internet connection, including the device’s unique device
              identifier, IP address, MAC address, operating system, browser
              type, mobile network information, and the device’s telephone
              number (where applicable).
            </li>
            <li className={baseClass("section-list-text")}>
              Stored information and files. The Platform also may, with your
              authorization, access metadata and other information associated
              with other files stored on your device. This may include, for
              example, photographs, audio and video clips, personal contacts,
              and address book information.
            </li>
            <li className={baseClass("section-list-text")}>
              Location information. If you permit us to identify your location
              when using the Platform, we will have access to your location and
              time zone. We do not collect real-time information about the
              location of your device. Any location information collected will
              be anonymized.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-text")}>
          The technologies we use for this automatic data collection may
          include:
        </div>
        <div className={baseClass("section-text")}>
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              Cookies are data files that are placed on your device or computer
              and often include an anonymous unique identifier. For more
              information about cookies, and how to disable cookies on your
              browser, visit{" "}
              <span
                className={baseClass("link")}
                onClick={() =>
                  openLinkInNewTab("https://www.allaboutcookies.org/")
                }
              >
                {`https://www.allaboutcookies.org/`}
              </span>
              . When using an app, it may be possible to refuse to accept mobile
              cookies by activating the appropriate setting on your smartphone.
              However, if you disable browser cookies or refuse to accept mobile
              cookies, you may be unable to use certain features or access
              certain parts of the Platform.
            </li>
            <li className={baseClass("section-list-text")}>
              Log files. Log files track actions occurring on the Platform, and
              collect data including your IP address, MAC address, browser type,
              Internet service provider, referring/exit pages, and date/time
              stamps.
            </li>
            <li className={baseClass("section-list-text")}>
              Web beacons. Small electronic files known as web beacons (also
              referred to as clear gifs, pixel tags, and single-pixel gifs)
              permit us, for example, to count users who have visited certain
              pages and other related statistics (for example, recording the
              popularity of certain content and verifying system and server
              integrity).
            </li>
          </ul>
        </div>
        <div className={baseClass("section-header")}>
          For What Purposes Do We Collect Your Information?
        </div>
        <div className={baseClass("section-text")}>Personal Information</div>
        <div className={baseClass("section-text")}>
          Generally, we collect and use Personal Information in order to:
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              fulfill the purposes for which you provided it or that were
              described when it was collected, or any other purpose for which
              you consented;
            </li>
            <li className={baseClass("section-list-text")}>
              provide you with information, products, or services that you
              request from us and to improve them;
            </li>
            <li className={baseClass("section-list-text")}>
              communicate with you, respond to your inquiries, provide you with
              technical and customer support;
            </li>
            <li className={baseClass("section-list-text")}>
              meet our legal, regulatory, audit, security and financial
              requirements under applicable law;
            </li>
            <li className={baseClass("section-list-text")}>
              manage our risk, including fraud, money laundering, terrorist
              financing and other risk;
            </li>
            <li className={baseClass("section-list-text")}>
              send you press releases, marketing materials or information about
              scheduled events for which we have your consent to receive under
              applicable law;
            </li>
            <li className={baseClass("section-list-text")}>
              administer contests, surveys, or other Platform features in which
              you have chosen to participate;
            </li>
            <li className={baseClass("section-list-text")}>
              provide you with targeted advertisements or other behavioural
              marketing (as described below); and
            </li>
            <li className={baseClass("section-list-text")}>
              investigate any violations of, and enforce compliance with, our
              Terms of Use and other terms and conditions of use.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-text")}>
          We do not collect more Personal Information than we require for the
          purposes for which Personal Information is being collected, unless we
          are required or permitted by law to collect additional information. We
          will notify the individual from whom the information is collected of
          the purposes for which we are collecting the information. This notice
          will be provided at or before the time that we collect the Personal
          Information. Depending on the way in which Personal Information is
          collected, this may be done orally or in writing. Other than as
          required or allowed by law, your Personal Information will not be used
          for any other purpose without your consent.
        </div>
        <div className={baseClass("section-header")}>Technical Information</div>
        <div className={baseClass("section-text")}>
          Technical Information may be used for the following purposes:
        </div>
        <div className={baseClass("section-text")}>
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              for Platform and system administration purposes;
            </li>
            <li className={baseClass("section-list-text")}>
              to improve the performance of the Platform and our Services;
            </li>
            <li className={baseClass("section-list-text")}>
              to provide you with a streamlined and personalized experience;
            </li>
            <li className={baseClass("section-list-text")}>
              to keep track of your specified preferences;
            </li>
            <li className={baseClass("section-list-text")}>
              to conduct internal reviews of the number of visitors to the
              Platform;
            </li>
            <li className={baseClass("section-list-text")}>
              to help us better understand how users interact with our Platform;
            </li>
            <li className={baseClass("section-list-text")}>
              to respond to customer support and other specific requests from
              users; and
            </li>
            <li className={baseClass("section-list-text")}>
              to prevent fraudulent activity and protect the security or
              integrity of our Platform.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-header")}>
          To Whom Do We Disclose Your Information?
        </div>
        <div className={baseClass("section-text")}>
          We do not sell or rent Personal Information. We may disclose Personal
          Information that we collect as described in this Policy with your
          consent for any other purpose we disclose when you provide the
          information or as permitted or required by applicable law. When
          Personal Information that has been collected is to be used for a
          purpose that we have not previously identified, we will obtain your
          consent for the new purpose, unless the new purpose is required or
          permitted by law.
        </div>
        <div className={baseClass("section-text")}>
          We may disclose aggregated and anonymized information, which no longer
          allows an individual to be directly or indirectly identified, for
          study or research purposes or for the production of statistics.
        </div>
        <div className={baseClass("section-text")}>
          We may share or disclose your Personal Information in the following
          circumstances:
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              Subsidiaries and Affiliates. To our subsidiaries and affiliates
              for the purposes that you consented to.
            </li>
            <li className={baseClass("section-list-text")}>
              Service Providers. To contractors, service providers, technology
              providers and other affiliated or third parties we use to support
              our business. For instance, we use service providers to host our
              servers, send communications on our behalf, conduct KYC/AML and
              KYT compliance and process transactions. Our service providers are
              given access to the information they need to perform their
              designated functions for uses you have consented to, and we do not
              authorize them to use or disclose personal information for any
              other purposes, including their own purposes or the purposes of
              any third party. Service providers are also required to have
              appropriate security measures in place to protect your Personal
              Information whether it is in transit or in storage.
            </li>
            <li className={baseClass("section-list-text")}>
              Sale or Business Transaction. In accordance with applicable law,
              to a buyer or other successor in the event of a proposed and
              completed merger, divestiture, restructuring, reorganization,
              dissolution, other sale or transfer or business transaction
              involving all or part of our company or our assets, including for
              the purposes of determining whether to proceed with such
              transaction.
            </li>
            <li className={baseClass("section-list-text")}>
              Law Enforcement. Liquid Marketplace, our affiliates and related
              companies and our Canadian, U.S. and other service providers may
              provide your personal information in response to a search warrant
              or other legally valid inquiry or order, or to an investigative
              body in the case of a breach of an agreement or contravention of
              law, or as otherwise required by applicable Canadian, U.S. or
              other law.
            </li>
            <li className={baseClass("section-list-text")}>
              Enforce our Rights. We may also disclose personal information
              where necessary for the establishment, exercise or defence of
              legal claims, or as otherwise permitted by law, including to
              enforce our rights arising from our Terms of Use and any other
              contracts between you and us.
            </li>
            <li className={baseClass("section-list-text")}>
              Protect our Rights. If we believe disclosure is necessary or
              appropriate to protect the rights, property, or safety of us, our
              customers, or others. This includes exchanging information with
              other companies and organizations for fraud prevention and credit
              risk reduction.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-header")}>
          Collection of Information from Minors
        </div>
        <div className={baseClass("section-text")}>
          Our Services are not intended for use by and we do not knowingly
          collect any personal information from minors, i.e., those under the
          age of majority in their jurisdiction of residence or not legally
          capable of entering into a contract. If you are a minor, you may not
          use the Services. If we discover that we have such information in our
          possession, we will destroy it.
        </div>
        <div className={baseClass("section-header")}>
          Use of Automated Systems
        </div>
        <div className={baseClass("section-text")}>
          We may use both manual and automated systems, such as machine learning
          and artificial intelligence, to: analyze your Personal Information and
          Technical Information to improve our Platform; make decisions;
          personalize your experience; automate certain aspects of the Platform;
          aggregate, de-identify or anonymize your information; provide you
          certain features; and protect the safety and security of our Platform.{" "}
        </div>
        <div className={baseClass("section-header")}>Targeted Advertising</div>
        <div className={baseClass("section-text")}>
          We may use the technologies described above under the heading
          “Technical Information” to collect information about your activities
          over time and across third party websites, apps or other online
          services (i.e., behavioural tracking), so that we can tailor our
          advertising to you that suits your interests, preferences and usage
          patterns.
        </div>
        <div className={baseClass("section-text")}>
          We may use the information we have collected from you to display
          advertisements to our advertisers’ target audiences. Even though we do
          not disclose your Personal Information for these purposes without your
          consent, if you click on or otherwise interact with an advertisement,
          the advertiser may assume that you meet its target criteria.
        </div>
        <div className={baseClass("section-text")}>
          Personal Information may be used by Liquid Marketplace and disclosed
          to Facebook for the purpose of delivering targeted advertising to you
          and other individuals on Facebook and for building lookalike
          audiences, using Facebook’s Custom Audiences and Lookalike Audiences
          features.
        </div>
        <div className={baseClass("section-text")}>
          Third Party Information Collection. When you download or use the
          Platform or its content, certain third parties may collect information
          about you or your device. These third parties may include:
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              advertisers, ad networks, and ad servers
            </li>
            <li className={baseClass("section-list-text")}>
              analytics companies
            </li>
            <li className={baseClass("section-list-text")}>
              your mobile device manufacturer
            </li>
            <li className={baseClass("section-list-text")}>
              your mobile service provider
            </li>
            <li className={baseClass("section-list-text")}>
              the third party app provider/vendor.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-text")}>
          These third parties may use cookies alone or in conjunction with other
          tracking technologies to collect information about you when you use
          the Platform. The information they collect may be associated with your
          Personal Information or they may collect information, including
          Personal Information, about your online activities over time and
          across different websites, apps and other online services. They may
          use this information to provide you with behavioural advertising or
          other targeted content. We do not control these third parties’
          tracking technologies or how they use them. If you have any questions
          about an advertisement or other targeted content, you should contact
          the responsible provider directly.
        </div>
        <div className={baseClass("section-text")}>
          In addition to helping advertisers reach the right people for their
          products and services, behavioural advertising helps support the
          Platform so that you can enjoy free content. To learn more about how
          targeted advertising works, you can visit the Network Advertising
          Initiative’s educational page at{" "}
          <span
            className={baseClass("link")}
            onClick={() =>
              openLinkInNewTab(
                "http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
              )
            }
          >
            {`http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work`}
          </span>
          . For information on how to opt-out of targeted advertisings, please
          see the section entitled “Your Privacy Preferences” below.
        </div>
        <div className={baseClass("section-header")}>Security Safeguards</div>
        <div className={baseClass("section-text")}>
          <div className={baseClass("paragraph-title")}>Our Obligation</div>
          We implement security safeguards to protect Personal Information
          against loss or theft, as well as unauthorized access, disclosure,
          copying, use or modification, regardless of the format in which the
          information is held. We use physical, organizational and technological
          measures to protect Personal Information, such as locked filing
          cabinets, firewalls, encryption, and employee education. The measures
          we take will vary depending on the sensitivity, format, location, and
          storage of the Personal Information. We ensure that our service
          providers are contractually obligated to adhere to appropriate
          security safeguards.
        </div>
        <div className={baseClass("section-text")}>
          While Liquid Marketplace employs advanced data encryption technology
          when interfacing with our users, third party service providers and
          other partners, you should be aware that there is a residual risk in
          transmitting any data electronically. Despite our efforts, no security
          measures are perfect or impenetrable and no method of data
          transmission can be guaranteed against any interception or other type
          of misuse. This risk is inherent in all Internet dealings.
        </div>
        <div className={baseClass("section-text")}>
          <div className={baseClass("paragraph-title")}>Your Obligations</div>
          The safety and security of your Personal Information also depends on
          you. While many of our Services are publicly accessible, access to
          your account information and some of our Services are accessible only
          through the use of an individual user ID and password. To protect the
          confidentiality of Personal Information, you must keep your password
          confidential and not disclose it to any other person. Please advise us
          immediately if you believe that the confidentiality of your password
          has been compromised or that your password has been misused. In
          addition, always log out and close your browser when you finish your
          session. Please note that we will never ask you to disclose your
          password in an unsolicited phone call or email. LIQUID MARKETPLACE IS
          NOT RESPONSIBLE FOR ACTIONS TAKEN REGARDING YOUR ACCOUNT WHILE A USER
          IS LOGGED IN USING YOUR USER ID AND PASSWORD.
          <br />
          We are not responsible for losses due to blockchains or any other
          features of the Platform, the Protocols, our service providers or any
          electronic wallet, including but not limited to late report by
          developers or representatives (or no report at all) of any issues with
          the Protocols, including forks, technical node issues, or any other
          issuers having fund losses as a result.
        </div>
        <div className={baseClass("section-header")}>
          Retention & Disposal of Personal Information
        </div>
        <div className={baseClass("section-text")}>
          We will keep Personal Information only for as long as it remains
          necessary for the purposes for which it was collected, to fulfil our
          legal, regulatory or reporting requirements, or as required by
          applicable law. There is no single retention period applicable to the
          various types of personal information collected. Please contact us if
          you would like to delete any personal information we hold about you,
          where provided for by applicable law as described below under the
          heading “Your Privacy Rights”.
        </div>
        <div className={baseClass("section-text")}>
          Note that we will retain Personal Information to the extent necessary
          to send you marketing communications in accordance with your marketing
          preferences, which you can change at any time as described below under
          the “Your Privacy Preferences” heading. We also reserve the right to
          continue to hold Personal Information about you to the extent it is
          required to be held by us by applicable law, rule or regulation.
        </div>
        <div className={baseClass("section-text")}>
          When your Personal Information is no longer required, we will make all
          reasonable efforts to ensure all electronic and hard copies of such
          information are securely destroyed or anonymized. However, where your
          information has been provided to third parties for certain purposes,
          such as payment processing, those third parties, such as payment
          processors and financial institutions, may retain the information
          according to their own retention and privacy policies.
        </div>
        <div className={baseClass("section-text")}>
          Under some circumstances we may instead anonymize your Personal
          Information so that at all times it is reasonable to expect in the
          circumstances that it irreversibly no longer allows you to be directly
          or indirectly identified. We reserve the right to use such anonymized
          information for any serious and legitimate purpose without further
          notice to you or your consent, as permitted by applicable law.
        </div>
        <div className={baseClass("section-header")}>
          Cross Border Data Transfers
        </div>
        <div className={baseClass("section-text")}>
          Your Personal Information may be processed or stored on servers or
          cloud providers located outside of the jurisdiction in which such
          information was originally collected, including outside of Canada. As
          a result, your Personal Information may be subject to the laws of such
          foreign jurisdiction and may be accessible without notice to you by
          the courts, law enforcement and national security authorities of that
          jurisdiction. You can obtain more information about our policies and
          practices with respect to the use of non-Canadian service providers by
          contacting us as described below under the “Contact Us” heading.
        </div>
        <div className={baseClass("section-header")}>
          Links to Third Party Platforms
        </div>
        <div className={baseClass("section-text")}>
          Our Services may provide links or references to websites or other
          platforms operated by third parties. These third party platforms are
          not governed by this Policy but by other privacy policies that may
          differ from our Policy. This Policy does not extend to the collection
          of Personal Information by these third parties and we do not assume
          any responsibility for the privacy practices, policies or actions of
          third parties. In visiting any third party platforms, whether linked
          to our Services or otherwise, you do so at your own risk and you
          assume all responsibility in that regard. We make no representations
          or warranties regarding, and do not endorse, any third party platforms
          or any content in such platform. We encourage you to review the
          privacy policies of each platform visited before using those platforms
          or disclosing any Personal Information to third parties.{" "}
        </div>
        <div className={baseClass("section-header")}>Your Privacy Rights</div>
        <div className={baseClass("section-text")}>
          You have certain rights with respect to your Personal Information that
          we collect and process, such as described below. Your privacy rights
          differ depending on your jurisdiction of residence. To exercise any of
          your privacy rights, please contact us as set out below under the
          “Contact Us” heading.
        </div>
        <div className={baseClass("section-text")}>
          <div className={baseClass("paragraph-title")}>
            Residents of Canada
          </div>
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              Updating Your Personal Information. We want to make sure that the
              Personal Information that we have collected about you is accurate,
              complete, and current. However, we will not routinely update this
              information, unless it is necessary for us to do so to fulfill the
              purposes for which we collected the Personal Information. Since we
              generally rely on you to inform us if there have been any changes
              to the Personal Information that we have collected about you,
              please let us know if any of this information has changed.
            </li>
            <li className={baseClass("section-list-text")}>
              Access to and Correction of Personal Information. If you have any
              questions about the accuracy of the Personal Information that we
              have collected about you, you can submit a written request to
              access that Personal Information in order to verify and update it,
              unless we are permitted or required under applicable laws to
              refuse your access to such Personal Information.
            </li>
            <li className={baseClass("section-list-text")}>
              Withdrawal of Consent. Where you have provided your consent to the
              collection, use, and transfer of your Personal Information, you
              may have the legal right to withdraw your consent under certain
              circumstances. Your withdrawal will not affect the lawfulness of
              our processing your Personal Information based on consent before
              your withdrawal. Please note that if you withdraw your consent we
              may not be able to provide you with a particular product or
              service. We will explain the impact to you at the time to help you
              make your decision.
            </li>
            <li className={baseClass("section-list-text")}>
              Right to be Forgotten/Restrict the Dissemination of Information.
              In certain jurisdictions, you may have the right to restrict the
              dissemination of your personal information or to request that
              hyperlinks associated with your name and that provide access to
              your personal information be de-indexed as prescribed by
              applicable law.
            </li>
            <li className={baseClass("section-list-text")}>
              Automated Decision-Making. In certain jurisdictions, you may have
              the right to be informed when your Personal Information was used
              to make a decision based exclusively on automated processing of
              Personal Information. Upon request, you may be entitled to further
              information regarding such automated decision-making.
            </li>
            <li className={baseClass("section-list-text")}>
              Right to Data Portability. In certain jurisdictions, you may have
              the right to request that an electronic copy of your personal
              information be transferred or ported to a third party as
              prescribed by applicable law.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-text")}>
          <div className={baseClass("paragraph-title")}>
            Residents of the European Union (EU)
          </div>
          Additionally, residents of the EU have the right to:
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              Obtain confirmation as to whether or not their Personal
              Information exists and to be informed of its content and source,
              verify its accuracy and request its completion, update or
              amendment.
            </li>
            <li className={baseClass("section-list-text")}>
              Request the deletion, anonymization or restriction of the
              processing of their Personal Information processed in breach of
              the applicable law.
            </li>
            <li className={baseClass("section-list-text")}>
              Object to the processing, in all cases, of their Personal
              Information for legitimate reasons.
            </li>
            <li className={baseClass("section-list-text")}>
              Receive an electronic copy of their Personal Information, if they
              would like such a copy for themselves or to port their Personal
              Information to a different provider.
            </li>
            <li className={baseClass("section-list-text")}>
              Lodge a complaint with the relevant data protection supervisory
              authority.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-header")}>
          Your Privacy Preferences
        </div>
        <div className={baseClass("section-text")}>
          We strive to provide you with choices regarding the Personal
          Information you provide to us. We have created mechanisms to provide
          you with the following controls over your information:
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              Cookies. For more information about cookies, and how to disable
              cookies on your browser, visit https://www.allaboutcookies.org/.
              When using a mobile application, it may be possible to refuse to
              accept mobile cookies by activating the appropriate setting on
              your smartphone. However, if you disable browser cookies or refuse
              to accept mobile cookies, you may be unable to use certain
              features or access certain parts of the Platform.
            </li>
            <li className={baseClass("section-list-text")}>
              Google Analytics. You can opt out of Google Analytics without
              affecting how you visit the Platform. For more information on
              opting-out of Google Analytics across all websites you use, visit
              this Google page:
            </li>
          </ul>
        </div>
        <div className={baseClass("section-text")}>
          <span
            className={baseClass("link")}
            onClick={() =>
              openLinkInNewTab(
                "https://support.google.com/analytics/answer/181881?hl=en&ref_topic=2919631"
              )
            }
          >
            https://support.google.com/analytics/answer/181881?hl=en&ref_topic=2919631
          </span>
        </div>
        <div className={baseClass("section-text")}>
          <ul className={baseClass("section-list")}>
            <li className={baseClass("section-list-text")}>
              Targeted Advertising. You can opt-out of targeted advertising by
              visiting the Digital Advertising Alliance’s opt-out portal at:{" "}
              <span
                className={baseClass("link")}
                onClick={() =>
                  openLinkInNewTab("https://optout.aboutads.info/?c=3&lang=en")
                }
              >
                https://optout.aboutads.info/?c=3&lang=en
              </span>{" "}
              (Canadian users) or{" "}
              <span
                className={baseClass("link")}
                onClick={() => openLinkInNewTab("http://optout.aboutads.info/")}
              >
                http://optout.aboutads.info/
              </span>{" "}
              (U.S. users). Please note that we do not alter our Platform’s data
              collection and use practices when we see a Do Not Track signal
              from your browser. You can opt-out of several third party ad
              servers’ and networks’ cookies simultaneously by using the opt-out
              tool created by the Digital Advertising Alliance. You can also
              access these websites to learn more about online behavioural
              advertising and how to stop websites from placing cookies on your
              device. Note that opting out does not mean you will no longer
              receive online advertising. It does mean that the network from
              which you opted out will no longer deliver ads tailored to your
              web preferences and usage patterns.
            </li>
            <li className={baseClass("section-list-text")}>
              Marketing & Promotional Communications. You may choose not to
              receive marketing and promotional emails, text messages, direct
              messages or other electronic communications from us by declining
              to receive such communications at the time of registration or at
              the time you otherwise provide your Personal Information to us.
              You may also opt-out of receiving marketing and promotional
              emails, text messages, direct messages or other electronic
              communications from us by following the instructions in those
              communications or by sending an email to
              support@liquidmarketplace.io. Note that if you opt out, we may
              still send you transactional messages such as emails about your
              account, our ongoing business relationship or your use of our
              Platform.
            </li>
          </ul>
        </div>
        <div className={baseClass("section-header")}>Contact Us</div>
        <div className={baseClass("section-text")}>
          We are accountable for all Personal Information in our custody and
          control and have designated an individual, who is ultimately
          accountable for the handling of such Personal Information and for
          ensuring that the principles described in this Policy are being
          complied with. If you have any concerns, complaints, would like to
          exercise your privacy rights, or would like further information
          regarding our privacy policies or practices, please contact our
          Privacy Officer at{" "}
          <span className={baseClass("link")}>
            Privacy@liquidmarketplace.io
          </span>
          .
        </div>
        <div className={baseClass("section-text")}>
          If you are a Canadian resident are you are not satisfied with our
          response to a privacy-related matter, you may contact the Office of
          the Privacy Commissioner of Canada at{" "}
          <span className={baseClass("link")}>1-800-282-1376</span> or at{" "}
          <span
            className={baseClass("link")}
            onClick={() => openLinkInNewTab("https://priv.gc.ca/")}
          >
            www.priv.gc.ca
          </span>
          .
        </div>
        <div className={baseClass("additional-text")}>
          1 .Liquid Marketplace Custody Inc., Liquid Marketplace Assets Inc.,
          Liquid Marketplace Technology Inc., Liquid Marketplace Platforms Inc.,
          and Liquid Marketplace Services Inc.
        </div>
      </div>
    </div>
  );
};

export { PrivacyPolicyPage };
