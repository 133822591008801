import { useState } from "react";
import { Title } from "../../components/common/Title"
import { SubmitForm } from "./SubmitForm"
import { sendMediaInqiries } from "../../helpers/fetchHelper";
import "./styles.css";

export const MediaInquiriesPage = () => {
  const [ form, setForm ] = useState({ isSubmitted: false, isReqSuccess: false });

  const onSubmit = (data) => {
    sendMediaInqiries(data)
      .then(() => {
        setForm({
          isSubmitted: true,
          isReqSuccess: true,
        })
      })
      .catch(() => {
        setForm({
          isSubmitted: true,
          isReqSuccess: false,
        })
      })
  }

  return (<div className={'media__wrapper'}>
    <Title title='media inquiries'/>
    <div className="submit-form-container">
      {form.isSubmitted && form.isReqSuccess ?
      <span className="submit-form-container__success">Thank you for submitting!</span> :
      <SubmitForm error={form.isSubmitted && !form.isReqSuccess ? 'Sorry, seems we expirencing some troubles. Try again later' : ''} onSubmit={onSubmit}/>}
    </div>
  </div>)
}