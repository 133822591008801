import { PropTypes } from "prop-types";
import { DarkButton } from "../../../components/common/Buttons";
import { getBEMClasses } from "../../../helpers/bemHelper";
import "./styles.css";

const baseClass = getBEMClasses("consign-with-us-info");

export const InfoBlock = (props) => {
  const { onNextClick } = props;
  return (
    <>
      <div className={baseClass("text")}>
        We appreciate your interest in selling your collectibles through our
        eBay consignment service. The Fee Schedule payout rate is the exact
        payout you will receive for the sale of your item on eBay
      </div>
      <div className={baseClass("table-wrapper")}>
        <div className={baseClass("table-column")}>
          <div className={baseClass("table-title")}>Sale Price(Usd):</div>
          <div className={baseClass("table-title-divider")} />
          <div className={baseClass("table-content")}>
            <div className={baseClass("table-row")}>$10,000+</div>
            <div className={baseClass("table-row")}>$5000 - $9999</div>
            <div className={baseClass("table-row")}>$2500 - $4999</div>
            <div className={baseClass("table-row")}>$1000 - $2499</div>
            <div className={baseClass("table-row")}>$100 - $999</div>
            <div className={baseClass("table-row")}>$50 - $99</div>
          </div>
        </div>
        <div className={baseClass("table-column")}>
          <div className={baseClass("table-title")}>Payout rate:</div>
          <div className={baseClass("table-title-divider")} />
          <div className={baseClass("table-content")}>
            <div className={baseClass("table-row")}>90%</div>
            <div className={baseClass("table-row")}>88%</div>
            <div className={baseClass("table-row")}>86%</div>
            <div className={baseClass("table-row")}>84%</div>
            <div className={baseClass("table-row")}>83%</div>
            <div className={baseClass("table-row")}>80%</div>
          </div>
        </div>
      </div>
      <DarkButton
        title="START"
        style={{
          margin: `0 auto`,
          height: 68,
          borderRadius: 40,
          fontSize: 28,
          boxShadow: `0px 5px 30px rgba(228, 221, 250, 0.4)`,
          marginBottom: 40,
        }}
        width={274}
        onClick={() => onNextClick()}
      />
    </>
  );
};

InfoBlock.propTypes = {
  onNextClick: PropTypes.func,
};
