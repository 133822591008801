import { getBEMClasses } from "../../../../helpers/bemHelper";
import { useMedia } from "react-use";
import Image1 from "./images/image1.png";
import Image2 from "./images/image2.png";
import { ShareButton } from "../../../../components/common/Buttons";
import "../../styles.css";

const baseClass = getBEMClasses("blog");

export const Vote = () => {
  const isMobileImage = useMedia("(max-width: 500px)");
  return (
    <>
      <div className={baseClass("title")}>Using Your Right to Vote</div>
      <div className={baseClass("date")}>
        Posted on July 6 2022, by Liquid MarketPlace
      </div>
      <div className={baseClass("text")} style={{ marginTop: 30 }}>
        Here at Liquid MarketPlace, we wanted to maintain the essence of what
        ownership truly means. We decided to democratize the platform by
        allowing token holders to vote on the future of an asset — specifically,
        on whether it should go to auction.
      </div>
      <img
        src={Image1}
        className={baseClass("full-width-img")}
        style={{ marginTop: 60, marginBottom: 60 }}
      />
      <div className={baseClass("text")}>
        If you are confused about how this voting feature will work, no need to
        fret: by the time you are done reading this, you’ll be more than ready
        to utilize your right to vote in any and all upcoming Liquid MarketPlace
        decisions.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Just like voting in an election, the voting feature has specific days
        and periods that you can use it. Every 30 days, there is a 36-hour
        period where you can vote, with each collectible operating on its own
        30-day cycle.
      </div>
      <img
        src={Image2}
        className={baseClass("center-img")}
        style={{
          marginTop: 40,
          maxHeight: 300,
          maxWidth: isMobileImage ? "100%" : 435,
          width: isMobileImage ? "stretch" : 435,
        }}
      />
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        {`Of course, you are not voting for an asset to run the country. Rather, you are voting on whether you want to bring the collectible to auction. When you are voting in favor of bringing an item to auction, that means you would like for it to be removed from the platform and put onto an auction block at an auction house. Something important to remember is that once your vote is cast, it’s binding. As well, we at Liquid MarketPlace cannot control how much the collectible sells for at the auction! That is all up to the buyers, of course. As I’m sure you've seen in television or movies, auctions all start at one dollar and are sold to the highest bidder. Whatever the highest bid is, meaning whatever amount the collectible sells at, is final. For a collectible to go to auction 80% must vote in favor. If 20 percent plus one vote against the auction, then the collectible remains on the platform. Remember that one token equals one vote, so when you place your vote you are voting on behalf of all the tokens you own!`}
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Now, when the voting period is commencing, some things do change. Once
        the voting period has started, you cannot trade tokens of that
        collectible; the market freezes until the voting period is over (36
        hours later). This ensures that the voting process is fair to current
        owners of the collectibles.
      </div>
      <div
        className={baseClass("text")}
        style={{ marginTop: 40, marginBottom: 80 }}
      >
        So, is your brain buzzing with information yet? Take some time to absorb
        all this, and then hop on Liquid MarketPlace and take advantage of your
        newly appointed voting right!
      </div>
      <ShareButton />
    </>
  );
};
