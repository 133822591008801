import PropTypes from "prop-types";
import { SubmitButton } from "../../../components/common/Form/SubmitButton";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { ResultTable } from "../ResultTable";
import "./styles.css";

const baseClass = getBEMClasses("consign-with-us-fourth-step");

export const ConsignFourthStep = (props) => {
  const { values, submitForm } = props;
  return (
    <div className={baseClass("form")}>
      <div className={baseClass("title")}>
        Thank you for listing with us! Please review the details carefully, then
        send your collectible to the address below.
      </div>
      <div className={baseClass("address-title")}>Shipping Address:</div>
      <div className={baseClass("address")}>Liquid MarketPlace Inc.</div>
      <div className={baseClass("address")}>
        Inc. 1703 - 480 University Avenue, Toronto, ON, Canada, M5G 1V2
      </div>
      <ul className={baseClass("list")}>
        <li className={baseClass("list-item")}>
          We pay all fees associated to selling on eBay
        </li>
        <li className={baseClass("list-item")}>
          We deal with all dispute processes
        </li>
        <li className={baseClass("list-item")}>
          We follow industry standards. All items start at $0.99 and have no
          reserve feature.
        </li>
      </ul>
      <div className={baseClass("warning")}>
        Please be aware sending to the wrong address may result in lost or
        stolen collectibles. Make sure to keep your shipment tracking ID safe in
        the event of a lost package.
      </div>
      <ResultTable values={values} />
      <SubmitButton
        label="submit"
        style={{ marginLeft: "auto", marginRight: "auto" }}
        onClick={() => {
          submitForm(values);
        }}
      />
    </div>
  );
};

ConsignFourthStep.propTypes = {
  values: PropTypes.object,
  submitForm: PropTypes.func,
};
