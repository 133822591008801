import { getBEMClasses } from "../../../helpers/bemHelper";
import CommunityVideo from "../../../assets/videos/community.mp4";
import EvaluationVideo from "../../../assets/videos/evaluation.mp4";
import MarketPlaceVideo from "../../../assets/videos/marketplace.mp4";
import TokenizationVideo from "../../../assets/videos/tokenization.mp4";
import { useIsTablet } from "../../../hooks/useIsMobile";
import "./styles.css";
import { Video } from "../../../components/common/Video";

const baseClass = getBEMClasses("about-us");

export const CollectSection = () => {
  const isTablet = useIsTablet();
  const videoSize = isTablet ? 150 : 200;
  return (
    <div className={baseClass("collect-section")}>
      <div className={baseClass("collect-section-card")}>
        <Video width={videoSize} height={videoSize} video={EvaluationVideo} />
        <div className={baseClass("collect-section-card-title")}>
          Evaluation
        </div>
        <div className={baseClass("collect-section-card-text")}>
          Assets are carefully selected, authenticated, and appraised by
          industry experts. They are then moved to a highly secured third-party
          vault.
        </div>
      </div>
      <div className={baseClass("collect-section-card")}>
        <Video width={videoSize} height={videoSize} video={TokenizationVideo} />
        <div className={baseClass("collect-section-card-title")}>
          Co-ownership
        </div>
        <div className={baseClass("collect-section-card-text")}>
          The total value of a collectible is split into equal portions, where
          one portion costs $0.10 cents. Start owning rare collectibles with
          other people and buy what you are comfortable spending. Co-owners
          decide what happens to the collectible. Do you want to sell it in an
          auction, hold it or accept a buyout offer? The choice is with the
          co-owners!
        </div>
      </div>
      <div className={baseClass("collect-section-card")}>
        <Video width={videoSize} height={videoSize} video={MarketPlaceVideo} />
        <div className={baseClass("collect-section-card-title")}>
          Marketplace
        </div>
        <div className={baseClass("collect-section-card-text")}>
          After each asset is sold out from ‘Offerings’, it is moved to the
          ‘MarketPlace’ where users can buy, sell, and trade tokens.
        </div>
      </div>
      <div className={baseClass("collect-section-card")}>
        <Video width={videoSize} height={videoSize} video={CommunityVideo} />
        <div className={baseClass("collect-section-card-title")}>Community</div>
        <div className={baseClass("collect-section-card-text")}>
          As a member of the Liquid MarketPlace community, you have the power to
          cast votes that decide the future of the assets you own tokens of,
          specifically whether it goes to auction or not. Liquid MarketPlace
          also gives collectors the opportunity to collect with friends as well
          as join our Discord, where you will become a part of our established
          community of like-minded collectors.
        </div>
      </div>
    </div>
  );
};
