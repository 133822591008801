import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { Form } from "../../../components/common/Form";
import { Input } from "../../../components/common/Form/Input";
import { Textarea } from "../../../components/common/Form/Textarea";
import { SubmitButton } from "../../../components/common/Form/SubmitButton";
import { Select } from "../../../components/common/Form/Select";
import * as Yup from "yup";
import { Formik } from "formik";
import { useIsTablet } from "../../../hooks/useIsMobile";
import Countries from "countrycitystatejson";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const baseClass = getBEMClasses("consign-with-us-first-step");

const COUNTRIES_BLACK_LIST = [
  "RU",
  "BY",
  "CU",
  "IR",
  "KP",
  "SO",
  "SS",
  "SD",
  "SY",
  "ZW",
  "LY",
];

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string()
    .email("Invalid email. Please try again.")
    .required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  address: Yup.string().required("Address is required"),
  country: Yup.string().required("Country is required"),
  provinceState: Yup.string().required("Province / State is required"),
  city: Yup.string().required("City is required"),
  postalZipCode: Yup.string().required("Postal / Zip code is required"),
});

export const ConsignFirstStep = (props) => {
  const { onNextClick, saveStepValues, initialValues } = props;
  const [selectedCountry, setSelectedCountry] = useState(
    initialValues.selectedCountry ? initialValues.selectedCountry : ""
  );
  const defaultInitialValues = !isEmpty(initialValues)
    ? initialValues
    : {
        fullName: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        provinceState: "",
        city: "",
        postalZipCode: "",
      };
  const isTablet = useIsTablet();
  const countriesOptions = useMemo(
    () =>
      Countries.getCountries()
        .filter((item) => !COUNTRIES_BLACK_LIST.includes(item.shortName))
        .map((item) => ({
          label: item.name,
          value: item.name,
          shortName: item.shortName,
        })),
    []
  );
  const provinceStateOptions = useMemo(
    () =>
      selectedCountry
        ? Countries.getStatesByShort(selectedCountry.shortName).map((item) => ({
            label: item,
            value: item,
          }))
        : [],
    [selectedCountry.shortName]
  );

  return (
    <Formik
      onSubmit={(values) => {
        saveStepValues({ ...values, selectedCountry }, 1);
        onNextClick();
      }}
      initialValues={defaultInitialValues}
      validationSchema={ValidationSchema}
    >
      {({ errors, touched }) => {
        return (
          <Form style={{ gap: isTablet ? 30 : 40 }}>
            <Input
              name="fullName"
              placeholder="Type your full name here"
              label="Full name"
              style={{ width: isTablet ? "100%" : 290 }}
              error={errors.fullName && touched.fullName ? errors.fullName : ""}
            />
            <div className={baseClass("row")}>
              <Input
                name="email"
                placeholder="Type your email here"
                label="email"
                error={errors.email && touched.email ? errors.email : ""}
              />
              <Input
                name="phone"
                placeholder="Type your phone number here"
                label="Phone number"
                error={errors.phone && touched.phone ? errors.phone : ""}
              />
            </div>
            <Textarea
              name="address"
              placeholder="Type your address here"
              label="Address"
              error={errors.address && touched.address ? errors.address : ""}
            />
            <div className={baseClass("row")}>
              <Select
                name="country"
                label="Country"
                options={countriesOptions}
                error={errors.country && touched.country ? errors.country : ""}
                defaultTitle={"Select your country"}
                customChange={(value) => {
                  setSelectedCountry(value);
                }}
              />
              <Select
                name="provinceState"
                label="Province/state"
                options={provinceStateOptions}
                error={
                  errors.provinceState && touched.provinceState
                    ? errors.provinceState
                    : ""
                }
                defaultTitle={"Select your province/state"}
              />
            </div>
            <div className={baseClass("row")}>
              <Input
                name="city"
                placeholder="Type your city here"
                label="City"
                error={errors.city && touched.city ? errors.city : ""}
              />
              <Input
                name="postalZipCode"
                placeholder="Type your postal/zip code"
                label="Postal/Zip code"
                error={
                  errors.postalZipCode && touched.postalZipCode
                    ? errors.postalZipCode
                    : ""
                }
              />
            </div>
            <SubmitButton
              label="next"
              style={{ marginLeft: "auto", marginRight: "auto", marginTop: 50 }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

ConsignFirstStep.propTypes = {
  onNextClick: PropTypes.func,
  saveStepValues: PropTypes.func,
  initialValues: PropTypes.object,
};
