import PropTypes from "prop-types";
import Slider from "react-slick";
import SliderIconLeft from "../../../assets/icons/slider-icon-left.svg";
import SliderIconRight from "../../../assets/icons/slider-icon-right.svg";
import { BASE_TRADING_URL } from "../../../constants/trading";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { useIsMobile } from "../../../hooks/useIsMobile";
import LMPAppBanner1 from "./images/LMPAppBanner1.png";
import LMPAppBanner2 from "./images/LMPAppBanner2.png";
import "./styles.css";

const baseClass = getBEMClasses("co-own");

export const CoOwnSection = () => {
  const isMobile = useIsMobile();
  const coOwnSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const CO_OWN_IMAGES = isMobile
    ? [
        {
          img: LMPAppBanner1,
          link: `${BASE_TRADING_URL}/co-own/logan-paul`,
        },
        { img: LMPAppBanner2 },
      ]
    : [{ img: LMPAppBanner1 }, { img: LMPAppBanner2 }];
  return (
    <div className={baseClass("wrapper")}>
      <Slider {...coOwnSliderSettings}>
        {CO_OWN_IMAGES.map((item, index) => (
          <div key={"co-own" + index} className={baseClass("img-wrapper")}>
            <img className={baseClass("img")} src={item.img} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconRight}
    />
  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconLeft}
    />
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
