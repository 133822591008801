import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import "./styles.css";

const baseClass = getBEMClasses("consign-with-us-steps");

export const Steps = (props) => {
  const { step, onPreviousClick } = props;
  return (
    <div className={baseClass("")}>
      <div className={baseClass("step")}>
        <div
          className={baseClass("step-circle", step >= 1 && "active")}
          onClick={step > 1 ? onPreviousClick : () => {}}
        >
          {step > 1 ? "Completed" : "Step 1"}
        </div>
        <div className={baseClass("step-description")}>Personal Details</div>
      </div>
      <img src={ArrowRight} alt="next" className={baseClass("step-arrow")} />
      <div className={baseClass("step")}>
        <div
          className={baseClass("step-circle", step >= 2 && "active")}
          onClick={step > 2 ? onPreviousClick : () => {}}
        >
          {step > 2 ? "Completed" : "Step 2"}
        </div>
        <div className={baseClass("step-description")}>Consignment Details</div>
      </div>
      <img src={ArrowRight} alt="next" className={baseClass("step-arrow")} />
      <div className={baseClass("step")}>
        <div
          className={baseClass("step-circle", step >= 3 && "active")}
          onClick={step > 3 ? onPreviousClick : () => {}}
        >
          {step > 3 ? "Completed" : "Step 3"}
        </div>
        <div className={baseClass("step-description")}>Payment Details</div>
      </div>
      <img src={ArrowRight} alt="next" className={baseClass("step-arrow")} />
      <div className={baseClass("step")}>
        <div className={baseClass("step-circle", step >= 4 && "active")}>
          Step 4
        </div>
        <div className={baseClass("step-description")}>Review & Shipment</div>
      </div>
    </div>
  );
};

Steps.propTypes = {
  step: PropTypes.number,
  onPreviousClick: PropTypes.func,
};
