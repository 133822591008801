import { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../App";
import { DarkButton } from "../../../components/common/Buttons";
import { getBEMClasses } from "../../../helpers/bemHelper";
import SliderIconLeft from "../../../assets/icons/slider-icon-left.svg";
import SliderIconRight from "../../../assets/icons/slider-icon-right.svg";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { CollectionCard } from "../../../components/CollectionCard";
import IllustratorVideo from "./videos/illustrator.mp4";
import BoxVideo from "./videos/box.mp4";
import OvechkinVideo from "./videos/ovechkin.mp4";
import LebronVideo from "./videos/lebron.mp4";
import HamiltonVideo from "./videos/hamilton.mp4";
import HerbetVideo from "./videos/herbert.mp4";
import "./styles.css";

const baseClass = getBEMClasses("collections");

const AVAILABLE_ASSETS = [
  { id: 7, video: IllustratorVideo },
  { id: 17, video: LebronVideo },
  { id: 28, video: OvechkinVideo },
  { id: 13, video: BoxVideo },
  { id: 25, video: HamiltonVideo },
  { id: 27, video: HerbetVideo },
];

export const CollectionsSection = () => {
  const navigate = useNavigate();
  const contextValue = useContext(AppContext);
  const items = contextValue?.drops;
  const dreamTeamSliderSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={baseClass("wrapper")}>
      <div className={baseClass("title")}>Elevate Your Collection</div>
      {items && items.length ? (
        <>
          <Slider {...dreamTeamSliderSettings}>
            {AVAILABLE_ASSETS.map((item) => {
              const asset = items.find((asset) => asset.id === item.id) || {};
              return <CollectionCard key={item.id} {...item} {...asset} />;
            })}
          </Slider>
          <div className={baseClass("explore-wrapper")}>
            <DarkButton
              title={"EXPLORE"}
              width={220}
              onClick={() => navigate("drops")}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconRight}
    />
  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconLeft}
    />
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
