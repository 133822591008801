import { DarkButton, GradientButton } from "../../components/common/Buttons";
import { useMedia } from "react-use";
import { getBEMClasses } from "../../helpers/bemHelper";
import MacImage from "./images/mac.png";
import CircleImage from "../../assets/icons/circle.svg";
import ItemsImage from "./images/items.png";
import CoOwnsImage from "./images/co-owns.png";
import FriendsImage from "./images/friends.png";
import FundsImage from "./images/funds.png";
import CollectTogether from "./images/collect-together.png";
import "./styles.css";
import { useIsMobile } from "../../hooks/useIsMobile";
import { openLinkInNewTab } from "../../helpers/linksHelper";

const baseClass = getBEMClasses("platform");

const PlatformPage = () => {
  const isSmallLayout = useMedia("(max-width: 900px)");
  const isMobile = useIsMobile();
  return (
    <div className={baseClass("")}>
      <div className={baseClass("join-us-wrapper")}>
        <img src={MacImage} className={baseClass("mac-image")} />
        <div className={baseClass("join-us-title")}>
          JOIN NOW TO OWN ANYTHING
        </div>
        {isMobile && (
          <div className={baseClass("join-us-text")}>
            We offer collectors the opportunity to co-own exclusive collectibles
            through the power of tokenization.
          </div>
        )}
        <div className={baseClass("auth-buttons")}>
          <DarkButton
            width={160}
            title={"LOG IN"}
            onClick={() =>
              openLinkInNewTab("https://trade.liquidmarketplace.io/login")
            }
          />
          <GradientButton
            width={160}
            title={"SIGN UP"}
            onClick={() =>
              openLinkInNewTab("https://trade.liquidmarketplace.io/signup")
            }
          />
        </div>
      </div>
      <div className={baseClass("contacts-wrapper")}>
        <div className={baseClass("contacts-left-part")}>
          <div className={baseClass("contacts-row")}>
            <img src={CircleImage} className={baseClass("contacts-circle")} />
            <div className={baseClass("contacts-text")}>
              Co-Own Exclusive Assets
            </div>
          </div>
          <div className={baseClass("contacts-row")}>
            <img src={CircleImage} className={baseClass("contacts-circle")} />
            <div className={baseClass("contacts-text")}>24/7 Trading</div>
          </div>
          <div className={baseClass("contacts-row")}>
            <img src={CircleImage} className={baseClass("contacts-circle")} />
            <div className={baseClass("contacts-text")}>LMP XP System</div>
          </div>
        </div>
        <div className={baseClass("contacts-right-part")}>
          <div className={baseClass("contacts-row")}>
            <img src={CircleImage} className={baseClass("contacts-circle")} />
            <div className={baseClass("contacts-text")}>
              Easy Funding via Crypto and Fiat
            </div>
          </div>
          <div className={baseClass("contacts-row")}>
            <img src={CircleImage} className={baseClass("contacts-circle")} />
            <div className={baseClass("contacts-text")}>
              Collect With Friends
            </div>
          </div>
          <div className={baseClass("contacts-row")}>
            <img src={CircleImage} className={baseClass("contacts-circle")} />
            <div className={baseClass("contacts-text")}>
              Explore a Curated Collection
            </div>
          </div>
        </div>
      </div>
      <div className={baseClass("information-section-wrapper")}>
        <img className={baseClass("information-img")} src={ItemsImage} />
        <div className={baseClass("information-section-text-wrapper")}>
          <div className={baseClass("information-section-title")}>
            co-own exclusive items from curated collections
          </div>
          <div className={baseClass("information-section-text")}>
            At Liquid MarketPlace, we list only the most sought-after assets. We
            carefully select which assets to list to ensure we are providing
            collectors with exclusive and diverse options.
          </div>
        </div>
      </div>
      <div className={baseClass("information-section-wrapper")}>
        {isSmallLayout && (
          <img className={baseClass("information-img")} src={CoOwnsImage} />
        )}
        <div className={baseClass("information-section-text-wrapper")}>
          <div className={baseClass("information-section-title")}>
            Co-own with influencers and celebrities globally
          </div>
          <div className={baseClass("information-section-text")}>
            We allow users to co-own desirable assets alongside established
            collectors and public figures. Liquid MarketPlace users can co-own
            and collect with the very best!
          </div>
        </div>
        {!isSmallLayout && (
          <img className={baseClass("information-img")} src={CoOwnsImage} />
        )}
      </div>
      <div className={baseClass("information-section-wrapper")}>
        <img className={baseClass("information-img")} src={FriendsImage} />
        <div className={baseClass("information-section-text-wrapper")}>
          <div className={baseClass("information-section-title")}>
            co-own with friends
          </div>
          <div className={baseClass("information-section-text")}>
            We firmly believe that collectibles are meant to be shared and
            appreciated. When you join Liquid MarketPlace, you are joining a
            global community of inspired collectors.
          </div>
        </div>
      </div>
      <div className={baseClass("information-section-wrapper")}>
        {isSmallLayout && (
          <img className={baseClass("information-img")} src={FundsImage} />
        )}
        <div className={baseClass("information-section-text-wrapper")}>
          <div className={baseClass("information-section-title")}>
            Easy Funding
          </div>
          <div className={baseClass("information-section-text")}>
            Online transactions have never been easier! We accept both
            cryptocurrency and fiat currencies to fund your account. Quick
            funding ensures you’ll never miss out on a rare asset!
          </div>
        </div>
        {!isSmallLayout && (
          <img className={baseClass("information-img")} src={FundsImage} />
        )}
      </div>
      <div className={baseClass("collect-together")}>
        <div className={baseClass("collect-together-title")}>
          Let’s collect together. create an account today!
        </div>
        <div className={baseClass("collect-together-button")}>
          <GradientButton
            width={220}
            title={"START NOW"}
            onClick={() =>
              openLinkInNewTab("https://trade.liquidmarketplace.io/signup")
            }
          />
        </div>
        <img src={CollectTogether} className={baseClass("collect-img")} />
      </div>
    </div>
  );
};

export { PlatformPage };
