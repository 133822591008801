import { getBEMClasses } from "../../../../helpers/bemHelper";
import { useMedia } from "react-use";
import Image1 from "./images/image1.png";
import Image2 from "./images/image2.png";
import { ShareButton } from "../../../../components/common/Buttons";
import "../../styles.css";

const baseClass = getBEMClasses("blog");

export const SteveAoki = () => {
  const isMobileImage = useMedia("(max-width: 450px)");
  return (
    <>
      <div className={baseClass("title")}>Let’s Talk: Steve Aoki</div>
      <div className={baseClass("date")}>
        Posted on July 6 2022, by Liquid MarketPlace
      </div>
      <div className={baseClass("text")} style={{ marginTop: 30 }}>
        Learn about Steve Aoki: what he’s done, what he’s doing, and what he’s
        planning to do. Listing two sports cards from his collection on Liquid
        MarketPlace is just one part of Aoki’s immersion into the world of
        collectibles and NFTs.
      </div>
      <img
        src={Image1}
        className={baseClass("full-width-img")}
        style={{ marginTop: 60, marginBottom: 60 }}
      />
      <div className={baseClass("text")}>
        Miami-born Steve Aoki is one of the most famous DJs in the world, making
        him a household name in the entertainment industry. However, what many
        don’t know is that Aoki is an avid collector, and has been for much of
        his life.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Aoki’s passion for collectibles is shown through his early involvement
        in many NFT projects, by his involvement as an advisor for Liquid
        MarketPlace, and by founding collectible-centric companies like
        A0K1VERSE and Aoki’s Cardhouse.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        {`What drives Aoki's passion for NFTs and assets? In a recent interview for The Verge, Aoki revealed the true reason behind his determination for the world of NFTs to succeed: when it comes to investing, Aoki explains that anything he invests in must have both a sentimental attachment and an experimental value. NFTs have both. He believes that NFTs are a communication tool as well as a great way to identify with society's culture and other people. All of this backs his passion and drive for the world of NFTs, collectibles, and the future of Web3.`}
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        As a shareholder and advisor for Liquid MarketPlace, Aoki has listed two
        sports cards from his collection on the platform for users to co-own,
        welcoming everyone to the world of collectibles and sharing his passion
        with devoted fans and collectors alike.
      </div>
      <img
        src={Image2}
        className={baseClass("center-img")}
        style={{
          marginTop: 40,
          marginBottom: 40,
          width: isMobileImage ? "stretch" : 400,
          height: isMobileImage ? "100%" : 400,
          maxWidth: isMobileImage ? "100%" : 400,
          maxHeight: isMobileImage ? "100%" : 400,
        }}
      />
      <div className={baseClass("sub-title")}>
        PSA 10 GEM MINT 2020 Panini Contenders Optic Justin Herbert Rookie
        Autograph Orange
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        THE PLAYER
      </div>
      <ul className={baseClass("list")}>
        <li className={baseClass("list-row")}>
          6th overall pick in the 2020 NFL Draft
        </li>
        <li className={baseClass("list-row")}>
          First-ever quarterback to throw for 30 touchdowns in each of his first
          two seasons
        </li>
        <li className={baseClass("list-row")}>
          Holds the record for most passing yards thrown in a quarterback’s
          first two seasons
        </li>
      </ul>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        THE CARD
      </div>
      <ul className={baseClass("list")}>
        <li className={baseClass("list-row")}>
          From Panini Contenders Optic — one of the biggest NFL collectible brands
        </li>
        <li className={baseClass("list-row")}>
          Part of the Rookie Ticket series and features an on-card autograph
        </li>
        <li className={baseClass("list-row")}>
          Orange variant, numbered to only 50 copies
        </li>
        <li className={baseClass("list-row")}>
          One of 4 PSA 10 copies making it one of Herbert’s most desirable cards
        </li>
      </ul>
      <div className={baseClass("sub-title")} style={{ marginTop: 40 }}>
        PSA 8 2020 Panini Obsidian Joe Burrow Rookie Jersey Inks - Black Finite
        1/1
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        THE PLAYER
      </div>
      <ul className={baseClass("list")}>
        <li className={baseClass("list-row")}>
          1st overall pick in the 2020 NFL Draft
        </li>
        <li className={baseClass("list-row")}>
          Set records for completion percentage, single-game passing yards, and
          passer rating (a numerical value assigned to quarterbacks based on
          their efficiency and skill)
        </li>
      </ul>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        THE CARD
      </div>
      <ul className={baseClass("list")}>
        <li className={baseClass("list-row")}>
          Features an authentic NFL Shield and Nike Swoosh Patch and a sticker
          auto from Joe Burrow
        </li>
        <li className={baseClass("list-row")}>
          Numbered to just one copy, making it one of Burrow’s most elusive
          cards
        </li>
      </ul>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Now, you might be left with a couple of questions.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Does this passion for NFTs mean that Aoki is going to hang his DJ
        headphones up to collect dust? Not likely. (Tours are lined up; don’t
        worry.)
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Does it mean that fans can look forward to a world where the music
        industry and NFTs collide? Possibly.
      </div>
      <div
        className={baseClass("text")}
        style={{ marginTop: 40, marginBottom: 80 }}
      >
        Does it mean that Aoki will list more assets with Liquid MarketPlace in
        the future? For this question, you’re going to have to stay tuned.
      </div>
      <ShareButton />
    </>
  );
};
