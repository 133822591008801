import { createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { AboutUsPage } from "./pages/AboutUs";
import { DropsPage } from "./pages/Drops";
import { HomePage } from "./pages/Home";
import { TermsOfUsePage } from "./pages/TermsOfUse";
import { FeeSchedulePage } from "./pages/FeeSchedule";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicy";
import { PlatformPage } from "./pages/Platform";
import { MediaInquiriesPage } from "./pages/MediaInquiries";
import { BlogsPage } from "./pages/Blogs";
import { PressPage } from "./pages/Press";
import { BlogPage } from "./pages/Blog";
import { ConsignWithUsPage } from "./pages/ConsignWithUs";
import { useDrops } from "./hooks/useDrops";
import ScrollToTop from "./hooks/scrollToTop";

function App() {
  // called twice in dev mode, but should work correctly on prod
  const drops = useDrops();

  return (
    <AppContext.Provider value={{ drops }}>
      <div className="App">
        <BrowserRouter>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route exact path={"/"} element={<HomePage />} />
            <Route exact path={"about-us"} element={<AboutUsPage />} />
            <Route exact path={"drops"} element={<DropsPage />} />
            <Route exact path={"platform"} element={<PlatformPage />} />
            <Route
              exact
              path={"media-inquiries"}
              element={<MediaInquiriesPage />}
            />
            <Route exact path={"blogs"} element={<BlogsPage />} />
            <Route exact path={"press"} element={<PressPage />} />
            <Route exact path={"blogs/*"} element={<BlogPage />} />
            <Route exact path={"terms-of-use"} element={<TermsOfUsePage />} />
            <Route exact path={"fee-schedule"} element={<FeeSchedulePage />} />
            <Route
              exact
              path={"privacy-policy"}
              element={<PrivacyPolicyPage />}
            />
            <Route
              exact
              path={"consign-with-us"}
              element={<ConsignWithUsPage />}
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </AppContext.Provider>
  );
}

export const AppContext = createContext();

export default App;
