import { getBEMClasses } from "../../../../helpers/bemHelper";
import PropTypes from "prop-types";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/arrow-up.svg";
import { BASE_TRADING_URL } from "../../../../constants/trading";
import "./styles.css";

const baseClass = getBEMClasses("drops-list-item");

export const ListItem = (props) => {
  const { id, name, imageUrl, tradeState } = props;
  const link = `${BASE_TRADING_URL}/asset-details/${id}`;
  return (
    <a
      className={baseClass()}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className={baseClass("image")} src={imageUrl} />
      <div className={baseClass("text-container")}>
        <div className={baseClass("info-container")}>
          <div className={baseClass("state", { offering: !tradeState })}>
            {tradeState ? "Now Trading" : "Offering"}
          </div>
          <div className={baseClass("name")}>{name}</div>
          {tradeState && (
            <div className={baseClass("trade-container")}>
              <ArrowUp
                className={baseClass("icon-growth", {
                  falling: !tradeState.isGrowing,
                })}
              />
              <div className={baseClass("price-container")}>
                <span className={baseClass("price")}>
                  {formatPrice(tradeState.price)}
                </span>
                <span className={baseClass("currency")}>USD</span>
              </div>
            </div>
          )}
        </div>
        <div className={getBEMClasses("buttons")("button", "dark")}>
          {tradeState ? "Trade now" : "Buy now"}
        </div>
      </div>
    </a>
  );
};

const formatPrice = (price) =>
  Number(price).toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

ListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  tradeState: PropTypes.shape({
    price: PropTypes.number.isRequired,
    isGrowing: PropTypes.bool.isRequired,
  }),
};
