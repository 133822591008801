import PropTypes from "prop-types";
import { DarkButton } from "../../../components/common/Buttons";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { useIsTablet } from "../../../hooks/useIsMobile";
import SliderIconLeft from "../../../assets/icons/slider-icon-left.svg";
import SliderIconRight from "../../../assets/icons/slider-icon-right.svg";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { PressCard } from "../../../components/PressCard";
import { PRESS_CARDS } from "../../Press/PressCards/pressCards";
import "./styles.css";

const baseClass = getBEMClasses("press-section");

export const PressSection = () => {
  const navigate = useNavigate();
  const isTablet = useIsTablet();
  const sliderSettings = {
    dots: isTablet ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const formattedCards = isTablet ? PRESS_CARDS : PRESS_CARDS.slice(0, 3);
  return (
    <div className={baseClass("")}>
      <div className={baseClass("title")}>MEDIA</div>
      {isTablet ? (
        <div className={baseClass("slider-wrapper")}>
          <Slider {...sliderSettings}>
            {formattedCards.map((item, index) => (
              <PressCard
                key={index}
                img={item.img}
                text={item.text}
                link={item.link}
                headerImg={item.headerImg}
                headerBackground={item.headerBackground}
                headerImgWidth={item.headerImgWidth}
              />
            ))}
          </Slider>
        </div>
      ) : (
        <div className={baseClass("cards")}>
          {formattedCards.map((item, index) => (
            <PressCard
              key={index}
              img={item.img}
              text={item.text}
              link={item.link}
              headerImg={item.headerImg}
              headerBackground={item.headerBackground}
              headerImgWidth={item.headerImgWidth}
            />
          ))}
        </div>
      )}
      <DarkButton
        title={"LEARN MORE"}
        width={220}
        onClick={() => navigate("press")}
      />
    </div>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconRight}
    />
  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconLeft}
    />
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
