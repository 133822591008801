import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getBEMClasses } from "../../helpers/bemHelper";
import { useIsTablet } from "../../hooks/useIsMobile";
import { PurpleButton } from "../common/Buttons";
import "./styles.css";

const baseClass = getBEMClasses("blog-card");

export const BlogCard = (props) => {
  const { img, title, date, description, link, isUseMaxWidth } = props;
  const isTablet = useIsTablet();
  const navigate = useNavigate();
  const goToBlog = () => {
    navigate(`/blogs/${link}`);
  };

  return isTablet ? (
    <div className={baseClass("card")}>
      <div className={baseClass("card-wrapper")} onClick={goToBlog}>
        <img className={baseClass("card-img")} src={img} />
        <div className={baseClass("card-content")}>
          {title && <div className={baseClass("card-title")}>{title}</div>}
          <div className={baseClass("card-date")}>{date}</div>
          <div className={baseClass("card-description")}>{description}</div>
          <PurpleButton title="READ MORE" width={125} />
        </div>
      </div>
    </div>
  ) : (
    <div className={baseClass("card")} onClick={goToBlog}>
      <img className={baseClass("card-img")} src={img} />
      <div className={baseClass("card-content")}>
        {title && (
          <div
            className={baseClass("card-title")}
            style={{ maxWidth: isUseMaxWidth ? "200px" : "100%" }}
          >
            {title}
          </div>
        )}
        <div className={baseClass("card-date")}>{date}</div>
        <div className={baseClass("card-description")}>{description}</div>
        <PurpleButton title="READ MORE" width={125} />
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  img: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
  isUseMaxWidth: PropTypes.bool,
};
