import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import usePromise from "react-use/lib/usePromise";
import { getDrops } from "../helpers/fetchHelper";

export const useDrops = () => {
  const mounted = usePromise();
  const [drops, setDrops] = useState();
  useEffect(() => {
    (async () => {
      const rawData = await mounted(getDrops());
      const drops =
        rawData &&
        rawData
          .filter(
            (data) =>
              !isEmpty(data.productAttributes) &&
              data.ProductType !== "NationalCurrency"
          )
          .map((data) => {
            const soldOut = data.productAttributes.soldOut;
            const isTrading =
              typeof soldOut === "string"
                ? soldOut.toLowerCase() === "true"
                : soldOut;
            return {
              id: data.ProductId,
              name: data.ProductFullName,
              imageUrl: data.productAttributes.MarketingImage || data.productAttributes.ProductImageURL,
              ...(isTrading && {
                tradeState: {
                  price: data?.level1?.BestBid ?? 0,
                  isGrowing:
                    (data?.level1?.Rolling24HrPxChangePercent ?? 0) >= 0,
                },
              }),
            };
          });
      setDrops(drops);
    })();
  }, []);
  return drops;
};
