import { getBEMClasses } from "../../../../helpers/bemHelper";
import Image1 from "./images/image1.png";
import Image2 from "./images/image2.png";
import Image3 from "./images/image3.png";
import { ShareButton } from "../../../../components/common/Buttons";
import "../../styles.css";

const baseClass = getBEMClasses("blog");

export const LoganPaul = () => {
  return (
    <>
      <div className={baseClass("title")}>Let’s Talk: Logan Paul</div>
      <div className={baseClass("date")}>
        Posted on July 6 2022, by Liquid MarketPlace
      </div>
      <div className={baseClass("text")} style={{ marginTop: 30 }}>
        Logan Paul has made quite the name for himself as a social media
        personality, entrepreneur, athlete, and entertainer. With his
        sensational entrance into the world of collectibles, Logan is once again
        adding to his ever-growing list of accomplishments.
      </div>
      <img
        src={Image1}
        className={baseClass("full-width-img")}
        style={{ marginTop: 60, marginBottom: 60 }}
      />
      <img
        src={Image2}
        className={baseClass("center-img")}
        style={{
          maxHeight: 440,
          maxWidth: 355,
        }}
      />
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Youtuber. Influencer. Jake Paul’s brother.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        These are some of the things that come to mind when you hear the name
        “Logan Paul.” However, it may be time to add another word to that list:
        Collector. Logan Paul’s collection of cards, NFTs, and other assets has
        left a major impact in the world of collectibles — especially when it
        comes to Pokémon cards and NFTs. Logan has also become professionally
        involved in this world by announcing himself as one of the co-founders
        of Liquid MarketPlace.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        In Logan’s own words, he is “turning [his] passion into a business,” and
        that nothing makes him happier.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Logan Paul is not shy when it comes to announcing his love for
        collecting. In April 2022, he shook the entertainment world when he
        walked on stage at WrestleMania with his diamond-encrusted PSA 10
        Pikachu Illustrator card hung around his neck from a gold chain. Logan
        put on an impressive show that night, and went home with the biggest
        victory of all: his first Guinness World Record title, ‘The most
        expensive Pokémon card sold in a private auction.’
      </div>
      <img
        src={Image3}
        className={baseClass("center-img")}
        style={{
          marginTop: 40,
          maxHeight: 440,
          maxWidth: 353,
        }}
      />
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Paul’s fascination for Pokémon and collectibles stretches back to well
        before his wrestling days. In September 2021, Logan announced to his
        followers that he had purchased a 1st Edition Base Set Pokémon Booster
        Box. Released in 1999 by Wizards of the Coast, the Pokémon 1st Edition
        Base Set was an overnight success, with millions of people having an
        immediate interest in the trading card game. What interested people
        about the booster boxes was the risk: until you open the packs, you’ll
        never know if you’ll find the elusive Charizard or be stuck with a
        plain-old Poliwrath.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        The Pokémon market has continued to grow at such a rapid pace for one
        key reason: for many collectors, it is more than just a game. Each card
        recalls a memory, representing a special place in time for the collector
        who owns it. After Paul’s purchase of the First Edition Box, he
        proceeded to open it on livestream and auction off each pack, generating
        $396,000 USD in revenue.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        However, that wasn’t the only box Logan purchased: the Poké-preneur
        eventually gathered 6 additional boxes, one of which is currently listed
        on Liquid MarketPlace. The ‘1999 1st Edition Pokémon Base Set Booster
        Box’ listed on Liquid MarketPlace shows Logan’s passion for not just the
        world of collectibles and assets, but for Liquid MarketPlace as a
        company. He wants to give his fans and members of the community the
        opportunity to own a piece of history.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        With Logan suiting up to fill the position as co-founder of a budding
        tech company, it means that his brother Jake might have to follow with
        something even more impressive — like possibly running for President? Oh,
        wait: Logan is doing that, too.
      </div>
      <div
        className={baseClass("text")}
        style={{ marginTop: 40, marginBottom: 80 }}
      >
        So the next time you think about Logan Paul, “Potential Future
        President” and “Liquid MarketPlace Co-Owner” should come to mind.
      </div>
      <ShareButton />
    </>
  );
};
