import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getBEMClasses } from "../../helpers/bemHelper";
import Logo from "../../assets/logo.png";
import MobileLogo from "../../assets/mobile-logo.png";
import { DarkButton, GradientButton } from "../common/Buttons";
import { useIsTablet } from "../../hooks/useIsMobile";
import HamburgerIcon from "../../assets/icons/hamburger.svg";
import CloseIcon from "../../assets/icons/close.svg";
import { openLinkInNewTab } from "../../helpers/linksHelper";
import "./styles.css";

const baseClass = getBEMClasses("header");

const Header = () => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const { pathname } = useLocation();
  const isTablet = useIsTablet();
  const navigate = useNavigate();

  const onTabletLinksClick = () => {
    if (isTablet) {
      setIsMenuExpanded(false);
    }
  };

  const renderLinks = () => {
    return (
      <div className={baseClass("links-wrapper")}>
        <Link
          to="/"
          className={baseClass("link", pathname === "/" && "active")}
          onClick={() => {
            onTabletLinksClick();
          }}
        >
          Home
        </Link>
        <Link
          to="about-us"
          className={baseClass("link", pathname === "/about-us" && "active")}
          onClick={() => {
            onTabletLinksClick();
          }}
        >
          About Us
        </Link>
        <Link
          to="drops"
          className={baseClass("link", pathname === "/drops" && "active")}
          onClick={() => {
            onTabletLinksClick();
          }}
        >
          Drops
        </Link>
        <Link
          to="platform"
          className={baseClass("link", pathname === "/platform" && "active")}
          onClick={() => {
            onTabletLinksClick();
          }}
        >
          Platform
        </Link>
        <Link
          to="consign-with-us"
          className={baseClass(
            "link",
            pathname === "/consign-with-us" && "active"
          )}
          onClick={() => {
            onTabletLinksClick();
          }}
        >
          Consign With Us
        </Link>
        <div className={baseClass("auth-wrapper")}>
          <DarkButton
            width={isTablet ? 200 : 120}
            title={"LOG IN"}
            onClick={() =>
              openLinkInNewTab("https://trade.liquidmarketplace.io/login")
            }
          />
          <GradientButton
            width={isTablet ? 200 : 120}
            title={"SIGN UP"}
            onClick={() =>
              openLinkInNewTab("https://trade.liquidmarketplace.io/signup")
            }
          />
        </div>
      </div>
    );
  };

  return isTablet ? (
    <>
      <div className={baseClass("mobile-wrapper")}>
        <div className={baseClass("mobile-left")}>
          <img
            src={isMenuExpanded ? CloseIcon : HamburgerIcon}
            className={baseClass("menu-icon")}
            onClick={() => setIsMenuExpanded(!isMenuExpanded)}
          />
        </div>
        <img src={MobileLogo} className={baseClass("logo")} />
        <div className={baseClass("mobile-right-stub")}></div>
      </div>
      {isMenuExpanded && renderLinks()}
    </>
  ) : (
    <div className={baseClass("wrapper")}>
      <img
        src={Logo}
        className={baseClass("logo")}
        onClick={() => navigate("/")}
      />
      {renderLinks()}
    </div>
  );
};

export { Header };
