import PropTypes from "prop-types";
import { useCallback, useMemo, useReducer } from "react";

import { DarkButton } from "../../../components/common/Buttons";
import {
  LabeledInput,
  LabeledTextArea,
} from "../../../components/common/Inputs";
import { useIsMobile } from "../../../hooks/useIsMobile";

import "./styles.css";

const SET_FIRST_NAME = "SET_FIRST_NAME";
const SET_LAST_NAME = "SET_SECOND_NAME";
const SET_EMAIL = "SET_EMAIL";
const SET_COMPANY = "SET_COMPANY";
const SET_MESSAGE = "SET_MESSAGE";

export const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const submitFormReducer = (state, [action, data]) => {
  switch (action) {
    case SET_FIRST_NAME:
      return { ...state, firstName: data };
    case SET_LAST_NAME:
      return { ...state, lastName: data };
    case SET_EMAIL:
      return emailRegexp.test(data)
        ? { ...state, email: { data, valid: true, isTouched: true } }
        : {
            ...state,
            email: { data: state.email.data, valid: false, isTouched: true },
          };
    case SET_COMPANY:
      return { ...state, company: data };
    case SET_MESSAGE:
      return { ...state, message: data };
    default:
      return state;
  }
};

const initialState = {
  firstName: "",
  lastName: "",
  email: {
    isTouched: false,
    valid: false,
    data: "",
  },
  company: "",
  message: "",
};

export const SubmitForm = ({ onSubmit, error }) => {
  const [state, dispatch] = useReducer(submitFormReducer, initialState);
  const isMobile = useIsMobile();

  const setField =
    (action) =>
    ({ target: { value } }) => {
      dispatch([action, value]);
    };

  const isError = useMemo(
    () => !state.email.valid && state.email.isTouched,
    [state]
  );

  const submitted = useCallback(() => {
    const { email, ...restState } = state;
    if (email.valid) {
      onSubmit({ ...restState, email: email.data });
    } else {
      dispatch([SET_EMAIL, ""]);
    }
  }, [onSubmit, state]);

  return (
    <div className="form-container">
      <div className="form-container__title">Thanks for your interest!</div>
      <form className="form-container__form">
        {isMobile ? (
          <>
            <LabeledInput
              onChange={setField(SET_FIRST_NAME)}
              id="firstName"
              label="first name"
            />
            <LabeledInput
              onChange={setField(SET_LAST_NAME)}
              id="lastName"
              label="last name"
            />
          </>
        ) : (
          <div className="form-container__form__name">
            <LabeledInput
              onChange={setField(SET_FIRST_NAME)}
              style={{ width: "210px" }}
              id="firstName"
              label="first name"
            />
            <LabeledInput
              onChange={setField(SET_LAST_NAME)}
              style={{ width: "210px" }}
              id="lastName"
              label="last name"
            />
          </div>
        )}
        <LabeledInput
          onChange={setField(SET_EMAIL)}
          error={isError ? "You should enter valid email" : ""}
          id="email"
          label="email"
          type="email"
        />
        <LabeledInput
          onChange={setField(SET_COMPANY)}
          id="company"
          label="company"
        />
        <LabeledTextArea
          onChange={setField(SET_MESSAGE)}
          id="message"
          label="message"
          style={{ height: "160px" }}
        />
        <DarkButton
          title="SUBMIT"
          onClick={submitted}
          style={{ marginTop: "10px", alignSelf: "center" }}
        />
      </form>
      {error && <span className="form-container__error">{error}</span>}
    </div>
  );
};

SubmitForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
};
