import { getBEMClasses } from "../../../../helpers/bemHelper";
import { useMedia } from "react-use";
import Image1 from "./images/image1.png";
import Image2 from "./images/image2.png";
import Image3 from "./images/image3.png";
import { ShareButton } from "../../../../components/common/Buttons";
import "../../styles.css";

const baseClass = getBEMClasses("blog");

export const DarrenJack = () => {
  const isMobileImage = useMedia("(max-width: 760px)");
  return (
    <>
      <div className={baseClass("title")}>Let’s Talk: Darren Jack</div>
      <div className={baseClass("date")}>
        Posted on July 6 2022, by Liquid MarketPlace
      </div>
      <div className={baseClass("text")} style={{ marginTop: 30 }}>
        {`Darren Jack is one of the world of motorsports' most senior collectors, who has commanded the respect of peers and fans alike. By joining the Liquid MarketPlace team, Jack is using this experience and these connections to provide previously unattainable co-ownership opportunities.`}
      </div>
      <img
        src={Image1}
        className={baseClass("full-width-img")}
        style={{ marginTop: 60, marginBottom: 60 }}
      />
      <div className={baseClass("text")}>
        It’s a common saying that when you do what you love, you never have to
        work a day in your life. Darren Jack lives by this mantra.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        At the age of four, Jack attended his first F1 race, the San Marino
        Grand Prix. This began his passion for the world of racing, leaving a
        lasting impression that would come to shape his future and career. Since
        that first fateful race, Jack has attended over one thousand racing
        events, becoming extremely knowledgeable about the sport along the way.
      </div>
      <img
        src={Image2}
        className={baseClass("center-img")}
        style={{
          maxHeight: 301,
          maxWidth: 289,
          marginTop: 40,
          height: 301,
        }}
      />
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        {`As the founder of the Hall of Fame Collection, Jack has built the world's largest collection of motorsports memorabilia. Motorsports’ collectors all know about the Hall of Fame Collection thanks to the man behind the collectibles: Jack’s passion for the world of collecting has inspired many. His knowledge and charisma are what drove Jack to become a Brand Ambassador for Liquid MarketPlace. In this position, he helps acquire the most elusive, sought-after motorsports collectibles and assets in the world for users to co-own.`}
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Recently, Jack listed an incredible piece on Liquid MarketPlace: his{" "}
        <span className={baseClass("text-bold")}>
          2021 Topps Chrome Lewis Hamilton Red Liners Superfractor PSA 10
        </span>
        . This card, to collectors everywhere, is a big deal, as Lewis Hamilton
        continues to prove that he is the greatest driver to ever live.
      </div>
      <img
        src={Image3}
        className={baseClass("center-img")}
        style={{
          marginTop: 40,
          maxHeight: 473,
          maxWidth: isMobileImage ? "100%" : 713,
          width: isMobileImage ? "100%" : 713,
        }}
      />
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        {`Lewis has collected a total of seven Formula 1 World Championships, which means he is tied for most championships ever won. He also has the most Grand Prix and podium positions of any driver in history. Knowing the significance of this card to the racing world makes co-owning it a dream come true for many people. The fact that it's a PSA 10, and a one-of-one, is pretty great too.`}
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Since the Hall of Fame Collection has thousands of pieces of memorabilia
        that Jack has collected over his many years of work, ranging from
        trading cards to helmets, suits, and even champagne bottles, users can
        look forward to Jack listing more assets from his collection to co-own
        on the platform.
      </div>
      <div
        className={baseClass("text")}
        style={{ marginTop: 40, marginBottom: 80 }}
      >
        Motorsport enthusiasts, start your engines: Darren Jack will continue to
        source and provide the most exclusive and desirable racing cards and
        memorabilia for you to co-own on Liquid MarketPlace.
      </div>
      <ShareButton />
    </>
  );
};
