import { getBEMClasses } from "../../../../helpers/bemHelper";
import { useIsTablet } from "../../../../hooks/useIsMobile";
import Image1 from "./images/image1.png";
import Image2 from "./images/image2.png";
import Image3 from "./images/image3.png";
import Image4 from "./images/image4.png";
import { ShareButton } from "../../../../components/common/Buttons";
import "../../styles.css";

const baseClass = getBEMClasses("blog");

export const ExpensiveCollectibles = () => {
  const isTablet = useIsTablet();
  return (
    <>
      <div className={baseClass("title")}>
        The Expensive Side of Collectibles
      </div>
      <div className={baseClass("date")}>
        Posted on July 6 2022, by Liquid MarketPlace
      </div>
      <div className={baseClass("text")} style={{ marginTop: 30 }}>
        The desire to collect goes back to the beginning of the human race: what
        used to be a survival method has now become a hobby, or a way to show
        off your wealth.
      </div>
      <img
        src={Image1}
        className={baseClass("full-width-img")}
        style={{ marginTop: 60, marginBottom: 60 }}
      />
      <div className={baseClass("text")}>
        Coins, monkey pictures, paintings, and Pokémon cards. What do these
        things have in common? They are all collectibles.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        People collect thousands of different things, ranging in price from a
        couple dollars to even hundreds of millions.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        You may be wondering, how expensive can a collectible really get? Let’s
        look at a few high-priced collectibles that have set record sales.
      </div>
      <div className={baseClass("text-bold")} style={{ marginTop: 40 }}>
        Most Expensive Coin
      </div>
      <img
        src={Image2}
        className={baseClass("center-img")}
        style={{
          marginTop: 40,
          height: isTablet ? 215 : 416,
          maxHeight: isTablet ? 215 : 416,
          maxWidth: isTablet ? "100%" : 767,
          width: isTablet ? "stretch" : 767,
        }}
      />
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        {`Pressed in 1933 and made of solid gold, the most expensive coin ever bought at auction was a $20 face value, unreleased, double eagle coin, which sold for $18.9 million. The reason this coin never saw the inside of any American wallet was because President Roosevelt banned all Americans from owning gold in an attempt to end the nation's banking crisis. However, twenty coins managed to survive and now are heavily desired in auctions since they are super rare collectibles.`}
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        The person who sold his one-of-twenty coin for that hefty price tag was
        shoe designer Stuart Weitzman, who donated all of the proceeds to
        charity.
      </div>
      <img
        src={Image3}
        className={baseClass("center-img")}
        style={{
          marginTop: 40,
          height: isTablet ? 245 : 480,
          maxHeight: isTablet ? 245 : 480,
          maxWidth: isTablet ? "100%" : 540,
          width: isTablet ? "stretch" : 540,
        }}
      />
      <div className={baseClass("text-bold")} style={{ marginTop: 40 }}>
        Most Expensive Pokémon Card
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        {`In 2021, this highly elusive collectible was bought by Liquid MarketPlace’s own Logan Paul. Paul purchased the coveted PSA 10 Pikachu Illustrator in a private sale, traveling across the globe from the United States, to Italy, and then to Dubai. He traded a PSA Grade 9 Pikachu Illustrator (worth $1.275 million) along with $4 million in cash to get his hands on the phenomenal card, bringing the total price up to $5.275 million. The story of the Pikachu Illustrator stretches back to 1998 in Japan, where three art contests awarded 39 copies to lucky winners. The art on this card was done by Atsuko Nishida and features Kanji writing that says: “We certify that your illustration is an excellent entry in the Pokémon Card Game Illustration Contest. Therefore, we state that you are an Officially Authorized Pokémon Card Illustrator and admire your skill."`}
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        This specific copy of the Illustrator is the only copy to have been
        given a PSA 10 grade, certifying its exclusivity as one of the greatest
        Pokémon cards to ever exist.
      </div>
      <img
        src={Image4}
        className={baseClass("center-img")}
        style={{
          marginTop: 40,
          height: isTablet ? 237.5 : 480,
          maxHeight: isTablet ? 237.5 : 480,
          maxWidth: isTablet ? "100%" : 800,
          width: isTablet ? "100%" : 800,
        }}
      />
      <div className={baseClass("text-bold")} style={{ marginTop: 40 }}>
        Most Expensive Painting
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        To date, the most expensive painting ever sold is the Salvator Mundi,
        painted by none other than Leonardo DaVinci.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Dating back to the 1500s, the painting features Jesus Christ holding a
        crystal orb, representing the “crystalline sphere” of the heavens. The
        painting sold at Christie’s New York Auction House for a whopping $450.3
        million!
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Who could possibly afford this?
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        The Crown Prince of Saudi Arabia — that’s who. Mohammed bin Salman
        purchased this painting in 2017 on behalf of the Abu Dhabi Department of
        Culture and Tourism.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        If you wish to see this painting in person, unfortunately, the odds of
        that are pretty unlikely. Originally, the painting was intended to be on
        public display at the Louvre Abu Dabhi, but rumour has it that Salman is
        holding onto it on his superyacht.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Liquid MarketPlace has been trying and trying to get in contact with the
        prince so we can list the painting on our platform, but we can’t seem to
        get ahold of him.
      </div>
      <div
        className={baseClass("text")}
        style={{ marginTop: 40, marginBottom: 80 }}
      >
        {`Maybe he doesn't have cell reception on his yacht…`}
      </div>
      <ShareButton />
    </>
  );
};
