import React from 'react'
import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { PAYOUT_METHODS } from "../Step3";
import "./styles.css";

const baseClass = getBEMClasses("result-table");

export const ResultTable = React.forwardRef((props, ref) => {
  const { values } = props;
  return (
    <div ref={ref} className={baseClass("")}>
      <div className={baseClass("row")}>
        <div className={baseClass("row-name")}>Full Name:</div>
        <div className={baseClass("row-value")}>{values.fullName}</div>
      </div>
      <div className={baseClass("row")}>
        <div className={baseClass("row-name")}>Email:</div>
        <div className={baseClass("row-value")}>{values.email}</div>
      </div>
      <div className={baseClass("row")}>
        <div className={baseClass("row-name")}>Phone Number:</div>
        <div className={baseClass("row-value")}>{values.phone}</div>
      </div>
      <div className={baseClass("row")}>
        <div className={baseClass("row-name")}>Address:</div>
        <div className={baseClass("row-value")}>{values.address}</div>
      </div>
      <div className={baseClass("row")}>
        <div className={baseClass("row-name")}>Country:</div>
        <div className={baseClass("row-value")}>{values.country}</div>
      </div>
      <div className={baseClass("row")}>
        <div className={baseClass("row-name")}>Province/State:</div>
        <div className={baseClass("row-value")}>{values.provinceState}</div>
      </div>
      <div className={baseClass("row")}>
        <div className={baseClass("row-name")}>City:</div>
        <div className={baseClass("row-value")}>{values.city}</div>
      </div>
      <div className={baseClass("row")}>
        <div className={baseClass("row-name")}>Postal/ZIP Code:</div>
        <div className={baseClass("row-value")}>{values.postalZipCode}</div>
      </div>
      {values.cards.map((item, index) => (
        <div key={`card${index}`} className={baseClass("extra-row")}>
          <div className={baseClass("row")}>
            <div className={baseClass("row-name")}>Card Type:</div>
            <div className={baseClass("row-value")}>{item.cardType}</div>
          </div>
          <div className={baseClass("row")}>
            <div className={baseClass("row-name")}>Qnty:</div>
            <div className={baseClass("row-value")}>{item.qnty}</div>
          </div>
        </div>
      ))}
      <div className={baseClass("row")}>
        <div className={baseClass("row-name")}>
          Preferred Auction Payment Methods:
        </div>
        <div className={baseClass("row-value")}>
          {values.auctionPayoutMethod}
        </div>
      </div>
      {values.auctionPayoutMethod === PAYOUT_METHODS.PAYPAL.value && (
        <>
          <div className={baseClass("row")}>
            <div className={baseClass("row-name")}>Paypal email:</div>
            <div className={baseClass("row-value")}>{values.paypalEmail}</div>
          </div>
        </>
      )}
      {values.auctionPayoutMethod === PAYOUT_METHODS.WIRE_TRANSFER.value && (
        <>
          <div className={baseClass("row")}>
            <div className={baseClass("row-name")}>Bank Account Number:</div>
            <div className={baseClass("row-value")}>
              {values.bankAccountNumber}
            </div>
          </div>
          <div className={baseClass("row")}>
            <div className={baseClass("row-name")}>Swift Code:</div>
            <div className={baseClass("row-value")}>{values.swiftCode}</div>
          </div>
          <div className={baseClass("row")}>
            <div className={baseClass("row-name")}>Address:</div>
            <div className={baseClass("row-value")}>{values.bankAddress}</div>
          </div>
        </>
      )}
      {values.auctionPayoutMethod ===
        PAYOUT_METHODS.INTERACT_E_TRANSFER.value && (
        <>
          <div className={baseClass("row")}>
            <div className={baseClass("row-name")}>
              Interac E-Transfer Email:
            </div>
            <div className={baseClass("row-value")}>{values.interactEmail}</div>
          </div>
        </>
      )}
    </div>
  );
});

ResultTable.displayName = 'ResultTable';

ResultTable.propTypes = {
  values: PropTypes.object,
};
