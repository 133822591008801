import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/bemHelper';
import { ReactComponent as LeftIcon } from '../../../assets/icons/slider-icon-left.svg';
import { ReactComponent as LeftDoubleIcon } from '../../../assets/icons/slider-icon-left-double.svg';
import { ReactComponent as RightIcon } from '../../../assets/icons/slider-icon-right.svg';
import { ReactComponent as RightDoubleIcon } from '../../../assets/icons/slider-icon-right-double.svg';
import './Pagination.css';

export const PaginationComponent = (props) => {
  const {
    content,
    activePage = 0,
    setActivePage,
    customClass = '',
    totalPages,
    maxPages,
    showOnlyActivePage,
    containerClassName = ''
  } = props;
  const currentContent = useMemo(
    () => {
      return content(activePage);
    },
    [content, activePage]
  );
  const length = maxPages > totalPages ? totalPages : maxPages;

  useEffect(
    () => {
      if (activePage > 0 && currentContent.length === 0) {
        setActivePage(activePage - 1);
      }
    },
    [activePage, currentContent.length]
  );

  const getClasses = () => {
    return getBEMClasses(['pagination', `${customClass}-pagination`]);
  };

  const paginationClasses = useMemo(() => getClasses(), [customClass]);

  const goToPage = (event, page) => {
    event.preventDefault();
    setActivePage && setActivePage(page);
  };

  const renderNextButtons = page => {
    const disabled = totalPages === 0 || page === totalPages - 1;
    return (
      <span className={paginationClasses('next')}>
        <a
          className={paginationClasses('icon', { next: true, disabled })}
          onClick={e => !disabled && goToPage(e, page + 1)}>
          <RightIcon />
        </a>
        <a
          className={paginationClasses('icon', { last: true, disabled })}
          onClick={e => !disabled && goToPage(e, totalPages - 1)}>
          <RightDoubleIcon />
        </a>
      </span>
    );
  };

  const renderPreviousButtons = page => {
    const disabled = totalPages === 0 || page === 0;
    return (
      <span className={paginationClasses('previous')}>
        <a
          className={paginationClasses('icon', { first: true, disabled })}
          onClick={e => !disabled && goToPage(e, 0)}>
          <LeftDoubleIcon />
        </a>
        <a
          className={paginationClasses('icon', { prev: true, disabled })}
          onClick={e => !disabled && goToPage(e, page - 1)}>
          <LeftIcon />
        </a>
      </span>
    );
  };

  const pages = generateNumberRangeArray(length, activePage, totalPages).map(
    i => (
      <a
        key={i}
        className={paginationClasses(
          'page',
          activePage === i ? 'active' : null
        )}
        onClick={e => goToPage(e, i)}>
        {i + 1}
      </a>
    )
  );

  return (
    <div className={containerClassName}>
      <div className={paginationClasses('content')}>{currentContent}</div>
      <div className={paginationClasses('container')}>
        {renderPreviousButtons(activePage)}
        {showOnlyActivePage ? (
          <div className={paginationClasses('pages')}>
            <div className={paginationClasses('page')}>{activePage + 1}</div>
          </div>
        ) : (
          <div className={paginationClasses('pages')}>{pages}</div>
        )}
        {renderNextButtons(activePage)}
      </div>
    </div>
  );
};

// Generate an array of integers, centered on MiddleValue, not reaching limit.
const generateNumberRangeArray = (length, middleValue, limit) =>
  Array.from({ length }, (v, i) => {
    const offset = Math.floor(length / 2);

    if (!middleValue || middleValue <= offset) {
      // Ascend starting at 0.
      return i;
    }

    if (limit && middleValue + offset >= limit) {
      // Descend starting at maximum.
      return limit - i - 1;
    }

    return middleValue + i - offset;
  }).sort((a, b) => a - b); // Ensure numerical sorting (rather than default string).

PaginationComponent.propTypes = {
  totalPages: PropTypes.number.isRequired,
  content: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  containerClassName: PropTypes.string,
  customClass: PropTypes.string,
  setActivePage: PropTypes.func,
  maxPages: PropTypes.number,
  showOnlyActivePage: PropTypes.bool
};
