import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { useIsTablet } from "../../../hooks/useIsMobile";
import HeaderImage from "./images/header-background.png";
import "./styles.css";

const baseClass = getBEMClasses("consign-with-us-header");

export const Header = (props) => {
  const { showSubtitle = false } = props;
  const isTablet = useIsTablet();
  const heightWithSubtitle = isTablet ? 240 : 360;
  const heightWithoutSubtitle = isTablet ? 160 : 200;
  return (
    <div
      className={baseClass("wrapper")}
      style={{
        height: showSubtitle ? heightWithSubtitle : heightWithoutSubtitle,
      }}
    >
      <img src={HeaderImage} alt="consign" className={baseClass("img")} />
      <div className={baseClass("text-wrapper")}>
        <div className={baseClass("title")}>
          <p className={baseClass("title-text")}>Consign with us</p>
        </div>
        {showSubtitle && (
          <div className={baseClass("subtitle-wrapper")}>
            Sell Your Collectibles With{" "}
            <span className={baseClass("subtitle-company-text")}>
              Liquid MarketPlace!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  showSubtitle: PropTypes.bool,
};
