import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { Input } from "../../../components/common/Form/Input";
import { Form } from "../../../components/common/Form";
import { SubmitButton } from "../../../components/common/Form/SubmitButton";
import { Select } from "../../../components/common/Form/Select";
import * as Yup from "yup";
import { Formik } from "formik";
import { useIsTablet, useIsMobile } from "../../../hooks/useIsMobile";
import { FieldArray, getIn } from "formik";
import CloseCircle from "../../../assets/icons/circle-close.svg";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const baseClass = getBEMClasses("consign-with-us-second-step");

const ValidationSchema = Yup.object().shape({
  cards: Yup.array()
    .of(
      Yup.object().shape({
        qnty: Yup.number("Quantity must be number")
          .moreThan(0, "Quantity is required")
          .required("Quantity is required"),
        cardType: Yup.string().required("Card type is required"),
      })
    )
    .required("Required"),
});

export const ConsignSecondStep = (props) => {
  const { onNextClick, saveStepValues, initialValues } = props;
  const defaultInitialValues = !isEmpty(initialValues)
    ? initialValues
    : {
        cards: [{ id: Math.random(), cardType: "", qnty: "" }],
      };
  const cardOptions = [
    { label: "Sport Card", value: "SportCard" },
    { label: "TCG Card", value: "TCG Card" },
    { label: "Non-Sport Card", value: "Non-Sport Card" },
    { label: "Sealed Product", value: "Sealed Product" },
  ];
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return (
    <Formik
      onSubmit={(values) => {
        const formattedValues = values.cards.map((value) => {
          // eslint-disable-next-line
          const { id, ...rest } = value;
          return { ...rest };
        });
        saveStepValues({ cards: formattedValues }, 2);
        onNextClick();
      }}
      initialValues={defaultInitialValues}
      validationSchema={ValidationSchema}
    >
      {({ values, errors, touched }) => {
        return (
          <Form style={{ gap: isTablet ? 30 : 40 }}>
            <FieldArray
              name="cards"
              render={(arrayHelpers) => (
                <div className={baseClass("fields")}>
                  {values.cards.map((card, index) => (
                    <div key={card.id} className={baseClass("row")}>
                      <Select
                        name={`cards.${index}.cardType`}
                        label="Card type"
                        options={cardOptions}
                        style={{ width: isMobile ? "100%" : 280 }}
                        width={isMobile ? "100%" : 280}
                        error={
                          getIn(errors, `cards.${index}.cardType`) &&
                          getIn(touched, `cards.${index}.cardType`)
                            ? getIn(errors, `cards.${index}.cardType`)
                            : ""
                        }
                        defaultTitle={"Select card type"}
                        infoText={
                          "Liquid MarketPlace only accepts graded cards. If a consigner sends a non graded card (raw) , it will be shipped back at the expense of the consigner."
                        }
                      />
                      <Input
                        name={`cards.${index}.qnty`}
                        label="QNTY"
                        placeholder={"0"}
                        style={{ width: isMobile ? "100%" : 80 }}
                        width={isMobile ? "100%" : 80}
                        error={
                          getIn(errors, `cards.${index}.qnty`) &&
                          getIn(touched, `cards.${index}.qnty`)
                            ? getIn(errors, `cards.${index}.qnty`)
                            : ""
                        }
                      />
                      {values.cards.length > 1 && (
                        <div
                          className={baseClass("remove-button")}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <img src={CloseCircle} alt="remove" />
                          Remove
                        </div>
                      )}
                    </div>
                  ))}
                  <div
                    className={baseClass("add-card-type")}
                    onClick={() =>
                      arrayHelpers.push({
                        id: Math.random(),
                        cardType: "",
                        qnty: "",
                      })
                    }
                  >
                    +
                    <span className={baseClass("add-card-type-text")}>
                      Add New Card Type
                    </span>
                  </div>
                </div>
              )}
            />
            <SubmitButton
              label="next"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: isMobile ? 40 : 80,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

ConsignSecondStep.propTypes = {
  onNextClick: PropTypes.func,
  saveStepValues: PropTypes.func,
  initialValues: PropTypes.object,
};
