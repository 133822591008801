import { getBEMClasses } from "../../../../helpers/bemHelper";
import { useMedia } from "react-use";
import Image1 from "./images/image1.png";
import Image2 from "./images/image2.png";
import { ShareButton } from "../../../../components/common/Buttons";
import "../../styles.css";

const baseClass = getBEMClasses("blog");

export const TrustAndTransparency = () => {
  const isMobileImage = useMedia("(max-width: 700px)");
  return (
    <>
      <div className={baseClass("title")}>Trust and Transparency</div>
      <div className={baseClass("date")}>
        Posted on July 6 2022, by Liquid MarketPlace
      </div>
      <div
        className={baseClass("text")}
        style={{ textAlign: "center", marginTop: 30 }}
      >
        You buy tokens of an asset.
      </div>
      <div
        className={baseClass("text")}
        style={{ textAlign: "center", marginTop: 40 }}
      >
        We ensure that the asset is safe and sound.
      </div>
      <div
        className={baseClass("text")}
        style={{ textAlign: "center", marginTop: 40 }}
      >
        Let’s unpack how we do that.
      </div>
      <img
        src={Image1}
        className={baseClass("full-width-img")}
        style={{ marginTop: 60, marginBottom: 60 }}
      />
      <div className={baseClass("text")}>
        So, you have ownership in your dream collectibles. But now that leaves a
        couple of burning questions: Where are these collectibles kept? And, how
        can I be sure that they are safe?
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Liquid MarketPlace has got you covered!
      </div>
      <img
        src={Image2}
        className={baseClass("center-img")}
        style={{
          marginTop: 40,
          height: isMobileImage ? 285 : 480,
          maxHeight: isMobileImage ? 285 : 480,
          maxWidth: isMobileImage ? "100%" : 640,
          width: isMobileImage ? "100%" : 640,
        }}
      />
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Picture a secluded island off the coast of Spain, where the only sign of
        civilization is a titanium state-of-the-art vault. The vault is
        surrounded by laser beams that are triggered by the slightest movement
        and deactivated by facial recognition technology. Only then can the
        vault be opened, done by twisting the heavy circular door, to reveal all
        the rare, elusive collectibles that are available on Liquid MarketPlace.
        This would be pretty cool, right? Well, maybe we’ll get there one day,
        but for now, the vault is still secure enough that you can rest assured
        that your collectibles are safe.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        One part of that story is true; the collectibles are kept in
        super-secure state-of-the-art vaults. These vaults meet strict
        requirements and are fully equipped with industry-leading lighting, fire
        suppression and security. We may not have facial recognition, but the
        security of the vaults is strong enough that there’s nothing to worry
        about.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        The vaults themselves are owned and managed by a third-party company
        that is uniquely qualified and experienced in handling rare
        collectibles. The company stores works of art for private collectors and
        museums, proving that they understand the importance of assets and the
        standards at which they need to be kept.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        At Liquid MarketPlace, we receive documents quarterly from this
        third-party ensuring that the collectibles are still in great condition
        and are tucked away safe and sound in the vaults.
      </div>
      <div
        className={baseClass("text")}
        style={{ marginTop: 40, marginBottom: 80 }}
      >
        After learning all of this, we hope that you are confident in the safety
        of the collectibles and your ownership in them. The day will come when
        we can implement laser beams and facial recognition but for now, our
        vaults are more than secure.
      </div>
      <ShareButton />
    </>
  );
};
