import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { DarkInput } from "../common/Inputs";
import { emailRegexp } from "../../pages/MediaInquiries/SubmitForm";
import { subscribeEmail } from "../../helpers/fetchHelper";
import "./styles.css";

const initialState = { email: "", valid: false, touched: false };
const subscribeState = { isReqSent: false, isError: false };

export const EmailSubscribe = ({ onSubscribe }) => {
  const [state, setState] = useState(initialState);
  const [subReqStatus, setSubReqStatus] = useState(subscribeState);

  const error = useMemo(
    () =>
      !state.valid && state.touched
        ? "You should enter correct email!"
        : subReqStatus.isError
          ? "Sorry, we couldn't subscribe you this time try again later"
          : "",
    [state, subReqStatus]
  );

  const emailChanged = ({ target: { value } }) => {
    setState({
      email: value,
      valid: emailRegexp.test(value),
      touched: true,
    });
  };

  const subscribe = () => {
    if (state.valid) {
      if (onSubscribe) onSubscribe();
      subscribeEmail(state.email)
        .then(() => {
          setSubReqStatus({
            isReqSent: true,
            isError: false,
          });
        })
        .catch((err) => {
          console.error(err);
          setSubReqStatus({
            isReqSent: true,
            isError: true,
          });
        });
    } else {
      setState((state) => ({ ...state, touched: true }));
    }
  };

  const isShowSuccessMessage = subReqStatus.isReqSent && !subReqStatus.isError;

  return (
    <div className="email-subscribe">
      {isShowSuccessMessage ? (
        <span className="email-subscribe__success">
          Thank you for subscribing!
        </span>
      ) : (
        <>
          <span className="email-subscribe__title">Stay Connected!</span>
          <span className="email-subscribe__content">
            Sign up to be the first to know about drops, platform updates, and
            more.
          </span>
          <div className="email-subscribe__input">
            <DarkInput
              id="email"
              type="email"
              placeholder="Email address"
              onChange={emailChanged}
              style={{ minWidth: "0px" }}
            />
            <div
              className="email-subscribe__input__button"
              onClick={subscribe}
            >
              Subscribe
            </div>
          </div>
          <span className="email-subscribe__input__error">{error}</span>
        </>
      )}
    </div>
  );
};

EmailSubscribe.propTypes = {
  onClose: PropTypes.func,
  onSubscribe: PropTypes.func,
};
