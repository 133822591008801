import PropTypes from "prop-types";
import { getBEMClasses } from "../../../../helpers/bemHelper";
import "./styles.css";

const baseClass = getBEMClasses("form-submit");

export const SubmitButton = (props) => {
  const { label, ...rest } = props;
  return (
    <button type="submit" className={baseClass("")} {...rest}>
      {label}
    </button>
  );
};

SubmitButton.propTypes = {
  label: PropTypes.string,
};
