import PropTypes from "prop-types";
import { DarkButton } from "../../../components/common/Buttons";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { useIsTablet } from "../../../hooks/useIsMobile";
import SliderIconLeft from "../../../assets/icons/slider-icon-left.svg";
import SliderIconRight from "../../../assets/icons/slider-icon-right.svg";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { BlogCard } from "../../../components/BlogCard";
import { BLOG_CARDS } from "../../Blogs/BlogsCards/blogCards";
import "./styles.css";

const baseClass = getBEMClasses("blogs-section");

export const BlogsSection = () => {
  const isTablet = useIsTablet();
  const navigate = useNavigate();
  const sliderSettings = {
    dots: isTablet ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const formattedCards = BLOG_CARDS.slice(0, 3);
  return (
    <div className={baseClass("")}>
      <div className={baseClass("title")}>BLOG</div>
      {isTablet ? (
        <div className={baseClass("slider-wrapper")}>
          <Slider {...sliderSettings}>
            {formattedCards.map((item, index) => (
              <BlogCard
                key={index}
                img={item.img}
                description={item.description}
                date={item.date}
                link={item.link}
                title={item.title}
              />
            ))}
          </Slider>
        </div>
      ) : (
        <div className={baseClass("cards")}>
          {formattedCards.map((item, index) => (
            <BlogCard
              key={index}
              img={item.img}
              description={item.description}
              date={item.date}
              link={item.link}
              title={item.title}
              isUseMaxWidth
            />
          ))}
        </div>
      )}
      <DarkButton
        title={"LEARN MORE"}
        width={220}
        onClick={() => navigate("blogs")}
      />
    </div>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconRight}
    />
  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconLeft}
    />
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
