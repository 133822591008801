import { Title } from "../../components/common/Title";
import { getBEMClasses } from "../../helpers/bemHelper";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const baseClass = getBEMClasses("terms-of-use");

const TermsOfUsePage = () => {
  const navigate = useNavigate();

  const goToFeeSchedule = () => {
    navigate("/fee-schedule");
  };
  return (
    <div className={baseClass("")}>
      <Title title="TERMS OF USE" />
      <div className={baseClass("text-section-wrapper")}>
        <div className={baseClass("header-text")}>
          THIS IS A BINDING LEGAL AGREEMENT
        </div>
        <div className={baseClass("header-text")}>
          - PLEASE READ IT CAREFULLY BEFORE USING THIS PLATFORM OR OUR SERVICES
        </div>
        <div className={baseClass("date-text")}>
          Last Updated: March 29, 2022
        </div>
        <div className={baseClass("section-header")}>1. Introduction</div>
        <div className={baseClass("section-text")}>
          1.1 Liquid Marketplace Inc. and its wholly-owned
          subsidiaries1(collectively, “Liquid Marketplace”, “we”, “us” and
          “our”) provides a website, mobile application or other digital
          platform (each, a “Platform”) that allows users to purchase, collect,
          showcase and sell fractional ownership interests in rare collectibles
          (“Collectibles”). We are making the Platform available to you subject
          to these Terms of Use (the “Terms”), which incorporate by reference
          our Privacy Policy, the Listing Agreement if you are a Collector (as
          defined below), the Custody Agreement (as defined below) and any other
          terms and conditions incorporated by reference.
        </div>
        <div className={baseClass("section-text")}>
          1.2 The Terms constitute a legal agreement between Liquid Marketplace
          and users of or visitors to the Platform including without limitation,
          owners of Collectibles listed for sale on the Platform (“Collectors”),
          potential or actual purchasers of Collectibles, and visitors exploring
          the Platform (each a “user” or “you”). By accessing the Platform,
          whether via a computer, mobile device or otherwise, you agree that you
          have read, understand and accept these Terms. Please read these Terms
          carefully as they contain very important information regarding your
          rights and obligations, as well as conditions, limitations and
          exclusions that might apply to you. (Not applicable to Quebec
          residents) The Terms also include mandatory arbitration and class
          action waiver provisions in Section 13, to the greatest extent
          permitted by applicable law.
        </div>
        <div className={baseClass("section-text")}>
          1.3 By using the Platform, you affirm that you are at least the age of
          majority in your jurisdiction of residence and have the legal capacity
          to enter into these Terms, and you accept and are bound by these Terms
          and agree that your personal information will be dealt with in
          accordance with our Privacy Policy. You affirm that if you are using
          our Platform on behalf of an organization or company, you have the
          legal authority to bind any such organization or company to these
          Terms, in which case the terms “you”, “user” or “your” shall refer to
          such entity. If you do not have such authority, you must not use the
          Platform on behalf of such entity.
        </div>
        <div className={baseClass("section-text")}>
          1.4 You may not use the Platform if you: (i) do not agree to these
          Terms; (ii) are not of the age of majority in your jurisdiction of
          residence; or (iii) are prohibited from accessing or using the
          Platform or any of the Platform’s contents, products or services by
          these Terms or applicable law.
        </div>
        <div className={baseClass("section-text")}>
          1.5 Any purchase or sale you make, accept or facilitate outside of
          this Platform of a Token (as defined below) will be entirely at your
          risk. We do not control or endorse purchases or sales of Tokens
          outside of our Platform. We expressly deny any obligation to indemnify
          1 Liquid Marketplace Custody Inc., Liquid Marketplace Assets Inc.,
          Liquid Marketplace Technology Inc., Liquid Marketplace Platforms Inc.,
          and Liquid Marketplace Services Inc. you or hold you harmless for any
          losses you may incur by transacting, or facilitating transactions, in
          Tokens outside of our Platform.
        </div>
        <div className={baseClass("section-header")}>
          2. Changes to Terms of Use and Platform
        </div>
        <div className={baseClass("section-text")}>
          2.1 These Terms apply to the Platform, including any updates or
          supplements. We may change or revise any aspect of these Terms or the
          Platform from time to time in our sole discretion, by posting the
          revised Terms on the Platform or electronically sending you the
          revised Terms. You are bound by any revisions to the Terms and should
          periodically visit this page to review the then-current Terms. Your
          access and use of the Platform will be subject to the most current
          version of the Terms, as indicated by the “Last Updated” date at the
          top of this page. Your use of the Platform after such revised Terms
          are made available will signify your acceptance of such revised Terms
          and your agreement to be bound by them. If you do not agree with any
          such modification, your sole and exclusive remedy is to terminate your
          use of the Platform and close your Account.
        </div>
        <div className={baseClass("section-text")}>
          2.2 We are constantly innovating the Platform to help provide the best
          possible experience. You acknowledge and agree that the form and
          nature of the Platform, and any part of it, may change from time to
          time without prior notice to you, and that we may add new features and
          change any part of the Platform at any time without notice. We may, at
          any time, and without notice or liability, change, suspend,
          discontinue or even terminate any aspect, or the availability, of any
          feature of this Platform or impose limits on certain of its features,
          including restricting your access to all or parts of the Platform. You
          agree that we shall not be liable to you or any third party for any
          modification or termination of the Platform or your Account, or
          suspension or termination of your access to the Platform or your
          Account, except to the extent otherwise expressly set forth herein.
        </div>
        <div className={baseClass("section-header")}>
          3. Account Registration
        </div>
        <div className={baseClass("section-text")}>
          3.1 Access
          <br />
          To access the Platform you must have the necessary equipment (such as
          a smartphone or computer) as well as access to the Internet. To most
          easily access the Platform, you should first install a web browser
          (such as the Google Chrome web browser). Access to the Platform may
          become degraded or unavailable during times of significant volatility
          or volume. Although we strive to provide you with excellent service,
          we do not represent that the Platform will be available without
          interruption and we do not guarantee that any order will be executed,
          accepted, recorded or remain open. You agree that Liquid Marketplace
          is not liable for any losses you may incur as a result of or
          associated with any transaction delays or unavailability.
        </div>
        <div className={baseClass("section-text")}>
          3.2 Account Registration
          <br />
          In order to use certain features of the Platform, you will need to
          create an account on the Platform (an “Account”). By creating an
          Account, you agree to provide accurate, current and complete account
          information about yourself, and to maintain and promptly update your
          Account information as necessary. We reserve the right to reclaim
          usernames at our sole and unfettered discretion without any liability
          to you. By creating an Account and using the Platform, you acknowledge
          and agree that your right to use the Account is personal to you and is
          not transferable by you to any other person or entity. All personal
          information associated with your Account or your use of the Platform
          is collected, used and disclosed in accordance with our Privacy
          Policy.
        </div>
        <div className={baseClass("section-text")}>
          3.3 Account Security
          <br />
          You are solely responsible for the security of your Account and any
          passwords for your Account. If you suspect or become aware of any
          unauthorized use of your password or Account, you agree to notify us
          immediately at support@liquidmarketplace.io. You must take any steps
          that we reasonably require you to take to reduce, manage or report
          such unauthorized use. Failure to provide prompt notification of any
          unauthorized use may be taken into account in our determination of the
          appropriate resolution of the matter.
        </div>
        <div className={baseClass("section-text")}>
          3.4 Minors
          <br />
          The Platform is not intended for those under the age of majority in
          their jurisdiction of residence (i.e., minors). If you are a minor,
          you may not use the Platform. We do not knowingly collect information
          from or direct any of our content specifically to minors. If we learn
          or have reason to suspect that you are a minor, we will unfortunately
          have to close your Account.
        </div>
        <div className={baseClass("section-header")}>
          4. Account Funding and Management
        </div>
        <div className={baseClass("section-text")}>
          4.1 External Accounts
          <br />
          In order to transact on the Platform, you must first load either legal
          tender, such as U.S. or Canadian dollars (“Legal Tender”) or digital
          assets, such as Ether or Bitcoin (“Digital Asset” and, together with
          Legal Tender, “Funds”) to your Account. Funds may be deposited in your
          Account from a financial account that you beneficially own, including
          certain accepted credit cards (“Financial Account”) or a Digital Asset
          address or account that you own, control or operate (“Digital Asset
          Account” and, together with Financial Account, “External Account”).
          Liquid Marketplace may identify from time to time the External
          Accounts from which Funds will be accepted. In no event will Liquid
          Marketplace be responsible for, and Liquid Marketplace makes no
          guarantee regarding, the performance of any External Account. You may
          be required to verify that you control the External Account that you
          use to load Funds to your Account. You may be charged fees by the
          provider of your External Account. Liquid Marketplace is not
          responsible for any such fees or for the management of security of any
          External Account. You are solely responsible for your use of any
          External Account and you agree to comply with all terms and conditions
          applicable to any External Account.
        </div>
        <div className={baseClass("section-text")}>
          4.2 Account Funding
          <br />
          You can use the Funds in your Account to purchase, store, and sell the
          tokens that represent fractional interests of collectibles on the
          Platform (“Token” or “Tokens”). At no time will Liquid Marketplace be
          the owner of any Funds or Tokens in your Account, and you acknowledge
          and agree that Liquid Marketplace is not holding your Funds or Tokens,
          whether as your trustee or otherwise, and is not acting as your
          broker, intermediary, agent, trustee, advisor, or in any fiduciary
          capacit. You agree and understand that Liquid Marketplace does not own
          any of the Tokens, transactions, or other details related to your
          Account, and that Liquid Marketplace’s role is only as a facilitator
          to send withdrawal and transfer instructions on your behalf in
          connection with your trading activities on the Platform.
        </div>
        <div className={baseClass("section-text")}>
          4.3 User Authorizations
          <br />
          You hereby authorize Liquid Marketplace, its third party service
          providers, and their respective employees and agents, in relation to
          your Account and use of the Platform, to, among other things:
        </div>
        <div className={baseClass("section-text")}>
          (a) access and view the balances and all other Transaction information
          (including Transaction history) relating to your Account for the
          purpose of reconciliation and computation of amounts due to or from
          you arising from Transactions;
        </div>
        <div className={baseClass("section-text")}>
          (b) transfer of Funds to/from your Account;
        </div>
        <div className={baseClass("section-text")}>
          (c) effect credit and debit of your Account balance in relation to
          Transactions that have been executed on the Platform or for Fees and
          charges arising from Transactions conducted through the Platform, or
          in relation to Transactions which have been reversed pursuant to these
          Terms;
        </div>
        <div className={baseClass("section-text")}>
          (d) freeze further credit or debit to or from your Account due to your
          breach of these Terms or breach of applicable law, or if there is a
          suspicion of money laundering/terrorism financing, or if there are
          breaches of anti-money laundering/countering the financing of
          terrorism policies and procedures; and
        </div>
        <div className={baseClass("section-text")}>
          (e) use any of your data or information obtained through your use of
          the Platform for purposes of effecting Transactions on the Platform or
          otherwise and/or share any of your data or information for purposes of
          operating and maintaining your Account.
        </div>
        <div className={baseClass("section-text")}>
          The foregoing authorizations may only be revoked upon closure of your
          Account in accordance with these Terms. Should you attempt to revoke
          any of these authorizations prior to the closure of your Account, then
          you will not be entitled to continue using the Platform, and we may
          immediately terminate and/or cancel your Account.
        </div>
        <br />
        <br />
        <br />
        <div className={baseClass("section-text")}>
          4.4 Withdrawals from your Account
          <br />
          You may initiate a transfer of Funds from your Account to an External
          Account from time to time by submitting the relevant instruction
          through the Platform in accordance with the instructions on the
          Platform (or such other method that Liquid Marketplace may prescribe),
          and confirming your instruction (through the method that Liquid
          Marketplace may prescribe) to withdraw such Funds. You agree and
          acknowledge that any request for a withdrawal of Funds will only be
          effected after such time as Liquid Marketplace and/or the relevant
          third party service provider(s) may require to conduct the necessary
          verification and reconciliation procedures and we reserve the right to
          refuse a withdrawal initiated within 72 hours of a deposit of Funds
          and to suspend an Account where we have reasonable grounds to believe
          that such withdrawal or other account activity is not in compliance
          with applicable law or regulation or would otherwise cause Liquid
          Marketplace to fail to comply with applicable law or regulation. Under
          no circumstances shall any of (a) Liquid Marketplace, (b) its third
          party service providers, or (c) the officers, directors, employees,
          representatives, agents, suppliers and service providers of the
          foregoing be responsible or liable for any direct or indirect losses
          (including loss of profits, business or opportunities), damages or
          costs suffered by you or any other person or entity, arising from any
          delay or refusal in effecting a withdrawal or for an Account
          suspension.
        </div>
        <div className={baseClass("section-header")}>
          5. Transactions on the Platform
        </div>
        <div className={baseClass("section-text")}>
          5.1 Transacting on the Platform
          <br />
          The Platform allows (or in the future may allow) you to purchase,
          collect and showcase Tokens representing a fractional interest in an
          underlying Collectible. Each Token is created as an ERC-20 Token on
          the Ethereum blockchain, stored in a digital wallet maintained by a
          third-party service provider, and recorded on the Platform. When you
          transact on the Platform, we facilitate your requested transaction
          between you and one or more other Platform users. Transactions that
          take place on the Platform are managed and confirmed by a third party
          service provider. When you execute a purchase of Tokens (a
          “Purchase”), you do so using funds in your Account. We may choose not
          to process transactions where Funds have not yet settled to your
          Account. When you execute a sale of Tokens (a “Sale” or “Sell” and,
          together with a Purchase, a “Transaction”), Sale proceeds, net of
          certain Fees (as defined below), are deposited into your Account. All
          Transactions take place between users of the Platform. Liquid
          Marketplace is acting in the capacity of a service provider
          facilitating such Transactions and is not a party to any Transaction.
          All Transactions on the Platform are executed automatically based on
          your order instructions. You are solely responsible for determining
          whether any Purchase, collecting strategy, or related Transaction is
          appropriate for you. You should consult your legal or tax professional
          regarding your specific situation. We may provide informational
          resources about Collectibles. You agree that such informational
          resources shall not constitute legal, tax, financial or collecting
          advice of any sort, and you will not treat it as such.
        </div>
        <div className={baseClass("section-text")}>
          5.2 Purchasing Tokens
          <br />
          You can Purchase Tokens in two ways: (a) by buying Tokens during the
          initial tokenization of a Collectible on the Platform (the “Initial
          Drop”); or (b) by buying Tokens from other users in the Platform’s
          marketplace (the “Marketplace”) once the Initial Drop has sold out.
          Typically, each Token will be valued at US$0.10 as part of the Initial
          Drop. For the purposes of illustration only, legal ownership of a
          US$1,000,000 Collectible will be represented by 10,000,000 Tokens
          pursuant to the listing agreement entered into between us and the
          original owner of the Collectible (the “Listing Agreement”). The
          Collectible will then be listed for Sale as an Initial Drop on the
          Platform, which will allow users to purchase Tokens. Once all the
          Tokens available in an Initial Drop have been sold, the Tokens may be
          made available on the Platform for users to buy and sell in real-time.
          Before you Purchase a Token, we will provide you with the following
          information: (i) the Collectible being represented by such Token, (ii)
          the number of Tokens being made available for Sale in connection with
          such Collectible, and (iii) whether any limitations will be imposed on
          the number of Tokens each user will be entitled to Purchase. We
          reserve the right to change the prices of each Token at any time or
          from time to time at our sole and absolute discretion, and to limit
          the number of Tokens each user is entitled to Purchase. We strongly
          encourage you not to Purchase Tokens other than as part of an Initial
          Drop or on the Platform. If you decide to Purchase or Sell Tokens in
          any other way, you understand that such Transactions will be entirely
          at your own risk.
        </div>
        <div className={baseClass("section-text")}>
          5.3 Reversals and Cancellations
          <br />
          You cannot cancel, reverse or change any Transaction that is complete.
          If your payment is not successful, if your Account has insufficient
          funds, or if you reverse a payment made of Funds to your Account, you
          authorize (a) Liquid Marketplace to cancel the Transaction, and (b)
          the applicable service provider to debit your other payment methods,
          including your Account balances or other linked accounts, in any
          amount necessary to complete the Transaction. You are responsible for
          maintaining an adequate balance and/or sufficient credit limits in
          order to avoid overdraft, non-sufficient funds, or similar fees
          charged by your payment provider with respect to any External Account
          or otherwise. We reserve the right to refuse to process, or to cancel
          or reverse, any Transaction, even after funds have been debited from
          your Account, if we suspect the Transaction involves (or has a high
          risk of involvement in) money laundering, terrorist financing, fraud,
          or any other type of financial crime; in response to a subpoena, court
          order, or other government order; if we reasonably suspect that the
          Transaction is erroneous; or if Liquid Marketplace suspects the
          Transaction relates to a Prohibited Use as set forth below, In such
          instances, Liquid Marketplace may reverse the Transaction and we are
          under no obligation to allow you to reinstate a Purchase or Sale order
          at the same price or on the same terms as the cancelled Transaction.
        </div>
        <div className={baseClass("section-text")}>
          5.4 Financial Transactions on the Platform
          <br />
          Any payments or Transactions that you engage in on the Platform may be
          conducted through payment processors that we expressly present to you
          and will be subject to the terms and conditions and privacy policies
          of such third-party payment processor. You should familiarize yourself
          with those documents. We have no liability to you or to any third
          party for any claims or damages that may arise as a result of any
          payments or Transactions that you engage in on the Platform, or any
          other payment or Transactions that you conduct through the third-party
          service providers. We do not provide refunds for any Purchases that
          you might take on or through the Platform, whether for Tokens or
          otherwise.
        </div>
        <div className={baseClass("section-text")}>
          All Transactions that occur on the Platform are denominated in U.S.
          dollars. If you fund your Account with any other Funds, such Funds
          will be automatically converted to U.S. dollars using a third-party
          service provider. In the event any Funds are converted into U.S.
          dollars, the applicable rates will be determined with reference to a
          base exchange rate with an additional transactional fee, which fee
          will be applied and retained by the third-party service provider
          before the converted funds are added to your Account. Any conversion
          from Funds to U.S. dollars is solely to facilitate transactions on the
          Platform. In no event will you be able to use the Platform to purchase
          or trade in digital or fiat currencies.
        </div>
        <div className={baseClass("section-text")}>
          5.5 Risk of Loss
          <br />
          You understand and acknowledge the following regarding the Tokens and
          Transactions:
        </div>
        <div className={baseClass("section-text")}>
          (a) the value of Tokens can go up or down, and there is substantial
          risk that you could lose money trading or holding Tokens;
        </div>
        <div className={baseClass("section-text")}>
          (b) the price and liquidity of Tokens may be subject to large and
          sudden fluctuations;
        </div>
        <div className={baseClass("section-text")}>
          (c) legislative and regulatory changes or actions at the provincial,
          territory, state, federal or international level may adversely affect
          the use, transfer, exchange and value of Tokens;
        </div>
        <div className={baseClass("section-text")}>
          (d) Transactions may be irreversible and losses due to fraudulent or
          accidental Transactions may not be recoverable; and
        </div>
        <div className={baseClass("section-text")}>
          (e) some Transactions may be deemed to be made when recorded on a
          public ledger, rather than the time at which you initiate the
          Transaction.
        </div>
        <div className={baseClass("section-text")}>
          5.6 Residency
          <br />
          By using the Platform, you represent that you are not a resident of a
          jurisdiction that is subject to sanctions maintained by the government
          of your respective jurisdiction, or any other jurisdiction where
          access to the Platform is illegal or otherwise prohibited, including
          those countries listed on the Government of Canada’s Consolidated
          Canadian Autonomous Sanctions List or any list maintained by the U.S.
          Office of Foreign Assets Control. You acknowledge that you are aware
          of the rules of your jurisdiction and will not use the Platform if
          such use is prohibited or otherwise violates the laws of your state,
          province, territory, country, or other jurisdiction, and that you are
          responsible for determining compliance with all applicable local laws
          in your jurisdiction. We reserve the right to suspend or terminate
          your access to the Platform without prior notice if we determine, in
          our judgment, that you reside in a jurisdiction that prohibits the use
          of the Platform.
        </div>
        <div className={baseClass("section-header")}>6. Fees and Taxes</div>
        <div className={baseClass("section-text")}>
          6.1 Responsibility for Fees
          <br />
          You will be required to pay a fee for each Transaction you initiate on
          the Platform, and each time you fund or withdraw cash from your
          Account (each a “Fee”), as set out in the{" "}
          <span className={baseClass("link")} onClick={goToFeeSchedule}>
            Fee Schedule
          </span>
          , which may be amended from time to time at our sole discretion. By
          using the Platform, you agree to pay all applicable Fees. We will
          notify you of the pricing and Fees that will apply to your Transaction
          when you initiate the Transaction and in each receipt we issue to you.
          We may charge Fees to cover network fees associated with certain
          Transactions. You understand that the amount of such Fees will
          fluctuate based on network conditions and other factors beyond our
          control. We will calculate such Fees at our discretion and will notify
          you of the Fee at or before the time you authorize the Transaction.
          You may also incur third party fees, including from your External
          Account, arising from activities and transactions that you carry out
          on the Platform (collectively, “Third-Party Fees”). You understand and
          agree that you are solely responsible for the payment of any such
          Third-Party Fees, and that they are not included in the Fee Schedule.
        </div>
        <div className={baseClass("section-text")}>
          6.2 Responsibility for Taxes
          <br />
          You will be solely responsible to pay any and all sales, use,
          value-added and other taxes, duties and assessments (except taxes on
          our net income) now or hereafter claimed or imposed by any
          governmental authority (collectively, the “Taxes”) associated with
          your use of the Platform, or any revenues accrued to you through the
          Platform or from Transactions made off the Platform. Except for income
          taxes levied on us, you: (a) will pay or reimburse us for all
          national, federal, provincial, territory, state, local or other taxes
          and assessments of any jurisdiction, including value-added taxes and
          taxes as required by international tax treaties, customs or other
          import or export taxes, and amounts levied in lieu thereof based on
          charges set, services performed or payments made hereunder, as are now
          or hereafter may be imposed under the authority of any national,
          provincial, territory, state, local or any other taxing jurisdiction;
          and (b) will not be entitled to deduct the amount of any such taxes,
          duties or assessments from payments (including Fees) made to us
          pursuant to these Terms.
        </div>
        <div className={baseClass("section-text")}>
          6.3 It is your sole responsibility to determine what, if any, taxes
          apply to any Transactions you conduct using the Platform, and to
          withhold, collect, report and remit the correct amounts of taxes to
          the appropriate tax authorities. Your Transaction history is available
          through your Account.
        </div>
        <div className={baseClass("section-header")}>6. Fees and Taxes</div>
        <div className={baseClass("section-text")}>
          7. Ownership of Tokens and Collectibles
        </div>
        <div className={baseClass("section-text")}>
          7.1 Ownership of Token
          <br />
          For the purposes of this section, “own” and “ownership” mean, with
          respect to a Token, a Token that you have Purchased or otherwise
          rightfully acquired from a legitimate source (and not through any of
          the Category B Prohibited Activities (as defined below)), where proof
          of such Purchase is recorded on the relevant blockchain. Because each
          Token is a Token on the Ethereum blockchain, when you Purchase a Token
          in accordance with these Terms (and not through any of the Category B
          Prohibited Activities), you own the Token completely. This means that
          you have the exclusive right to swap your Token, Sell it or give it
          away. You acknowledge and agree that such exclusive right is subject
          to your maintaining the security of your Account as required pursuant
          to these Terms. Ownership of each Token on the Platform is mediated
          entirely by a third party service provider and the Ethereum
          blockchain. Notwithstanding the foregoing, Liquid Marketplace
          maintains a stance of cooperation with law enforcement authorities
          globally and will not hesitate to seize, freeze, or terminate your
          Account and funds which are flagged or investigated by legal mandate.
          Your ownership of Tokens will only be recognized by us if you have
          Purchased or otherwise rightfully acquired such Tokens from a
          legitimate source and not through any of the Category B Prohibited
          Activities (as defined below).
        </div>
        <div className={baseClass("section-text")}>
          7.2 Ownership of Collectible
          <br />
          In order to list a Collectible for sale on the Platform, the Collector
          (i.e. the owner of the Collectible) must enter into a Listing
          Agreement with Liquid Marketplace. Pursuant to the Listing Agreement,
          the Collector will convey all right, title and interest in and to the
          Collectible to the holders of the Tokens (“Tokenholders”) representing
          such Collectible. The Collectible will be held in a secure third party
          vault in the name of a wholly-owned subsidiary of Liquid Marketplace
          (the “Custodian”) pursuant to a Custody Agreement entered into between
          the Collector and the Custodian. By agreeing to these Terms, each user
          agrees that upon purchase of a Token representing a proprietary
          interest in a particular Collectible, the user shall be deemed to have
          entered into the respective Custody Agreement for that Collectible as
          a “Tokenholder”.
        </div>
        <div className={baseClass("section-text")}>
          Pursuant to the Custody Agreement, neither the Collector nor an
          individual Tokenholder will have the right to take possession of the
          Collectible or direct the Custodian in any manner whatsoever, unless
          an individual acquires sole ownership of the Tokens associated with a
          Collectible, whether through a Buyout (as defined below) or otherwise.
          The Collectible will only be released by the Custodian upon tender of
          100% of the Tokens representing sole ownership in such Collectible
          (i.e., sole title, interest and right to possession of the
          Collectible). Once an individual owns 100% of the Tokens associated
          with a Collectible on the Platform, that user may be given the option
          to take possession of the Collectible using the Platform. If the user
          chooses to take possession of the Collectible, we may charge
          additional fees in connection with taking possession, including
          processing and network fees, and will coordinate with the user
          directly to address the process for retrieval of the Collectible.
        </div>
        <div className={baseClass("section-text")}>
          7.3 Buyouts
          <br />
          We may allow users to Purchase all of the Tokens associated with a
          certain Collectible without the other Tokenholders associated with
          that Collectible executing a Sale (a “Buyout”). A Buyout can only be
          exercised by a user who first acquires the minimum number of Tokens
          associated with a Collectible that Liquid Marketplace may establish
          from time to time (the “Buyout Threshold”). The Sale price under any
          such Buyout will include a premium over the Market Price of the Tokens
          associated with such Collectible (the “Buyout Premium”), with payment
          of the Sale price being made pro rata to Tokenholders being purchased
          in the Buyout. Additional fees may apply to Buyout Transactions.
        </div>
        <div className={baseClass("section-text")}>
          Once your ownership of Tokens associated with a Collectible meets or
          exceeds the Buyout Threshold, you may request a Buyout through the
          Platform (“Buyout Offer”). In order for the Buyout to be accepted, 80%
          of the Tokens associated with that Collectible (including the Tokens
          owned by you) must be voted in favour of the Buyout Offer within a
          72-hour period. In the event that 80% or more of the Tokens associated
          with the Collectible vote in favour of the Buyout Offer, the remaining
          Tokens will automatically be tendered as part of the Buyout. As noted
          in Section 7.2 above, once you own 100% of the Tokens associated with
          a Collectible, you may request the repossession of such Collectible
          from the third-party custodian, subject to the payment of associated
          fees and costs. For the purposes of Buyouts, “Market Price” shall mean
          the higher of: (i) the twenty-four hour high price on the Platform,
          (ii) the 30-day volume-weighted average price on the Platform
          (“VWAP”), and (iii) the 90-day VWAP on the Platform. You understand
          and agree that, where enabled on the Platform in our sole discretion,
          Buyouts may allow your Tokens to be purchased from you by another user
          at the price determined by the Buyout Premium, and that such Buyouts
          may occur without you executing a Sale.
        </div>
        <div className={baseClass("section-text")}>
          7.4 Disposition of Collectibles
          <br />
          We may periodically allow Token holders holding Tokens associated with
          a certain Collectible to vote on whether that Collectible should be
          sent to an auction to be sold (a “Auction Sale”). We may from time to
          time establish relationships with reputable collectible auction houses
          for the purposes of such Auction Sales (each, an “Auction House”).
        </div>
        <div className={baseClass("section-text")}>
          In order for a Collectible to be sent to an Auction House and the
          Auction Sale to be effected, 80% of the Tokens associated with that
          Collectible (including the Tokens owned by you) must be voted in
          favour of the Auction Sale within a 72-hour period. In the event that
          80% or more of the Tokens associated with the Collectible vote in
          favour of the Auction Sale, the remaining Tokens will automatically be
          tendered as part of the Auction Sale. You understand and agree that,
          where enabled on the Platform in our sole discretion, Auction Sales
          may allow your Tokens to be tendered and extinguished in exchange for
          your pro rata portion of the price achieved for the Collectible in
          connection with the Auction Sale less the reasonable applicable fees
          of such Auction House, and that such Auction Sale may occur without
          you executing a Sale or voting in favour of such Auction Sale. You
          further acknowledge and agree that the price realized for the
          Collectible as part of an Auction Sale may yield a price per Token
          that is at, above or below the price such Collectible’s Tokens are
          trading on the Platform.
        </div>
        <div className={baseClass("section-header")}>
          8. Ownership of Platform IP
        </div>
        <div className={baseClass("section-text")}>
          8.1 We Own the Platform
          <br />
          You acknowledge and agree that we (or, as applicable, our licensors)
          own all legal right, title and interest in and to all elements of the
          Platform and all intellectual property rights associated therein
          (including, without limitation, all designs, systems, methods,
          information, computer code, software, services, “look and feel”,
          organization, compilation of the content, code, data, and all other
          elements of the Platform (collectively, the “Platform Materials”). You
          acknowledge that the Platform Materials are protected by copyright,
          trade dress, patent, and trademark laws, international conventions,
          other relevant intellectual property and proprietary rights, and
          applicable laws. All Platform Materials are the copyrighted property
          of us or our licensors, and all trademarks, service marks, and trade
          names associated with the Platform or otherwise contained in the
          Platform Materials are proprietary to us or our licensors.
        </div>
        <div className={baseClass("section-text")}>
          8.2 Limited User License
          <br />
          Except as expressly set forth herein, your use of the Platform does
          not grant you ownership of or any other rights with respect to any
          content, code, data or other Platform Materials that you may access on
          or through the Platform. We reserve all rights in and to the Platform
          Materials that are not expressly granted to you in these Terms.
          Subject to your adherence to these Terms, you are granted a
          non-exclusive, limited, non-transferable, freely revocable license to
          use the Platform for personal, non-commercial purposes only and as
          permitted by the features of the Platform. We may terminate this
          license at any time for any reason or no reason. You may not use this
          Platform for any other purpose without our prior written approval.
          Your unauthorized use of the Platform or the contents of this Platform
          may violate applicable laws and we reserve all rights to pursue any
          remedy we may choose.
        </div>
        <div className={baseClass("section-text")}>
          8.3 Further User Ownership Acknowledgements
          <br />
          You understand and agree that: (a) your Purchase of a Token, whether
          on the Platform or otherwise, does not give you any rights or licenses
          in or to the Platform Materials, other than those expressly contained
          in these Terms; (b) you do not have the right, except as otherwise set
          forth in these Terms, to reproduce, distribute, or otherwise
          commercialize any elements of the Platform Materials without our prior
          written consent in each case, which consent we may withhold in our
          sole and absolute discretion; and (c) you will not apply for, register
          or otherwise use or attempt to use any of our trademarks or service
          marks, or any confusingly similar marks, anywhere in the world without
          our prior written consent in each case, which consent we may withhold
          at our sole and absolute discretion.
        </div>
        <div className={baseClass("section-text")}>
          8.4 User Feedback
          <br />
          You may choose to submit comments, bug reports, ideas or other
          feedback about the Platform, including without limitation about how to
          improve the Platform (collectively, “Feedback”). By submitting any
          Feedback, you agree that we are free to use such Feedback at our
          discretion, including without limitation, to improve the Platform or
          develop new products or services, and without additional compensation
          to you, and to disclose such Feedback to third parties (whether on a
          non-confidential basis or otherwise). You hereby grant us a perpetual,
          royalty-free, irrevocable, nonexclusive, worldwide license under all
          rights necessary for us to incorporate and use your Feedback for any
          purpose and waive all moral rights in such Feedback in our favour.
        </div>
        <div className={baseClass("section-header")}>
          9. Conditions of Use and Prohibited Activities
        </div>
        <div className={baseClass("section-text")}>
          9.1 User Warranties
          <br />
          You agree that you are responsible for your own conduct while
          accessing or using the Platform, and for any consequences thereof. You
          agree to use the Platform only for purposes that are legal, proper and
          in accordance with these Terms and any applicable laws or regulations.
          Without limiting the foregoing, you warrant and agree that your use of
          the Platform will not (and will not allow any third party to):
        </div>
        <div className={baseClass("section-text")}>a) in any manner:</div>
        <div className={baseClass("section-text")}>
          (i) involve the sending, uploading, distributing or disseminating any
          unlawful, defamatory, harassing, abusive, fraudulent, obscene,
          pornographic or otherwise objectionable content;
        </div>
        <div className={baseClass("section-text")}>
          (ii) involve the distribution of any viruses, worms, defects, Trojan
          horses, corrupted files, hoaxes, or any other items of a harmful,
          disruptive, destructive or deceptive nature;
        </div>
        <div className={baseClass("section-text")}>
          (iii) involve the uploading, posting, transmitting or otherwise making
          available through the Platform any content that infringes the
          intellectual property rights of any party;
        </div>
        <div className={baseClass("section-text")}>
          (iv) involve using the Platform to violate the legal rights (such as
          rights of privacy and publicity) of others;
        </div>
        <div className={baseClass("section-text")}>
          (v) involve engaging in, promoting or encouraging illegal activity
          (including, without limitation, money laundering);
        </div>
        <div className={baseClass("section-text")}>
          (vi) involve accessing data not intended for you or logging into a
          server or account which you are not authorized to access;
        </div>
        <div className={baseClass("section-text")}>
          (vii) involve interfering with other users’ enjoyment of the Platform;
        </div>
        <div className={baseClass("section-text")}>
          (viii) involve exploiting the Platform for any unauthorized commercial
          purpose;
        </div>
        <div className={baseClass("section-text")}>
          (ix) involve modifying, adapting, translating or reverse engineering
          any portion of the Platform;
        </div>
        <div className={baseClass("section-text")}>
          (x) involve attempting to probe, scan or test the vulnerability of a
          system or network or to breach security or authentication measures
          without proper authorization;
        </div>
        <div className={baseClass("section-text")}>
          (xi) involve engaging in “screen scraping,” “database scraping”;
        </div>
        <div className={baseClass("section-text")}>
          (xii) involve removing any copyright, trademark or other proprietary
          rights notices contained in or on the Platform or any part of it;
        </div>
        <div className={baseClass("section-text")}>
          (xiii) involve reformatting or framing any portion of the Platform;
        </div>
        <div className={baseClass("section-text")}>
          (xiv) involve displaying any other material, products or services that
          violate or encourage conduct that would violate any criminal laws, any
          other applicable laws, or any third party rights;
        </div>
        <div className={baseClass("section-text")}>
          (xv) involve using any spider, site search/retrieval application, or
          other device to retrieve or index any portion of the Platform or the
          content posted on the Platform, or to collect information about its
          users for any unauthorized purpose;
        </div>
        <div className={baseClass("section-text")}>
          (xvi) involve accessing or using the Platform for the purpose of
          creating a product or service that is competitive with any of our
          products or services;
        </div>
        <div className={baseClass("section-text")}>
          (xvii) involve abusing, harassing, or threatening another user of the
          Platform or any of our authorized representatives, customer service
          personnel, chat board moderates, or volunteers (including, without
          limitation, filing support tickets with false information, sending
          excessive e-mails or support tickets, obstructing our employees from
          doing their jobs, refusing to follow the instructions of our
          employees, or publicly disparaging us by implying favoritism by our
          employees or otherwise); or
        </div>
        <div className={baseClass("section-text")}>
          (xviii) involve using any abusive, defamatory, ethnically or racially
          offensive, harassing, harmful, hateful, obscene, offensive, sexually
          explicit, threatening or vulgar language when communicating with
          another user of the Platform or any of our authorized representatives
          <br />
          (each, a “Category A Prohibited Activity ”); and/or
        </div>
        <div className={baseClass("section-text")}>(b) in any manner:</div>
        <div className={baseClass("section-text")}>
          (i) involve creating user accounts by automated means or under false
          or fraudulent pretenses;
        </div>
        <div className={baseClass("section-text")}>
          (ii) involve the impersonation of another person (via the use of an
          e-mail address or otherwise);
        </div>
        <div className={baseClass("section-text")}>
          (iii) involve using, employing, operating, or creating a computer
          program to simulate the human behavior of a user (“Bots”);
        </div>
        <div className={baseClass("section-text")}>
          (iv) involve using, employing or operating Bots or other similar forms
          of automation to engage in any activity or Transaction on the Platform
          (including, without limitation Purchases of Tokens as part of an
          Initial Drop or on the Marketplace);
        </div>
        <div className={baseClass("section-text")}>
          (v) involve Purchasing Tokens through inappropriate or illegal means
          (including, among other things, using a stolen credit card, or a
          payment mechanism that you do not have the right to use, or Purchasing
          Tokens and then attempting to charge the cost back to your payment
          method while 13 still maintaining ownership or control of the Tokens
          or selling, gifting or trading the Tokens to someone else;
        </div>
        <div className={baseClass("section-text")}>
          (vi) involving the purchasing, selling or facilitating the purchase
          and sale of any user’s account(s) to other users or third parties for
          cash or cryptocurrency consideration outside of the Platform; or
        </div>
        <div className={baseClass("section-text")}>
          (vii) otherwise involve or result in the wrongful seizure or receipt
          of any Tokens or other digital assets
        </div>
        <div className={baseClass("section-text")}>
          (each, a “Category B Prohibited Activity” and, together with Category
          A Prohibited Activity, the “Prohibited Activities”)
        </div>
        <div className={baseClass("section-text")}>
          9.2 Effect of Your Breaches
          <br />
          If you engage in any of the Prohibited Activities we may, at our sole
          and absolute discretion, without notice or liability to you, and
          without limiting any of our other rights or remedies at law or in
          equity, immediately suspend or terminate your Account and/or delete
          your Tokens from the Platform. If we delete your Tokens from the
          Platform, you will not receive a refund of any amounts you paid for
          those Tokens. Notwithstanding the foregoing, however, if we reasonably
          believe that you are engaged in any of the Category B Prohibited
          Activities, in addition to our right to immediately suspend or
          terminate your Account and/or delete your Tokens from the Platform, we
          also reserve the right, at our sole and absolute discretion, without
          any notice or liability to you, to take any or all of the following
          actions: (a) to deem any Transaction that took place on or as the
          result of such activities to be void ab initio; and/or (b) to
          immediately confiscate any Tokens that were purchased or acquired as
          the result of such activities. We will investigate all suspected
          Prohibited Activities and may involve, and co-operate with, law
          enforcement authorities in prosecuting users who are involved in such
          activities.
        </div>
        <div className={baseClass("section-header")}>
          10. Suspension, Termination and Cancellation
        </div>
        <div className={baseClass("section-text")}>
          10.1 Termination by User
          <br />
          You may cancel your Account at any time by withdrawing all balances
          from your Account and following the account termination procedures
          prescribed by us from time to time. You will not be charged for
          cancelling your Account, although you will be required to pay any
          outstanding amounts owed to us. You authorize us to cancel or suspend
          any pending Transactions at the time of cancellation.
        </div>
        <div className={baseClass("section-text")}>
          10.2 Termination by Us
          <br />
          You acknowledge and agree that we may: (a) suspend, restrict or
          terminate your access to the Platform, and/or (b) deactivate or cancel
          your Account if: (i) we are so required by a facially valid subpoena,
          court order or binding order of a government authority; (ii) we
          reasonably suspect you of using your Account in connection with any of
          the Prohibited Activities; (iii) use of your Account is subject to any
          pending litigation, investigation or government proceeding and/or we
          perceive a heightened risk of legal or regulatory non-compliance
          associated with your Account activity; (iv) our service partners are
          unable to support your use; or (v) you take any action that we deem as
          circumventing our controls, including, but not limited to, opening
          multiple Accounts or abusing promotions which we may offer from time
          to time. You acknowledge that our decision to take certain actions,
          including limiting access to, suspending or closing your Account, may
          be based on confidential criteria that are essential to our risk
          management and security protocols. You agree that we are under no
          obligation to disclose the details of our risk management and security
          procedures to you. You waive and hold us and our subsidiaries,
          affiliates and our and their respective directors, officers,
          employees, agents, service providers, contractors, licensors,
          licensees, suppliers, and successors harmless from any and all claims
          resulting from any action taken by us and any of the foregoing parties
          relating to any investigations by either us or such parties or by law
          enforcement authorities.
        </div>
        <div className={baseClass("section-header")}>11. Indemnification</div>
        <div className={baseClass("section-text")}>
          11.1 If you have any dispute with one or more users of the Platform,
          you release Liquid Marketplace, its affiliates and service providers,
          and each of their respective officers, directors, agents, joint
          venturers, employees and representatives from any and all claims,
          demands and damages (actual and consequential) of every kind and
          nature arising out of or in any way connected with such disputes. To
          the fullest extent permitted by applicable law, you agree to indemnify
          and hold Liquid Marketplace, its affiliates and service providers, and
          each of its or their respective officers, directors, agents, joint
          venturers, employees and representatives, harmless from and against
          any claim, action or demand (including attorneys’ and accounting fees
          and any fines, fees or penalties imposed by any regulatory authority)
          arising out of or related to your breach of these Terms, your use of
          the Platform, or your violation of any law, rule or regulation, or the
          rights of any third party. We shall provide notice to you promptly of
          any such claim, action or demand and may choose in our sole discretion
          to assist you, at your expense, in defending any such claim, action or
          demand.
        </div>
        <div className={baseClass("section-header")}>
          12. Dispute Resolution; Binding Arbitration
        </div>
        <div className={baseClass("section-text")}>
          (Not applicable to Quebec residents) To the full extent permitted by
          applicable law, you are agreeing to give up any rights to litigate
          claims in a court. Other rights that you would have if you went to
          court may also be unavailable or may be limited in arbitration. You
          hereby expressly give up your right to have a trial by jury, where
          applicable. To the fullest extent permitted by applicable law, you
          hereby expressly give up your right to participate as a member of a
          class of claimants in any lawsuit, but not limited to, class action
          lawsuits involving any such dispute.
        </div>
        <div className={baseClass("section-text")}>
          12.1 Binding Arbitration
          <br />
          All disputes arising out of or in connection with this contract, or in
          respect of any defined legal relationship associated therewith or
          derived therefrom, shall be referred to and finally resolved by
          arbitration under the International Commercial Arbitration Rules of
          Procedure of the Vancouver International Arbitration Centre, subject
          to the following:
          <br />
          (a) the appointing authority shall be The ADR Institute of Ontario;
          <br />
          (b) the case shall be administered by The ADR Institute of Ontario in
          accordance with its Rules; and
          <br />
          (c) the place of arbitration shall be Toronto, Ontario, Canada.
        </div>
        <div className={baseClass("section-text")}>
          12.2 Arbitration Fees
          <br />
          Each party will cover its own fees and costs associated with the
          arbitration proceedings; however, if the arbitrator finds that you
          cannot afford to pay the fees and costs reasonably associated with the
          arbitration proceedings, we will pay them for you.
        </div>
        <div className={baseClass("section-text")}>
          12.3 Award Enforcement
          <br />
          The award of the arbitrator will be final and binding, and any
          judgment on the award rendered by the arbitrator may be entered in any
          court of competent jurisdiction. The parties agree that they will not
          appeal any arbitration decision to any court.
        </div>
        <div className={baseClass("section-text")}>
          12.4 Our Equitable Remedies
          <br />
          Notwithstanding the foregoing, we may seek and obtain injunctive
          relief in any jurisdiction in any court of competent jurisdiction, and
          you agree that these Terms are specifically enforceable by us through
          injunctive relief and other equitable remedies without proof of
          monetary damages.
        </div>
        <div className={baseClass("section-header")}>13. Disclaimers</div>
        <div className={baseClass("section-text")}>
          13.1 Subjectivity of Value
          <br />
          You acknowledge and agree that the value of each Token is inherently
          subjective, in the same way the value of the Collectible is inherently
          subjective. Each Token has no inherent or intrinsic value.
        </div>
        <div className={baseClass("section-text")}>
          13.2 Open Source Protocols
          <br />
          We do not own or control the Ethereum blockchain, including any smart
          contract and other network functionality by which Tokens may be stored
          or transmitted (the “Protocols”). Generally, the Protocols are open
          source, and anyone can use, copy, modify, and distribute them. We
          assume no responsibility for the operation of the Protocols, and we
          are not able to guarantee the functionality or security of the
          Protocols. In particular, the Protocols may be subject to sudden
          changes in operating rules (including forks). Any such operating
          changes may materially affect the availability, value, functionality,
          and/or the name of any Token stored in your Account. Liquid
          Marketplace does not control the timing and features of these material
          operating changes. It is your responsibility to make yourself aware of
          upcoming operating changes and consider publicly available information
          and information that may be provided by Liquid Marketplace in
          determining whether to continue to use the Platform. In the event of
          any such operational change, Liquid Marketplace reserves the right to
          take such steps as may be necessary to protect the security and safety
          of your Tokens, including temporarily suspending operations for the
          involved Token(s), and other necessary steps. Liquid Marketplace will
          use its best efforts to provide you with notice of its response to any
          material operating change; however, such changes are outside of our
          control and may occur without notice to us. Liquid Marketplace’s
          response to any material operating change is subject to its sole
          discretion and includes decided not to support any affected Protocol,
          fork, or other actions.
        </div>
        <div className={baseClass("section-text")}>
          13.3 Force Majeure
          <br />
          Liquid Marketplace will not be liable for delays, failure in
          performance or interruption of service which result directly or
          indirectly from any cause or condition beyond our reasonable control,
          including but not limited to, significant market volatility, any delay
          or failure due to any act of God, act of civil or military
          authorities, act of terrorists, civil disturbance, war, strike or
          other labor dispute, fire, interruption in telecommunications or
          Internet services or network provider services, failure of equipment
          and/or software, other catastrophe or any other occurrence which is
          beyond our reasonable control and shall not affect the validity and
          enforceability of any remaining provisions.
        </div>
        <div className={baseClass("section-text")}>
          13.4 No Warranties
          <br />
          You expressly understand and agree that your access to and use of the
          Platform is at your sole risk, and that the platform is provided “as
          is” and “as available” without warranties of any kind, whether express
          or implied, to the fullest extent permissible pursuant to applicable
          law. We, our subsidiaries, affiliates, and licensors make no express
          warranties and hereby disclaim all implied warranties regarding the
          Platform and any part of it, including the implied warranties of
          merchantability, fitness for a particular purpose, non-infringement,
          correctness, accuracy, or reliability. Without limiting the generality
          of the foregoing, we, our subsidiaries, affiliates, and licensors do
          not represent or warrant to you that: (i) your access to or use of the
          Platform will meet your requirements; (ii) your access to our use of
          the Platform will be uninterrupted, timely, secure or free from error;
          (iii) usage data provided through the Platform will be accurate; (iv)
          the Platform or any content, services, or features made available on
          or through the Platform are free of viruses or other harmful
          components; or (v) that any data that you disclose when you use the
          Platform will be secure. Some jurisdictions do not allow the exclusion
          of implied warranties in contracts with consumers, so some or all of
          the above exclusions may not apply to you.
        </div>
        <div className={baseClass("section-text")}>
          You accept the inherent security risks of providing information and
          dealing online over the Internet, and agree that we have no liability
          or responsibility for any breach of security unless it is due to our
          gross negligence. We will not be responsible or liable to you for any
          losses you incur as the result of your use of the Platform, or your
          Account, including but not limited to any losses, damages or claims
          arising from: (i) user error, such as forgotten passwords or
          incorrectly construed smart contracts or other transactions; (ii)
          server failure or data loss; (iii) corrupted wallet files; or (iv)
          unauthorized access or activities by third parties, including, but not
          limited to, the use of viruses, phishing, brute-forcing or other means
          of attack against the Platform, our service providers, or any
          electronic wallet.
        </div>
        <div className={baseClass("section-text")}>
          We are not responsible for losses due to blockchains or any other
          features of the Platform, the Protocols, our service providers or any
          electronic wallet, including but not limited to late report by
          developers or representatives (or no report at all) of any issues with
          the Protocols, including forks, technical node issues, or any other
          issuers having fund losses as a result.
        </div>
        <div className={baseClass("section-header")}>
          14. Limitation of Liability
        </div>
        <div className={baseClass("section-text")}>
          14.1 You understand and agree that we, our parent, subsidiaries,
          affiliates and licensors will not be liable to you or to any third
          party for any indirect, incidental, special, consequential, or
          exemplary damages which you may incur, howsoever caused and under any
          theory of liability, including, without limitation, any loss of
          profits (whether incurred directly or indirectly), loss of goodwill or
          business reputation, loss of data, cost of procurement of substitute
          goods or services, diminution of value or any other intangible loss,
          even if we have been advised of the possibility of such damages.
        </div>
        <div className={baseClass("section-text")}>
          You agree that our total, aggregate liability to you for any and all
          claims arising out of or relating to these Terms or your access to or
          use of (or your inability to access or use) any portion of the
          Platform, whether in contract, tort, strict liability, or any other
          legal theory, is limited to the greater of the amounts you have
          actually and lawfully paid us under these Terms in the two (2) month
          period preceding the date the claim arose. Some jurisdictions do not
          allow the exclusion or limitation of liability, so the above
          limitation may not apply to you. (For Quebec residents only): The
          foregoing paragraph does not apply to damages that result from the
          acts of Liquid Marketplace.
        </div>
        <div className={baseClass("section-text")}>
          You acknowledge and agree that we have made the Platform available to
          you and entered into these Terms in reliance upon the representations
          and warranties, disclaimers and limitations of liability set forth
          herein, which reflect a reasonable and fair allocation of risk between
          us and you and form an essential basis of the bargain between us and
          you. We would not be able to provide the Platform to you without these
          limitations.
        </div>
        <div className={baseClass("section-header")}>
          15. Links To & From External Platforms
        </div>
        <div className={baseClass("section-text")}>
          15.1 The Platform may provide links or references to websites,
          resources or other platforms which may be operated by third parties
          (collectively, the “External Platforms”), which are provided solely as
          a convenience to our users. We have no control over any External
          Platforms. External Platforms are not governed by these Terms but by
          other policies that may differ from these Terms. In visiting any
          External Platforms, whether linked to this Platform or otherwise, you
          do so at your own risk and you assume all responsibility in that
          regard. We make no representations or warranties regarding, and do not
          endorse, any External Platform or any content on such platform. We
          encourage you to review the terms of use of each External Platform
          visited before using them. You acknowledge and agree that we are not
          responsible for the availability of any External Platforms, and that
          we do not endorse any advertising, products or other materials on or
          made available from or through any External Platforms. Furthermore,
          you acknowledge and agree that we are not liable for any loss or
          damage which may be incurred as a result of the availability or
          unavailability of the External Platforms, or as a result of any
          reliance placed by you upon the completeness, accuracy or existence of
          any advertising, products or other materials on, or made available
          from, any External Platforms.
        </div>
        <div className={baseClass("section-text")}>
          15.2 Liquid Marketplace prohibits third parties from producing
          materials which contain links to our Platform or framing of content
          contained within our Platform without our prior written consent, which
          consent may be withheld in our sole and absolute discretion. We
          reserve the right to disable any such unauthorized links or framing.
          We have no responsibility or liability for any material that may
          contain links to the Site.
        </div>
        <div className={baseClass("section-header")}>
          16. Additional Terms for Mobile Applications
        </div>
        <div className={baseClass("section-text")}>
          16.1 Parties & Third-Party Beneficiaries These Terms are between you
          and Liquid Marketplace only. Unless otherwise provided herein, these
          Terms do not and are not intended to confer any rights or remedies
          upon any person or entity other than you. Where a mobile application
          version of the Site (“App”) is provided, Liquid Marketplace is solely
          responsible for such App, including all services provided via the App.
          <br />
          If you download the App via the App Store or Google Play, Apple Inc.
          or Google, Inc., respectively, shall be a third-party beneficiary to
          these Terms and have the right to enforce these Terms against you as a
          third-party beneficiary. However, these third-party beneficiaries are
          not a party to these Terms.
        </div>
        <div className={baseClass("section-text")}>
          You acknowledge and agree that your access to the App using a third
          party application store shall also be subject to the usage terms set
          forth in the applicable third-party beneficiary’s terms of service.
          You will comply with all applicable restrictions, requirements and
          rules that govern applications downloaded through the application
          store through which you obtained the App, including without limitation
          the Apple App Store or Google Play.
        </div>
        <div className={baseClass("section-text")}>
          You acknowledge that the third-party beneficiaries have no obligation
          whatsoever to furnish any maintenance and support services with
          respect to the App. You further acknowledge and agree that to the
          extent you have any claim arising from or related to your use of the
          App, in no event will the third party beneficiaries be responsible for
          any claims relating to the App, including but not limited to (a)
          intellectual property claims; (b) product liability claims; or (c) any
          claims arising under consumer protection or similar legislation.
        </div>
        <div className={baseClass("section-text")}>16.2 Mobile Network</div>
        <div className={baseClass("section-text")}>
          When you access the App through a mobile network, your network or
          roaming provider`s messaging, data and other rates and fees will apply
          and you will be responsible for all such charges. Downloading,
          installing or using the App may be prohibited or restricted by your
          network provider and the App may not work with your network provider
          or device.
        </div>
        <div className={baseClass("section-text")}>16.3 Software Updates</div>
        <div className={baseClass("section-text")}>
          We reserve the right to provide updates, new versions, and revisions,
          and make changes, corrections, and/or improvements (collectively
          “Updates”) to the App. By downloading, installing, starting or using
          the App, you agree to receive automatic software Updates, including
          any files that are automatically delivered to you by us (via online
          transmission, through a third party distributor, or otherwise) to
          patch, update, or otherwise modify the App.
        </div>
        <div className={baseClass("section-header")}>17. General</div>
        <div className={baseClass("section-text")}>
          17.1 Entire Agreement
          <br />
          These Terms, the Privacy Policy, and, where applicable, the Listing
          Agreement and Custody Agreement and any terms and conditions
          incorporated by reference in these Terms comprise the entire
          understanding and agreement between you and Liquid Marketplace as to
          the subject matter hereof, and supersede any and all prior
          discussions, agreements and understandings of any kind (including
          without limitation any prior versions of these Terms) and every nature
          between and among you and Liquid Marketplace.
        </div>
        <div className={baseClass("section-text")}>
          17.2 Interpretation
          <br />
          These Terms shall endure to the benefit of and be binding upon the
          parties hereto and their respective successors and permitted assigns.
          The division of these Terms into paragraphs or other subdivisions and
          the insertion of headings are for ease of reference only and shall not
          govern the meaning or interpretation of any provision of these Terms.
        </div>
        <div className={baseClass("section-text")}>
          17.3 Relationship
          <br />
          You acknowledge and agree that no agency, partnership, joint venture,
          employer-employee or franchisor-franchisee relationship is intended or
          created by these Terms or by your use of the Platform.
        </div>
        <div className={baseClass("section-text")}>
          17.4 No Waivers
          <br />
          Our failure or delay to exercise or enforce any right or provision of
          these Terms will not constitute or be deemed a waiver of future
          exercise or enforcement of such right or provision. The waiver of any
          right or provision of these Terms will be effective only if in writing
          and signed for and on behalf of us by a duly authorized
          representative.
        </div>
        <div className={baseClass("section-text")}>
          17.5 Assignment
          <br />
          Except as expressly provided in these Terms, you may not assign any
          rights and/or licenses granted under these Terms. We reserve the right
          to assign our rights without restriction, including without limitation
          to any Liquid Marketplace affiliates or subsidiaries, or to any
          successor in interest of any business associated with the Platform.
          Any attempted transfer or assignment in violation hereof shall be null
          and void. Subject to the foregoing, these Terms will bind and inure to
          the benefit of the parties, their successors and permitted assigns.
        </div>
        <div className={baseClass("section-text")}>
          17.6 Severability
          <br />
          If any provision of these Terms is determined to be invalid or
          unenforceable under any rule, law, or regulation of any local,
          municipal, state, provincial, territorial, federal or foreign
          government agency or court of competent jurisdiction, such provision
          will be changed and interpreted to accomplish the objectives of the
          provision to the fullest extent possible under any applicable law and
          the validity or enforceability of any other provision of these Terms
          shall not be affected.
        </div>
        <div className={baseClass("section-text")}>
          17.7 Survival
          <br />
          All provisions of these Terms which by their nature extend beyond the
          expiration or termination of these Terms, including, without
          limitation, sections pertaining to suspension or termination, Account
          cancellation, debts owing to Liquid Marketplace, general use of the
          Platform, disputes with Liquid Marketplace, any indemnification
          obligations, and general provisions in this section shall survive the
          termination or expiration of these Terms.
        </div>
        <div className={baseClass("section-text")}>
          17.8 Governing Law
          <br />
          (Not applicable to Quebec residents) All matters arising out of or
          relating to these Terms will be governed by and construed in
          accordance with the laws of the Province of Ontario and the federal
          laws of Canada applicable therein without giving effect to any choice
          or conflict of law provision or rule (whether of the Province of
          Ontario or any other jurisdiction).
        </div>
        <div className={baseClass("section-text")}>
          17.9 Venue
          <br />
          (Not applicable to Quebec residents) Subject to Section 12 of these
          Terms, any legal action or proceeding arising under these Terms will
          be brought exclusively in the courts of the Province of Ontario
          located in Toronto, Ontario, and we and you irrevocably consent and
          attorn to the personal jurisdiction there.
        </div>
        <div className={baseClass("section-text")}>
          17.10 Notices
          <br />
          We may provide you with any notices (including, without limitation,
          those regarding changes to these Terms) by email or other electronic
          communication or by postings on the Platform. By providing us with
          your email or other electronic address, you consent to our using
          electronic means to send you any notices. Electronic notices will be
          effective when we send the electronic communication, and notices we
          provide by posting will be effective upon posting. It is your
          responsibility to keep your email or other electronic address current.
        </div>
        <div className={baseClass("section-text")}>
          17.11 Contact Us
          <br />
          If you have any questions or comments about these Terms or the
          Platform, please contact us at:
          <br />
          info@liquidmarketplace.io.
        </div>
        <div className={baseClass("additional-text")}>
          1 .Liquid Marketplace Custody Inc., Liquid Marketplace Assets Inc.,
          Liquid Marketplace Technology Inc., Liquid Marketplace Platforms Inc.,
          and Liquid Marketplace Services Inc.
        </div>
      </div>
    </div>
  );
};

export { TermsOfUsePage };
