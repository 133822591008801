import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { AppContext } from "../../App";
import { getBEMClasses } from "../../helpers/bemHelper";
import { ListSection } from "./ListSection";
import { SearchSection } from "./SearchSection";
import { HeaderSection } from "../../components/HeaderSection";
import "./styles.css";

const baseClass = getBEMClasses("drops-page");

export const DropsPage = () => {
  const contextValue = useContext(AppContext);
  const items = contextValue?.drops;

  const [searchString, setSearchString] = useState("");
  // TODO(Jun 17, 2022): broken on React 18: https://github.com/streamich/react-use/issues/2343
  // const throttledSearchString = useThrottle(searchString.trim());
  const trimmedSearchString = searchString.trim();
  const filteredItems = useMemo(() => {
    return trimmedSearchString.length >= 2
      ? items?.filter((i) =>
          i.name.toLowerCase().includes(trimmedSearchString.toLowerCase())
        )
      : items;
  }, [items, trimmedSearchString]);

  const searchBoxRef = useRef();
  const onPageChange = useCallback(() => {
    if (searchString) {
      searchBoxRef.current?.scrollIntoView();
    }
  }, [searchString]);

  return (
    <div className={baseClass()}>
      <HeaderSection title={"let’s collect together"} />
      <div className={baseClass("search-wrapper")}>
        <SearchSection
          value={searchString}
          setValue={setSearchString}
          ref={searchBoxRef}
        />
      </div>
      <ListSection items={filteredItems} onPageChange={onPageChange} />
    </div>
  );
};
