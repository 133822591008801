import { getBEMClasses } from "../../../helpers/bemHelper";
import PropTypes from 'prop-types';
import './styles.css';


export const SpinnerDots = (props) => {
  const baseClass = getBEMClasses('spinner', 'spinner-dots', props.customClass);
  return (
    <div className={baseClass()}>
      <div className={baseClass("bounce1")} />
      <div className={baseClass("bounce2")} />
      <div className={baseClass("bounce3")} />
    </div>
  );
};

SpinnerDots.propTypes = {
  customClass: PropTypes.string
};
