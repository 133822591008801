import { Form as FormikForm } from "formik";
import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import "./styles.css";

const baseClass = getBEMClasses("form");

export const Form = (props) => {
  const { children, ...rest } =
    props;
  return (
    <FormikForm className={baseClass("")} {...rest}>
      {children}
    </FormikForm>
  );
};

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
