import React from 'react';
import PropTypes from 'prop-types';
import "./styles.css"

export const Title = ({ title, children }) => (
  <div className="title">
    { title ? <h1 className="title__text">{title}</h1> : children}
  </div>
)

Title.propTypes = {
  title: PropTypes.string,
  children: PropTypes.objectOf(React.Component)
}