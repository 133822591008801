
import { useRef } from 'react';
import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { ResultTable } from "../ResultTable";
import SuccessImage from "./images/success.png";
import ErrorImage from "./images/error.png";
import { useReactToPrint } from 'react-to-print';
import "./styles.css";

const baseClass = getBEMClasses("consign-with-us-result-step");

export const ResultStep = (props) => {
  const { values, isSubmissionError } = props;
  const componentRef = useRef();
  const pageStyle = `
  @page {
    background: white;
  }
`;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle
  });
  return (
    <div className={baseClass("")}>
      <img
        src={isSubmissionError ? ErrorImage : SuccessImage}
        alt="success"
        className={baseClass("img")}
      />
      {isSubmissionError ? (
        <>
          <div className={baseClass("title")}>Unsuccessful Submission</div>
          <div className={baseClass("error-subtitle ")}>
            Seems like something is wrong.
          </div>
          <div className={baseClass("error-subtitle ")}>
            Please try again later.
          </div>
        </>
      ) : (
        <>
          <div className={baseClass("title")}>Thank you! </div>
          <div className={baseClass("subtitle")}>
            Please print and include this{" "}
            <span className={baseClass("form-line")} onClick={handlePrint}>submission form</span> in
            the package.
          </div>
          <div className={baseClass("address-title")}>Shipping Address:</div>
          <div className={baseClass("address")}>Liquid MarketPlace Inc.</div>
          <div className={baseClass("address")}>
            Inc. 1703 - 480 University Avenue, Toronto, ON, Canada, M5G 1V2
          </div>
          <div className={baseClass("table")}>
              <ResultTable ref={componentRef} values={values} />
          </div>
        </>
      )}
    </div>
  );
};

ResultStep.propTypes = {
  values: PropTypes.object,
  isSubmissionError: PropTypes.bool,
};
