import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { shareLink } from "../../../helpers/shareLinkHelper";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import "./styles.css";

const baseClass = getBEMClasses("buttons");

export const DarkButton = (props) => {
  const { width = 120, onClick, title = "", style = {} } = props;
  return (
    <div
      className={baseClass("button", "dark")}
      style={{ width, ...style }}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

DarkButton.propTypes = {
  width: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object,
};

export const PurpleButton = (props) => {
  const { width = 125, height = 28, onClick, title = "", style = {} } = props;
  return (
    <div
      className={baseClass("button", "purple")}
      style={{ width, height, ...style }}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

PurpleButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object,
};

export const GradientButton = (props) => {
  const { width = 120, onClick, title = "", style = {} } = props;
  return (
    <div
      className={baseClass("button", "gradient")}
      style={{ width, ...style }}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

GradientButton.propTypes = {
  width: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object,
};

export const ShareButton = (props) => {
  const { width = 200, link, title = "Share", style = {} } = props;
  return (
    <div
      className={baseClass("button", "share")}
      style={{ width, ...style }}
      onClick={() => shareLink(link ? link : window.location.href)}
    >
      <ShareIcon className={baseClass("share-icon")} src={ShareIcon} />
      {title}
    </div>
  );
};

ShareButton.propTypes = {
  width: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object,
  link: PropTypes.string,
};
