import { useState } from "react";
import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { useIsTablet } from "../../../hooks/useIsMobile";
import { ListItem } from "./ListItem";
import { PaginationComponent } from "../../../components/common/Pagination";
import { SpinnerDots } from "../../../components/common/SpinnerDots";
import "./styles.css";
import { useEffect } from "react";

const baseClass = getBEMClasses("drops-list");

export const ListSection = (props) => {
  const { items, onPageChange } = props;
  const isTablet = useIsTablet();
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    onPageChange && onPageChange();
  }, [activePage, onPageChange]);

  if (!items) {
    return (
      <div className={baseClass("spinner-container")}>
        <SpinnerDots />
      </div>
    );
  }

  const pageSize = isTablet ? 6 : 4;
  // eslint-disable-next-line no-unused-vars
  const totalPages = Math.ceil(items.length / pageSize || items.length);
  return !items.length ? (
    <div className={baseClass("no-data")}>Nothing found, try again later</div>
  ) : (
    <div className={baseClass()}>
      <PaginationComponent
        customClass={baseClass()}
        totalPages={totalPages}
        maxPages={3}
        showOnlyActivePage={false}
        activePage={activePage}
        setActivePage={setActivePage}
        containerClassName={baseClass("pagination-wrapper")}
        content={(page) => {
          const pageItems = items.slice(pageSize * page, pageSize * (page + 1));
          return (
            <div className={baseClass("item-wrapper")}>
              {pageItems.map((item) => (
                <ListItem
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  imageUrl={item.imageUrl}
                  tradeState={item.tradeState}
                />
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};

ListSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      tradeState: PropTypes.shape({
        price: PropTypes.number.isRequired,
        isGrowing: PropTypes.bool.isRequired,
      }),
    })
  ),
  onPageChange: PropTypes.func,
};
