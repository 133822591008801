import { useState } from "react";
import PropTypes from "prop-types";
import { getBEMClasses } from "../../../../helpers/bemHelper";
import Info from "../../../../assets/icons/info.svg";
import CircleClose from "../../../../assets/icons/circle-close.svg";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import "./styles.css";

const baseClass = getBEMClasses("info-button");

export const InfoButton = (props) => {
  const { infoText } = props;
  const [isShowInfo, setIsShowInfo] = useState(false);
  const isMobile = useIsMobile();
  return (
    <>
      <div className={baseClass("")}>
        <img
          src={Info}
          className={baseClass("icon")}
          onClick={isMobile ? () => setIsShowInfo(true) : () => {}}
        />
        {!isMobile && <div className={baseClass("text")}>{infoText}</div>}
      </div>
      {isMobile && isShowInfo && (
        <div className={baseClass("text")}>
          {infoText}
          <img
            src={CircleClose}
            className={baseClass("icon-close")}
            onClick={() => setIsShowInfo(false)}
          />
        </div>
      )}
    </>
  );
};

InfoButton.propTypes = {
  infoText: PropTypes.string,
};
