import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";

import "./styles.css";

const bemClasses = getBEMClasses("inputs");

export const LabeledInput = ({ id, label, type, style, onChange, error }) => {
  return (
    <div className="input-container" style={style}>
      <label htmlFor={id} className="input-container__label">
        {label}
      </label>
      <input
        onChange={onChange}
        className={bemClasses("input", "light")}
        id={id}
        name={id}
        type={type ?? "text"}
      />
      {error && <span className="input-container__error">{error}</span>}
    </div>
  );
};

LabeledInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf(["text", "email"]),
  onChange: PropTypes.func,
  style: PropTypes.object,
  error: PropTypes.string,
};

export const LabeledTextArea = ({ id, label, style, onChange }) => {
  return (
    <div className="input-container" style={style}>
      <label htmlFor={id} className="input-container__label">
        {label}
      </label>
      <textarea
        className={bemClasses("input", "light")}
        style={{ height: "100%", resize: "none" }}
        id={id}
        name={id}
        onChange={onChange}
        type="textarea"
      />
    </div>
  );
};

LabeledTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

export const DarkInput = ({
  id,
  type,
  style,
  error,
  placeholder,
  onChange,
}) => {
  return (
    <div className="input-container" style={style}>
      <input
        className={bemClasses("input", "dark")}
        placeholder={placeholder}
        id={id}
        name={id}
        type={type ?? "text"}
        onChange={onChange}
      />
      {error && <span className="input-container__error">{error}</span>}
    </div>
  );
};

DarkInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "email"]),
  placeholder: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.string,
  onChange: PropTypes.func,
};
