import { getBEMClasses } from "../../../helpers/bemHelper";
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import "./styles.css";
import { useCallback } from "react";
import { forwardRef } from "react";

const baseClass = getBEMClasses("drops-search");

// eslint-disable-next-line react/display-name
export const SearchSection = forwardRef((props, ref) => {
  const {
    value,
    setValue
  } = props;
  const onChange = useCallback(e => setValue(e.target.value), [setValue]);
  const clear = useCallback(() => setValue(''), [setValue]);
  return (
    <div className={baseClass()} ref={ref}>
      {value ? (
        <CloseIcon
          className={baseClass('icon', 'close')}
          onClick={clear}
        />
      ) : (
        <SearchIcon className={baseClass('icon', 'search')} />
      )}
      <input
        className={baseClass('input')}
        placeholder={'SEARCH FOR YOUR DREAM COLLECTIBLES HERE…'}
        type='text'
        value={value}
        onChange={onChange}
      />
    </div>
  );
});

SearchSection.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
};
