import { DarkButton } from "../../../components/common/Buttons";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { openLinkInNewTab } from "../../../helpers/linksHelper";
import "./styles.css";

const baseClass = getBEMClasses("about-us");

export const JoinUsSection = () => {
  return (
    <div className={baseClass("join-us")} id="join-us">
      <div className={baseClass("join-us-title")}>JOIN US!</div>
      <div className={baseClass("position-section")}>
        <div className={baseClass("position-text-wrapper")}>
          <div className={baseClass("position-title")}>
            Social Media Strategist
          </div>
          <div className={baseClass("position-text")}>Full-Time Position</div>
        </div>
        <DarkButton
          title={"VIEW DETAILS"}
          width={180}
          onClick={() =>
            openLinkInNewTab(
              "https://careers.risepeople.com/LMP/en/4535_social-media-strategist"
            )
          }
        />
      </div>
      <div className={baseClass("position-divider")} />
      <div className={baseClass("position-section")}>
        <div className={baseClass("position-text-wrapper")}>
          <div className={baseClass("position-title")}>Graphic Designer</div>
          <div className={baseClass("position-text")}>Full-Time Position</div>
        </div>
        <DarkButton
          title={"VIEW DETAILS"}
          width={180}
          onClick={() =>
            openLinkInNewTab(
              "https://careers.risepeople.com/LMP/en/4536_graphic-designer"
            )
          }
        />
      </div>
      <div className={baseClass("position-divider")} />
      <div className={baseClass("position-section")}>
        <div className={baseClass("position-text-wrapper")}>
          <div className={baseClass("position-title")}>UX | UI Designer</div>
          <div className={baseClass("position-text")}>Full-Time Position</div>
        </div>
        <DarkButton
          title={"VIEW DETAILS"}
          width={180}
          onClick={() =>
            openLinkInNewTab(
              "https://careers.risepeople.com/LMP/en/4590_uiux-designer"
            )
          }
        />
      </div>
      <div className={baseClass("position-divider")} />
    </div>
  );
};
