// import Press1 from "./images/Press1.png";
import Press2 from "./images/Press2.png";
import Press3 from "./images/Press3.png";
import Press4 from "./images/Press4.png";
import Press5 from "./images/Press5.png";
import Press6 from "./images/Press6.png";
import Press7 from "./images/Press7.png";
import Press8 from "./images/Press8.png";
// import PressHeader1 from "./images/PressHeader1.png";
import PressHeader2 from "./images/PressHeader2.png";
import PressHeader3 from "./images/PressHeader3.png";
import PressHeader4 from "./images/PressHeader4.png";
import PressHeader5 from "./images/PressHeader5.png";
import PressHeader6 from "./images/PressHeader6.png";
import PressHeader7 from "./images/PressHeader7.png";
import PressHeader8 from "./images/PressHeader8.png";

export const PRESS_CARDS = [
  // {
  //   img: Press1,
  //   headerImg: PressHeader1,
  //   headerImgWidth: 102.22,
  //   text: "Read the pitch deck Liquid MarketPlace used to sell investors",
  //   link: "https://www.businessinsider.com/liquid-marketplace-logan-paul-used-this-pitch-deck-for-funding-2022-5",
  // },
  {
    img: Press2,
    headerImg: PressHeader2,
    text: "LIQUID MARKETPLACE LAUNCHES TO TURN SPORTS AND POKÉMON CARDS INTO TOKENS",
    link: "https://betakit.com/amid-nft-boom-liquid-marketplace-aims-to-turn-physical-sports-and-pokemon-cards-into-tokens/",
  },
  {
    img: Press3,
    headerImg: PressHeader3,
    text: "Logan Paul's Liquid Marketplace raises $8 million to own fractions of collectibles",
    link: "https://www.tubefilter.com/2022/04/13/logan-paul-99-originals-nft-liquid-marketplace/",
  },
  {
    img: Press4,
    headerImg: PressHeader4,
    text: `Logan Paul "Liquid Marketplace" Allows People To Co-Own Rare Collectibles`,
    link: "https://hypebeast.com/2022/4/logan-paul-liquid-marketplace-new-company-invest-in-shares-of-rare-collectibles",
  },
  {
    img: Press5,
    headerImg: PressHeader5,
    text: "Logan Paul Announces 'Liquid Marketplace' For Fractionalized NFT Art",
    link: "https://nftevening.com/logan-paul-announces-liquid-marketplace-for-fractionalized-nft-art/",
    headerBackground: "white",
  },
  {
    img: Press6,
    headerImg: PressHeader6,
    text: "Logan Paul's New Website Will Take Collectibles, Lock Them In A 'Vault' Then Sell Tokens",
    link: "https://kotaku.com/logan-paul-nft-crypto-blockchain-collectible-liquid-mar-1848754829",
  },
  {
    img: Press7,
    headerImg: PressHeader7,
    text: "Logan Paul's marketplace for tokenized collectibles launches after raising $8 million",
    link: "https://www.theblockcrypto.com/post/140573/logan-pauls-marketplace-for-tokenized-collectibles-goes-live-after-raising-8-million",
  },
  {
    img: Press8,
    headerImg: PressHeader8,
    text: "Logan Paul is Reaping the Rewards of Web3",
    link: "https://boardroom.tv/watch/logan-paul-boardroom-spotlight/",
  },
];