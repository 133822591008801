import PropTypes from "prop-types";
import { getBEMClasses } from "../../../helpers/bemHelper";
import SliderIconLeft from "../../../assets/icons/slider-icon-left.svg";
import SliderIconRight from "../../../assets/icons/slider-icon-right.svg";
import Slider from "react-slick";
import Slide1 from "./images/slide1.png";
import Slide2 from "./images/slide2.png";
import Slide3 from "./images/slide3.png";
import { useIsTablet } from "../../../hooks/useIsMobile";
import "./styles.css";

const baseClass = getBEMClasses("about-us");

export const StoriesSection = () => {
  const isTablet = useIsTablet();
  const sliderSettings = {
    dots: isTablet ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className={baseClass("stories")}>
      <div className={baseClass("stories-slider-wrapper")}>
        <Slider {...sliderSettings}>
          <div className={baseClass("story-card")}>
            <div className={baseClass("story-card-text-wrapper")}>
              <div className={baseClass("story-card-title")}>Our Story</div>
              <div className={baseClass("story-card-text")}>
                We began after noticing a divide in the collectibles market.
                Growing prices meant die-hard fans and collectors were often
                priced out. As collectors first, we wanted to change that.
              </div>
            </div>
            <img src={Slide1} className={baseClass("story-card-img")} />
          </div>
          <div className={baseClass("story-card")}>
            <div className={baseClass("story-card-text-wrapper")}>
              <div className={baseClass("story-card-title")}>Our Mission</div>
              <div className={baseClass("story-card-text")}>
                We want to make valuable and exclusive collectibles accessible
                to anyone interested in building their collection. Our vision is
                to create a level playing field where those who truly appreciate
                collecting can own something legendary.
              </div>
            </div>
            <img src={Slide2} className={baseClass("story-card-img")} />
          </div>
          <div className={baseClass("story-card")}>
            <div className={baseClass("story-card-text-wrapper")}>
              <div className={baseClass("story-card-title")}>Our Move</div>
              <div className={baseClass("story-card-text")}>
                We created a large-scale platform where people can own a piece
                of history by buying, selling, and trading collectibles in
                real-time, via co-ownership. When you purchase a portion of a
                collectible on Liquid MarketPlace, you become a co-owner of that
                asset.
              </div>
            </div>
            <img src={Slide3} className={baseClass("story-card-img")} />
          </div>
        </Slider>
      </div>
    </div>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconRight}
    />
  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={SliderIconLeft}
    />
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
