import { useRef } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { getBEMClasses } from "../../../../helpers/bemHelper";
import ArrowDown from "../../../../assets/icons/arrow-down.svg";
import SelectedMark from "../../../../assets/icons/selected.svg";
import { InfoButton } from "../InfoButton";
import ReactSelect, { components } from "react-select";
import "./styles.css";

const baseClass = getBEMClasses("form-select");

export const Select = (props) => {
  const {
    name,
    label,
    options,
    customChange,
    width,
    isRequired = true,
    error = "",
    defaultTitle,
    infoText = "",
  } = props;
  const isMenuOpen = useRef(false);
  return (
    <div className={baseClass("")} style={{ width: width ? width : "100%" }}>
      {!!label && (
        <label htmlFor={name} className={baseClass("label")}>
          {label}
          {isRequired && <span className={baseClass("required")}>*</span>}
          {!!infoText && <InfoButton infoText={infoText} />}
        </label>
      )}
      <div className={baseClass("select-wrapper", !!error && "error")}>
        <Field name={name}>
          {({ field, form }) => (
            <ReactSelect
              options={options}
              name={field.name}
              value={
                options
                  ? options.find((option) => option.value === field.value)
                  : ""
              }
              onChange={(option) => {
                form.setFieldValue(field.name, option.value);
                if (customChange) {
                  customChange(option);
                }
              }}
              onBlur={(e) => {
                form.setFieldTouched(field.name);
                field.onBlur(e);
              }}
              placeholder={defaultTitle}
              components={{
                IndicatorsContainer: IndicatorComponent,
                Option: OptionComponent,
              }}
              blurInputOnSelect={false}
              styles={{
                control: (provided, state) => {
                  isMenuOpen.current = state.menuIsOpen;
                  return {
                    ...provided,
                    maxHeight: 32,
                    height: 32,
                    minHeight: 32,
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    borderBottomLeftRadius: state.menuIsOpen ? 0 : 20,
                    borderBottomRightRadius: state.menuIsOpen ? 0 : 20,
                    backgroundColor: state.menuIsOpen ? "#E4E5E9" : "#FFFFFF",
                    borderColor: "transparent",
                    borderWidth: 2,
                    boxShadow: "none",
                    "&:hover": {
                      borderColor: "transparent",
                      boxShadow: "none",
                    },
                  };
                },
                input: (provided) => ({
                  ...provided,
                  fontSize: 14,
                  lineHeight: "150%",
                  fontWeight: 400,
                  color: "#000000",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: 14,
                  lineHeight: "150%",
                  fontWeight: 400,
                  color: "#000000",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: 14,
                  lineHeight: "150%",
                  fontWeight: 400,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  paddingRight: 20,
                }),
                menu: (provided) => ({
                  ...provided,
                  marginTop: 0,
                  borderColor: "transparent",
                  boxShadow: "none",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: 200,
                  overflowX: "hidden",
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }),
                option: (provided, state) => ({
                  ...provided,
                  background: "#ffffff",
                  color: state.isSelected ? "#2CA939" : "#191B20",
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: "150%",
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                  height: 32,
                  justifyContent: "space-between",
                  display: "flex",
                  "&:hover": {
                    background: "#ffffff",
                    color: "#2CA939",
                  },
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  transform: isMenuOpen.current
                    ? "rotateZ(180deg)  translateX(35px)"
                    : "rotate(0deg)",
                }),
              }}
            />
          )}
        </Field>
      </div>
      {!!error && <div className={baseClass("error-text")}>{error}</div>}
    </div>
  );
};

const IndicatorComponent = (props) => {
  // eslint-disable-next-line
  const { children, ...rest } = props;
  return (
    <components.IndicatorsContainer {...rest}>
      <img src={ArrowDown} className={baseClass("arrow")} />
    </components.IndicatorsContainer>
  );
};

IndicatorComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const OptionComponent = (props) => {
  const { children, isSelected, ...rest } = props;
  return (
    <components.Option {...rest}>
      {children}
      {isSelected && <img src={SelectedMark} />}
    </components.Option>
  );
};

OptionComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isSelected: PropTypes.bool,
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  fieldRef: PropTypes.object,
  customChange: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  defaultTitle: PropTypes.string,
  infoText: PropTypes.string,
};
