import { getBEMClasses } from "../../../../helpers/bemHelper";
import { useMedia } from "react-use";
import Image1 from "./images/image1.png";
import Image2 from "./images/image2.png";
import { ShareButton } from "../../../../components/common/Buttons";
import "../../styles.css";

const baseClass = getBEMClasses("blog");

export const MonkeyBusiness = () => {
  const isMobileImage = useMedia("(max-width: 780px)");
  return (
    <>
      <div className={baseClass("title")}>Getting Into Monkey Business</div>
      <div className={baseClass("date")}>
        Posted on July 6 2022, by Liquid MarketPlace
      </div>
      <div className={baseClass("text")} style={{ marginTop: 30 }}>
        Don’t worry, there’s nothing mischievous going on at Liquid MarketPlace!
        When we say we are getting into the monkey business, we mean it
        literally.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        {`Yup, we’re selling monkeys. Well, digitized ones :)`}
      </div>
      <img
        src={Image1}
        className={baseClass("full-width-img")}
        style={{ marginTop: 60, marginBottom: 60 }}
      />
      <div className={baseClass("text")}>
        In April 2021, monkeys seemed to take the world by storm. And not in a
        King Kong, jump on skyscrapers and yank airplanes out of the sky,
        attempting to destroy humanity kind of way (thankfully).
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        The creation of the Bored Ape Yacht Club (BAYC) in the NFT space is what
        launched the popularity of Apes on the internet. With unique traits,
        facial features, and accessories, the Bored Ape Yacht Club was an almost
        immediate success, with many people spending millions just to say they
        owned one.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Yuga Labs, the creators of BAYC, saw that many NFT enthusiasts had
        missed out on the initial Ape sales but still wanted to engage with the
        BAYC community. Yuga Labs brilliantly solved this by releasing a new NFT
        project derived from their beloved BAYC: the Mutant Ape Yacht Club
        (MAYC).
      </div>
      <img
        src={Image2}
        className={baseClass("center-img")}
        style={{
          marginTop: 40,
          height: isMobileImage ? 170: 420,
          maxHeight: isMobileImage ? 170: 420,
          maxWidth: isMobileImage ? "100%" : 745,
          width: isMobileImage ? "100%" : 745,
        }}
      />
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        {`These Mutant Apes are a freaky take on the traditional Apes that inhabit the NFT metaverse. Whether it has leopard-printed skin or no skin at all, green vampire fangs, or another mouth coming out of its mouth (yes, that's a thing), Mutant Apes put an eerie spin on the standard Bored Apes. This is the reason why people desire to own them: not only are they exclusive, but they immediately make you the center of attention.`}
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        There are up to 20,000 existing Mutant Apes. Following the launch, half
        were rewarded to owners of Bored Apes, and the other half were put up
        for auction. The owners of the Bored Apes were given Mutant Serum which
        allowed their Bored Ape to transform into a Mutant. The Serum could be
        bought and sold, but commanded a steep price tag, with an extremely rare
        Serum selling for more than $5 million.
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        {`Those who didn't get the chance to secure a Bored Ape had the next best thing once the Mutant Apes were put up for public sale. Sure, some may believe that nothing is as good as the original, but you can't deny the fact that these Mutant Apes are pretty cool — who wouldn't want to own an Ape with its brain sticking out of its hat?`}
      </div>
      <div className={baseClass("text")} style={{ marginTop: 40 }}>
        Owning a Mutant Ape means you are a part of the BAYC which as it grows
        will have many perks.
      </div>
      <div
        className={baseClass("text")}
        style={{ marginTop: 40, marginBottom: 80 }}
      >
        Coming soon to Liquid MarketPlace is the opportunity to co-own a portion
        of Mutant Ape #22070.
      </div>
      <ShareButton />
    </>
  );
};
