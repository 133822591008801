import { HeaderSection } from "../../components/HeaderSection";
import { getBEMClasses } from "../../helpers/bemHelper";
import { BlogsCards } from "./BlogsCards";
import "./styles.css";

const baseClass = getBEMClasses("blogs");

export const BlogsPage = () => {
  return (
    <div className={baseClass("")}>
      <HeaderSection
        title={"BLOG"}
        description={
          "Check out the following blog posts to learn about some interesting content related to members of our team, our company, the world of collectibles, and more."
        }
      />
      <BlogsCards />
    </div>
  );
};
