import PropTypes from "prop-types";
import BackgroundImage from "./background.png";
import { getBEMClasses } from "../../helpers/bemHelper";
import "./styles.css";

const baseClass = getBEMClasses("learnings-header");

export const HeaderSection = (props) => {
  const { title, description } = props;
  return (
    <div className={baseClass("")}>
      <img src={BackgroundImage} className={baseClass("background")} />
      <div className={baseClass("text-wrapper")}>
        <div className={baseClass("title")}>{title}</div>
        {description && (
          <div className={baseClass("description")}>{description}</div>
        )}
      </div>
    </div>
  );
};

HeaderSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
