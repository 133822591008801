import SimpleLogo from "../../assets/simple-logo.png";
import { getBEMClasses } from "../../helpers/bemHelper";
import { CollectSection } from "./CollectSection";
import { StoriesSection } from "./StoriesSection";
// import { TeamSection } from "./TeamSection";
import { JoinUsSection } from "./JoinUsSection";
import "./styles.css";

const baseClass = getBEMClasses("about-us");

const AboutUsPage = () => {
  return (
    <div className={baseClass()}>
      <div className={baseClass("header")}>
        <img src={SimpleLogo} className={baseClass("header-logo")} />
        <div className={baseClass("logo-wrapper")}>
          <span className={baseClass("logo-left-part")}>LIQUID</span>
          <span className={baseClass("logo-right-part")}>MARKETPLACE</span>
        </div>
        <div className={baseClass("header-text")}>
          Where collectors co-own physical assets. You no longer need to buy an
          asset by yourself, co-own expensive collectibles with groups of
          friends or other users!
        </div>
      </div>
      <CollectSection />
      <StoriesSection />
      {/* <TeamSection /> */}
      <JoinUsSection />
    </div>
  );
};

export { AboutUsPage };
