import { Title } from "../../components/common/Title";
import { getBEMClasses } from "../../helpers/bemHelper";
import "./styles.css";

const baseClass = getBEMClasses("fee-schedule");

const FeeSchedulePage = () => {
  return (
    <div className={baseClass("")}>
      <Title title="FEE SCHEDULE" />
      <div className={baseClass("content-wrapper")}>
        <div className={baseClass("header-wrapper")}>
          <div className={baseClass("header-text")}>
            Transactions and activities carried out on the Liquid MarketPlace
            platform will incur fees in accordance with this fee schedule (the
            “Fee Schedule”).
          </div>
          <div className={baseClass("header-text")}>
            This Fee Schedule may be amended from time to time at our sole
            discretion.
          </div>
          <div className={baseClass("header-text")}>
            Capitalized terms not defined within this Fee Schedule have the
            meanings ascribed to them in our Terms of Use.
          </div>
        </div>
        <div className={baseClass("date-text")}>
          Last Updated: July 26, 2023
        </div>
        <div className={baseClass("table-wrapper")}>
          <div className={baseClass("table-section-wrapper")}>
            <div className={baseClass("table-section-title")}>
              Transaction Fees
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Offering Curation Fee
              </div>
              <div className={baseClass("table-section-right-part")}>0%</div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Marketplace Transaction Fee
              </div>
              <div className={baseClass("table-section-right-part")}>2.5%</div>
            </div>
          </div>
          <div className={baseClass("table-section-wrapper")}>
            <div className={baseClass("table-section-title")}>Deposit Fees</div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Credit Card
              </div>
              <div className={baseClass("table-section-right-part")}>
                2.90% + $0.30 USD
              </div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Wire Transfer
              </div>
              <div className={baseClass("table-section-right-part")}>2%</div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Interac E-Transfer (Canadian Users)
              </div>
              <div className={baseClass("table-section-right-part")}>5.26%</div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Cryptocurrency
              </div>
              <div className={baseClass("table-section-right-part")}>0.00%</div>
            </div>
          </div>
          <div className={baseClass("table-section-wrapper")}>
            <div className={baseClass("table-section-title")}>
              Withdrawal Fees
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Wire Transfer
              </div>
              <div className={baseClass("table-section-right-part")}>
                USD 20.00
              </div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Multi-Payout (US Users)
              </div>
              <div className={baseClass("table-section-right-part")}>1.00%</div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Multi-Payout (International Users)
              </div>
              <div className={baseClass("table-section-right-part")}>3.00%</div>
            </div>
          </div>
          <div className={baseClass("table-section-wrapper")}>
            <div className={baseClass("table-section-title")}>
              Multi-Payout Options
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-full-part")}>
                PayPal (Accessible to All Users)
              </div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-full-part")}>
                Virtual Visa International Prepaid Card (Accessible to All
                Users)
              </div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-full-part")}>
                International Bank Transfer (International Users Only)
              </div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-full-part")}>
                Venmo (USA Users Only)
              </div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-full-part")}>
                Cashapp (USA Users Only)
              </div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-full-part")}>
                *FOR USERS OUTSIDE OF THE UNITED STATES* PayPal and Virtual Visa
                withdrawals are processed in USD, even for international users.
                The Virtual Visa card can be used to make online purchases in
                all currencies without paying any currency conversion fees.
                International Bank Transfers are processed in your country’s
                native currency as the 3.00% withdrawal fee covers all currency
                conversion fees.
              </div>
            </div>
          </div>
          <div className={baseClass("table-section-wrapper")}>
            <div className={baseClass("table-section-title")}>
              Sale of Asset
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Full Purchase of Asset in Offering (if 100% of tokens purchased
                by a single user within 30 days of initial drop)
              </div>
              <div className={baseClass("table-section-right-part")}>
                5% Removal Fee
              </div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Co-owner Initiated Auction
              </div>
              <div className={baseClass("table-section-right-part")}>
                No Fee
              </div>
            </div>
            <div className={baseClass("table-section-row")}>
              <div className={baseClass("table-section-left-part")}>
                Buyout Offer Accepted by Co-owners
              </div>
              <div className={baseClass("table-section-right-part")}>
                No Fee
              </div>
            </div>
          </div>
        </div>
        <div className={baseClass("additional-text-wrapper")}>
          <div className={baseClass("additional-text-title")}>
            Third Party Fees
          </div>
          <div className={baseClass("additional-text")}>
            You may also incur third party fees, including from your bank or
            credit card provider, arising from activities and transactions that
            you carry out on our platform (collectively, “Third-Party Fees”).
          </div>
          <div className={baseClass("additional-text")}>
            You agree and understand that you are solely responsible for the
            payment of any such Third-Party Fees, and that they are not included
            in the Fee Schedule.
          </div>
        </div>
      </div>
    </div>
  );
};

export { FeeSchedulePage };
