import { PressCard } from "../../../components/PressCard";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { PRESS_CARDS } from "./pressCards";
import "./styles.css";

const baseClass = getBEMClasses("press-cards");

export const PressCards = () => {
  return (
    <div className={baseClass("")}>
      {PRESS_CARDS.map((item, index) => (
        <PressCard
          key={index}
          img={item.img}
          text={item.text}
          link={item.link}
          headerImg={item.headerImg}
          headerBackground={item.headerBackground}
          headerImgWidth={item.headerImgWidth}
        />
      ))}
    </div>
  );
};
