import { useState, useReducer } from "react";
import { getBEMClasses } from "../../helpers/bemHelper";
import { sendConsignEmail } from "../../helpers/fetchHelper";
import { Header } from "./Header";
import { InfoBlock } from "./InfoBlock";
import { ResultStep } from "./ResultStep";
import { ConsignFirstStep } from "./Step1";
import { ConsignSecondStep } from "./Step2";
import { ConsignThirdStep } from "./Step3";
import { ConsignFourthStep } from "./Step4";
import { Steps } from "./Steps";
import "./styles.css";

const baseClass = getBEMClasses("consign-with-us");

const initialState = { step: 0, values: {}, step1: {}, step2: {}, step3: {} };

function consignReducer(state, action) {
  switch (action.type) {
    case "next-step":
      return { ...state, step: state.step + 1 };
    case "prev-step":
      return { ...state, step: state.step - 1 };
    case "set-values":
      return {
        ...state,
        values: { ...state.values, ...action.payload.values },
        [`step${action.payload.step}`]: action.payload.values,
      };
    default:
      throw new Error();
  }
}

export const ConsignWithUsPage = () => {
  const [isSubmissionError, setIsSubmissionError] = useState(false);
  const [state, dispatch] = useReducer(consignReducer, initialState);
  const { step, values, step1, step2, step3 } = state;

  const onNextClick = () => {
    dispatch({ type: "next-step" });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onPreviousClick = () => {
    dispatch({ type: "prev-step" });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const saveStepValues = (values, step) => {
    dispatch({ type: "set-values", payload: { values, step } });
  };

  const submitForm = async (values) => {
    try {
      // eslint-disable-next-line
      const { currentAuctionPayOutMethod, selectedCountry, ...validValues } =
        values;
      await sendConsignEmail(validValues);
      onNextClick();
    } catch (e) {
      console.error(e);
      setIsSubmissionError(true);
      onNextClick();
    }
  };

  const renderCurrentStep = () => {
    switch (step) {
      case 0:
        return <InfoBlock onNextClick={onNextClick} />;
      case 1:
        return (
          <ConsignFirstStep
            onNextClick={onNextClick}
            saveStepValues={saveStepValues}
            initialValues={step1}
          />
        );
      case 2:
        return (
          <ConsignSecondStep
            onNextClick={onNextClick}
            saveStepValues={saveStepValues}
            initialValues={step2}
          />
        );
      case 3:
        return (
          <ConsignThirdStep
            onNextClick={onNextClick}
            saveStepValues={saveStepValues}
            initialValues={step3}
          />
        );
      case 4:
        return <ConsignFourthStep values={values} submitForm={submitForm} />;
      case 5:
        return (
          <ResultStep values={values} isSubmissionError={isSubmissionError} />
        );
      default:
        null;
    }
  };

  return (
    <div className={baseClass("")}>
      <Header showSubtitle={step === 0} />
      {step > 0 && step < 5 && (
        <Steps step={step} onPreviousClick={onPreviousClick} />
      )}
      {renderCurrentStep()}
    </div>
  );
};
