import { GradientButton } from "../../../components/common/Buttons";
import DiscordImage from "./Discord.png";
import DiscordMobileImage from "./DiscordMobile.png";
import { getBEMClasses } from "../../../helpers/bemHelper";
import { openLinkInNewTab } from "../../../helpers/linksHelper";
import { useIsTablet } from "../../../hooks/useIsMobile";
import "./styles.css";

const baseClass = getBEMClasses("discord");

export const DiscordSection = () => {
  const isTablet = useIsTablet();
  return (
    <div className={baseClass("")}>
      {!isTablet && <img src={DiscordImage} className={baseClass("image")} />}
      <div className={baseClass("title-wrapper")}>
        <div className={baseClass("title")}>WELCOME TO</div>
        <div className={baseClass("title")}>OUR COMMUNITY</div>
      </div>
      {isTablet ? <img src={DiscordMobileImage} className={baseClass("mobile-image")} /> : null}
      <GradientButton
        title={"JOIN"}
        width={220}
        style={{ zIndex: 2 }}
        onClick={() => openLinkInNewTab("https://hubs.ly/Q01cK2Dq0")}
      />
    </div>
  );
};
