import { HeaderSection } from "../../components/HeaderSection";
import { getBEMClasses } from "../../helpers/bemHelper";
import { PressCards } from "./PressCards";
import "./styles.css";

const baseClass = getBEMClasses("press");

export const PressPage = () => {
  return (
    <div className={baseClass("")}>
      <HeaderSection
        title={"Press"}
        description={
          "See Liquid MarketPlace make quite the splash in the news by reading some of the articles below from companies like Business Insider, Financial Post, and more."
        }
      />
      <PressCards />
    </div>
  );
};
